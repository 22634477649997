import React from "react";
import { typeAlert } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

interface ToastState {
  show: boolean;
  message: string;
  type: typeAlert;
}

type SetSeeToastFunction = React.Dispatch<React.SetStateAction<ToastState>>;

export const showToastSuccessMessage = (
  message: string,
  type: typeAlert,
  setSeeToastFunction: SetSeeToastFunction,
) => {
  setSeeToastFunction({
    show: true,
    message: message || "",
    type,
  });

  setTimeout(() => {
    setSeeToastFunction({
      show: false,
      message: "",
      type: typeAlert.success,
    });
  }, 5000);
};
