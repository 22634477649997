import { call, put, takeEvery } from "redux-saga/effects";
import {
  downloadQuoteAllError,
  downloadQuoteAllSuccess,
  quoteAllError,
  quoteAllSuccess,
  quoteError,
  quoteOneError,
  quoteOneSuccess,
  quoteSuccess,
} from "./actions";
import {
  downloadGenerateQuoteAPI,
  getAllGenerateQuote,
  getOneGenerateQuote,
  postGenerateQuote,
} from "../../resources/helper/generateQuote";
import {
  DOWNLOAD_QUOTE_All,
  QUOTE,
  QUOTE_All,
  QUOTE_All_CLEAR_DATA,
  QUOTE_ONE,
} from "./actionTypes";

function* quote({ payload: { quote } }) {
  try {
    const response = yield call(postGenerateQuote, quote);
    yield put(quoteSuccess(response));
  } catch (error) {
    yield put(quoteError(error));
  }
}

function* quoteClearData() {
  try {
    yield put(quoteClearData(true));
  } catch (error) {
    yield put(quoteError(error));
  }
}

function* quoteOne({ payload: { quoteOne } }) {
  try {
    const response = yield call(
      getOneGenerateQuote,
      quoteOne.id,
      quoteOne.fullBody,
    );
    yield put(quoteOneSuccess(response));
  } catch (error) {
    yield put(quoteOneError(error));
  }
}

function* quoteAll({ payload: { quoteAll } }) {
  try {
    const response = yield call(
      getAllGenerateQuote,
      quoteAll.clientId,
      quoteAll.statuses,
      quoteAll.creationDateStart,
      quoteAll.creationDateEnd,
      quoteAll.projectIds,
      quoteAll.branches,
      quoteAll.enable,
      quoteAll.page,
      quoteAll.limit,
      quoteAll.numberId,
      quoteAll.fields,
      quoteAll.search,
      quoteAll.catalogStatusQuote,
    );
    yield put(quoteAllSuccess(response));
  } catch (error) {
    yield put(quoteAllError(error));
  }
}

function* downloadQuoteAll({ payload: { downloadQuoteAll } }) {
  try {
    const response = yield call(
      downloadGenerateQuoteAPI,
      downloadQuoteAll.clientId,
      downloadQuoteAll.enable,
      downloadQuoteAll.page,
      downloadQuoteAll.limit,
      downloadQuoteAll.catalogStatusQuote,
      downloadQuoteAll.creationDateStart,
      downloadQuoteAll.creationDateEnd,
      downloadQuoteAll.fields,
      downloadQuoteAll.contentType,
    );
    yield put(downloadQuoteAllSuccess(response));
  } catch (error) {
    yield put(downloadQuoteAllError(error));
  }
}

function* QuoteSaga() {
  yield takeEvery(QUOTE, quote);
  yield takeEvery(QUOTE_ONE, quoteOne);
  yield takeEvery(QUOTE_All, quoteAll);
  yield takeEvery(DOWNLOAD_QUOTE_All, downloadQuoteAll);
  yield takeEvery(QUOTE_All_CLEAR_DATA, quoteClearData);
}

export default QuoteSaga;
