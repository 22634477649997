export const QUOTE = "QUOTE";
export const QUOTE_ONE = "QUOTE_ONE";
export const QUOTE_SUCCESS = "QUOTE_SUCCESS";
export const QUOTE_ONE_SUCCESS = "QUOTE_ONE_SUCCESS";
export const QUOTE_ONE_ERROR = "QUOTE_ONE_ERROR";
export const QUOTE_ONE_CLEAR_DATA = "QUOTE_ONE_CLEAR_DATA";
export const QUOTE_ERROR = "QUOTE_ERROR";
export const QUOTE_CLEAR_DATA = "QUOTE_CLEAR_DATA";
export const QUOTE_All = "QUOTE_All";
export const QUOTE_All_SUCCESS = "QUOTE_All_SUCCESS";
export const QUOTE_All_ERROR = "QUOTE_All_ERROR";
export const QUOTE_All_CLEAR_DATA = "QUOTE_All_CLEAR_DATA";

export const DOWNLOAD_QUOTE_All = "DOWNLOAD_QUOTE_All";
export const DOWNLOAD_QUOTE_All_SUCCESS = "DOWNLOAD_QUOTE_All_SUCCESS";
export const DOWNLOAD_QUOTE_All_ERROR = "DOWNLOAD_QUOTE_All_ERROR";
export const DOWNLOAD_QUOTE_All_CLEAR_DATA = "DOWNLOAD_QUOTE_All_CLEAR_DATA";
