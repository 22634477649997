import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { I18nextProvider } from "react-i18next";
import i18Next from "i18next";
import reportWebVitals from "./reportWebVitals";

import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";

i18Next.init({
  interpolation: { escapeValue: false },
  lng: "es",
  resources: {
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

const app = (
  <Provider store={store}>
    <I18nextProvider i18n={i18Next}>
    
        <App />
     
    </I18nextProvider>
  </Provider>
);

const root = document.getElementById("root") as HTMLElement;
if (!root) {
  throw new Error("Root element not found");
}

const appRoot = ReactDOM.createRoot(root);
appRoot.render(app);

reportWebVitals();
