import React from "react";
import { LoginAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const View = ({ ...props }) => {
  return (
    <LoginAFY
      title={props.title}
      subTitle={props.subTitle}
      emailText={props.emailText}
      emailPlaceHolder={props.emailPlaceHolder}
      emailRequired={props.emailRequired}
      emailError={props.emailError}
      logo={props.logo}
      emailHelperText={props.emailHelperText}
      codeText={props.codeText}
      codeRequired={props.codeRequired}
      codeHelperText={props.codeHelperText}
      labelButtonLogin={props.buttonText}
      btnColor="#fffff"
      userForm="ConfirmEmail"
      onSignIn={props.onSaveData}
    />
  );
};
