import { FormSummaryAFY } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const PaymentSummaryView = (props: any) => {
  if (props.dataForm?.Name) {
    return (
      <>
        <FormSummaryAFY
          accountName={`${props.dataForm?.Name} ${props.dataForm?.LastNames}`}
          nextLabel={props.nextLabel}
          title={props.title}
          paymentOptions={props.paymentOptions}
          stepProgress={props.stepProgress}
          previousLabel={props.previousLabel}
          options={props.options}
          onBack={props.onBack}
          onNext={props.onNext}
          filters={props.filters}
          nextDisabled={false}
          onChangePayment={props.onChangePayment}
          dataForm={props.dataForm}
          paymentOption={props.paymentOption}
          minHeightGrid="calc(100vh - 260px)"
        />
      </>
    );
  } else {
    return <></>;
  }
};
