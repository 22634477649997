import React, { useEffect, useState } from "react";
import View from "./view";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import {
  breadcrumb,
  ListDropdownMultipleProps,
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
  ContextMenuAFY,
  sizesIcon,
  icons
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { sponsor } from "../../store/sponsor/actions";
import { SponsorModel } from "../../model/sponsor";

import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import LocalStorageService from "../../services/localStorage";
import { project, projectClearData } from "../../store/project/actions";

import { GetProjectAll } from "../../fnx/project/projectAll";

import { postCollection } from "../../store/actions";

import { useStyles } from "./style";
import './style.css';
import { insurances } from "../../store/actions";
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);


const Billing = () => {
  const dispatch = useDispatch();
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const [sponsorList, setSponsorList] = React.useState<SponsorModel[]>([]);
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(5000);
  const [rows, setRows] = useState<any>([]);
  const [sponsorsIds, setSponsorsIds] = React.useState("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState(false);
  const [triggerProject, setTriggerProject] = React.useState(false);
  const [insurancePage, setInsurancePage] = useState<number>(1);
  const [insuranceLimit, setInsuranceLimit] = useState<number>(20);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [insurancesList, setInsurancesList] = React.useState<any[]>([]);
  const handleOnClickNext = (data: any) => {
    let projectsId = data.sponsors;
    if (projectsId.length > 0) {
      projectsId = projectsId.map((item: any) => item.id);
    }

    let dataCollection = {
      actualMonth: data.billingMonthDate,
      cancelMonth: 0,
      maxInvoicesPerPolicy: data.numberMaximum,
      projectsId: projectsId,
      userId:  lsLogin.getUserLoginStored().userId
    }
    
    dispatch(postCollection(dataCollection));

    
  }
  const { responseSponsor, errorSponsor, loadingSponsor } = useSelector(
    (state: any) => ({
      responseSponsor: state.Sponsor.responseSponsor?.data,
      errorSponsor: state.Sponsor.errorSponsor,
      loadingSponsor: state.Sponsor.loadingSponsor,
    }),
  );

  const { responseCollection, errorCollection, loadingCollection } = useSelector(
    (state: any) => ({
      responseCollection: state.Collections.responsePostCollection?.data,
      errorCollection: state.Collections.errorPostCollection,
      loadingCollection: state.Collections.loadingPostCollection,
    }),
  );

  const [nameSearch, setNameSearch] = React.useState<string>("");
  const { responseProject, errorProject, loadingProject } = GetProjectAll(
    triggerProject,
    enable,
    insurancePage,
    insuranceLimit,
    sponsorsIds,
  );

  useEffect(() => {
    if (responseProject) {
      const newInsurancesList: any[] = responseProject.data.map(
        (item: any) => ({
          id: item.id,
          type: item.projectTypeInfo?.description,
          sponsor: item.fkSponsor?.name,
          product: item.description,
          currency: item.fkCurrency?.countryCode,
        }),
      );
      setInsurancesList(newInsurancesList);
    }

  }, [responseProject]);
  useEffect(() => {
    dispatch(
      sponsor({
        enable: enable,
        page: page,
        limit: limit,
        clientId: clientId,
      }),
    );
    setTrigger(true);
  }, []);

  //Llena la data para el filtro de los sponsor
  useEffect(() => {
    if (responseSponsor && responseSponsor?.length > 0) {
      const newSponsorList = responseSponsor.map((item: SponsorModel) => ({
        id: item.id,
        name: item.name,
        value: item.id,
        active: false,
      }));

      setSponsorList(newSponsorList);

      const idSponsor = responseSponsor
        .map((item: any) => item.id)
        .join("&sponsorId=");

      setSponsorsIds(idSponsor);

      setTriggerProject(true);
    }
    if (Array.isArray(responseSponsor)) {
      // setSponsors(responseSponsor);
    }
  }, [responseSponsor]);


  useEffect(() => {
    if (responseCollection) {
      console.log("responseCollection", responseCollection);
      setSeeToast({
        show: true,
        type: typeAlert.success,
        message: "Facturación exitosa",
      });
    }
  }
    , [responseCollection]);
  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}
      <LoadingAFY
        loading={loading
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={"Cargando..."}
        bgColor={themeCore.colors.backgroundLoader}
      />

      {insurancesList.length > 0 && (
        <View
          onClickNext={handleOnClickNext}
          products={insurancesList}
          onClickDefaultValues={() => {}}
        />
      )}
    </React.Fragment>
  );
};

export default Billing;
