import React, { useEffect } from "react";
import { ResponseError } from "../../resources/ResponseError";

import { useTranslation } from "react-i18next";
import { LoginView } from "./view";
import {
  LoadingAFY,
  onSignInDTO,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import LogoAfy from "../../assets/images/logoAffinity.svg";
import { useAuth } from "../../authContext";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../services/localStorage";
import { GetMenuSideBarHome } from "../../services/nav";
import showToast from "../../services/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  loginUserClearData,
  userConfirmEmailClearData,
  userConfirmPasswordClearData,
  userPasswordResetClearData,
} from "../../store/auth/actions";
import { showErrorToast } from "../../fnx/showError";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, setStatus } = useAuth();
  const storageKey = "afy-login";
  const localStorageService = new LocalStorageService(storageKey);
  const { t } = useTranslation("global");

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseLogin, errorLogin, loadingLogin } = useSelector(
    (state: any) => ({
      responseLogin: state.Login.responseLogin,
      errorLogin: state.Login.errorLogin,
      loadingLogin: state.Login.loadingLogin,
    }),
  );

  useEffect(() => {
    if (responseLogin) {
      let userDetails = {
        token: status,
        userName: responseLogin.user.name,
        email: responseLogin.user.email,
        clientId: responseLogin.client.id,
        countryId: responseLogin.client.countryId,
        branchId: responseLogin.branch.id,
        userId: responseLogin.user.id,
        roles: responseLogin.user.roles,
        idToken: {
          jwtToken: responseLogin.accessToken.jwtToken,
          payload: {
            name: responseLogin.idToken.payload.name,
            family_name: responseLogin.idToken.payload.family_name,
          },
        },
      };
      localStorageService.setUserLoginStored(userDetails);
      setStatus("authenticated");
    }
  }, [responseLogin]);

  useEffect(() => {
    if (errorLogin) {
      const error = errorLogin as ResponseError;
      let message;

      showErrorToast(errorLogin?.response, setSeeToast, t);

      if (error.response?.errors !== undefined) {
        let key = `login.errors.${error.response.errors[0].message}`;
        message = t(key);
      } else {
        console.log("Error: ", error.response?.message);
        message = t("error");
      }
      if (error) {
        if (error.response?.errors[0].message === "USER_NOT_CONFIRMED") {
          dispatch(loginUserClearData());
          dispatch(userConfirmEmailClearData());
          navigate("/confirmEmail");
        }
      }
    }
  }, [errorLogin]);

  const onClickForgetPassword = () => {
    dispatch(userPasswordResetClearData());
    dispatch(userConfirmPasswordClearData());
    navigate("/passwordReset");
  };

  const lgSingIn = (form: onSignInDTO) => {
    const storageKeyMh = "afy-menuh";
    const storageKeyMs = "afy-menus";
    const lsMH = new LocalStorageService(storageKeyMh);
    const lsMS = new LocalStorageService(storageKeyMs);
    lsMH.setMenuHorizontalStored([
      {
        id: "inicio",
        name: "Inicio",
        active: true,
      },
      {
        id: "admin",
        name: "Administración",
        active: false,
      },
      {
        id: "reporte",
        name: "Reportes",
        active: false,
      },
    ]);
    lsMS.setMenuSideBarStored(GetMenuSideBarHome());
    dispatch(
      loginUser({
        userName: form.email,
        password: form.password,
      }),
    );
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <LoadingAFY
        loading={loadingLogin}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={t("login.loadingLogin")}
        bgColor={themeCore.colors.backgroundLoader}
      />
      <LoginView
        onSingIn={lgSingIn}
        logo={LogoAfy}
        title={t("login.title")}
        emailError={t("login.emailError")}
        emailRequired={t("login.emailRequired")}
        emailText={t("login.emailText")}
        emailHelperText={t("login.emailHelperText")}
        passwordHelperText={t("login.passwordHelperText")}
        passwordText={t("login.passwordText")}
        passwordError={t("login.passwordError")}
        passwordRequired={t("login.passwordRequired")}
        labelButtonLogin={t("login.labelButtonLogin")}
        labelButtonForgetPassword={t("login.labelButtonForgetPassword")}
        labelInputPassword={t("login.labelInputPassword")}
        userForm={t("login.userForm")}
        onClickForgetPassword={onClickForgetPassword}
      />
    </React.Fragment>
  );
};
export default Login;
