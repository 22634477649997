import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "./view";
import { PlanModel, PlanRequest,PlanFormModel, PlanDataModel } from "../../model/plan";
import LocalStorageService from "../../services/localStorage";
import { useGetPlansAll  } from "../../fnx/plans/plansGetAll";
import { usePremiumEvalOptionGetAll  } from "../../fnx/premiumEvalOption/premiumEvalOptionGetAll";
import { usePlansPatch  } from "../../fnx/plans/plansPatch";
import { usePlanPost  } from "../../fnx/plans/plansPost";
import { useCoverTypePost  } from "../../fnx/coverType/coverTypePost";
import { useCoverTypePlanPost  } from "../../fnx/coverTypePlan/coverTypePlanPost";

import { deleteEntity, deleteEntityClearData } from "../../store/delete/actions";

import {
  CardProps,
  position,
  typeAlert,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { set } from "date-fns";
import { PremiumEvalOption } from "../../model/premiumEvalOption";
import { CoverTypeRequest } from "../../model/coverType";
import { CoverTypePlanRequest } from "../../model/coverTypePlan";
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const PlanAdmin = () => {
  const dispatch = useDispatch();

  const [list, setList] = useState<CardProps[]>([]);
  const [filters, setFilters] = useState<any>();
  const [listPremiumEvalOption, setPremiumEvalOption] = useState<PremiumEvalOption[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [triggerPremiumGetAll, setTriggerPremiumGetAll] = useState(false);
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerCoverTypePost, setTriggerCoverTypePost] = useState(false);
  const [triggerCoverTypePlanPost, setTriggerCoverTypePlanPost] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [planFormData, setPlanFormData] = useState<PlanDataModel>();
  const [planId, setPlanId] = useState("");
  const [planRequest, setPlanRequest] = useState<PlanRequest>({id:"", name:"", code:"", projectId:""});
  const [coverTypeRequest, setCoverTypeRequest] = useState<CoverTypeRequest>({id:"", code:"", description:""});
  const [coverTypePlanRequest, setCoverTypePlanRequest] = useState<CoverTypePlanRequest>({id:"", coverTypeId:"", premiumEvalOptionId:"", planId:""});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] = useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [dataForm, setDataForm] = useState<any>({
    CodePlan: "",
    Name: "",
  });

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  
  const { responsePlans, errorPlans, loadingPlans } = useGetPlansAll(trigger, clientId);
  const { responsePremiumEvalOption, errorPremiumEvalOption, loadingPremiumEvalOption } = usePremiumEvalOptionGetAll(triggerPremiumGetAll);
  const { responsePostPlans, errorPostPlans, loadingPostPlans } = usePlanPost(triggerPost,planRequest);
  const { responsePostCoverType, errorPostCoverType, loadingPostCoverType } = useCoverTypePost(triggerCoverTypePost,coverTypeRequest);
  const { responsePostCoverTypePlan, errorPostCoverTypePlan, loadingPostCoverTypePlan } = useCoverTypePlanPost(triggerCoverTypePlanPost,coverTypePlanRequest);
  const { responsePatchPlans, errorPatchPlans, loadingPatchPlans }  = usePlansPatch (triggerPatch,planRequest);
  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  useEffect(() => {
      if (!responsePlans?.data) {
        setTrigger(true);
      }
      if (!responsePremiumEvalOption?.data) {
        setTriggerPremiumGetAll(true);
      }
  }, []);

  useEffect(() => {
    if (responsePlans && responsePlans?.length > 0) {
      const list: CardProps[] = responsePlans.map(
        (item: PlanModel) => ({
          id: item.id,
          title: item.name,
          code: item.code,
            currency: '$',
            period: 'Mes',
            price: "0",
        }),
      );
     setList(list);
     setFilters([
        {
          name: "Sponsor",
          label: responsePlans[0].projectInfo?.fkSponsor.name,
          type: "label",
        },
        {
          name: "Tipo",
          label: responsePlans[0].projectInfo?.description,
          type: "label",
        },
        {
          name: "Reglas",
          label: responsePlans[0].scenaries[0].name,
          type: "label",
        },
      ]);
    }
  }, [responsePlans]);
  
  useEffect(() => {
    if (responsePremiumEvalOption && responsePremiumEvalOption?.length > 0) {
      const list: CardProps[] = responsePremiumEvalOption.map(
        (item: PremiumEvalOption) => ({
          id: item.id,
          value: item.id,
          name: item.description,
        }),
      );
      setPremiumEvalOption(list);
    }
  }, [responsePremiumEvalOption]);

  useEffect(() => {
    if (responsePostPlans && responsePostPlans) {
      
      setPlanId(responsePostPlans?.id);
      setCoverTypeRequest({code: planFormData?.CoverTypeCode , description: planFormData?.CoverTypeDescription});
      setTriggerCoverTypePost(true);
      setTriggerPost(false);
    }
  }, [responsePostPlans]);

  useEffect(() => {
    if (responsePostCoverType) {
      setCoverTypePlanRequest({planId:planId,coverTypeId: responsePostCoverType?.id, premiumEvalOptionId: planFormData?.IdOption});
      setTriggerCoverTypePlanPost(true);
      setTriggerCoverTypePost(false);
    }
  }, [responsePostCoverType]);

  useEffect(() => {
    if (responsePostCoverTypePlan) {
      setTriggerCoverTypePlanPost(false);
    }
  }, [responsePostCoverTypePlan]);
  
  useEffect(() => {
    if (responseDeleteEntity) {
      setSeeToast({
        show: true,
        message: "Eliminado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenModal(false);
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      setSeeToast({
        show: true,
        message: errorDeleteEntity?.message.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  useEffect(() => {
    setTriggerPatch(false);
    if (responsePatchPlans && responsePatchPlans.data) {
      setSeeToast({
        show: true,
        message: "Actualizado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenModal(false);
      setTrigger(true);
      //dispatch(patchSponsorClearData());
    } else if (errorPatchPlans) {
      setSeeToast({
        show: true,
        message: errorPatchPlans?.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [responsePatchPlans, errorPatchPlans]);

  const showEdit = (row: any) => {
       setDataForm({
        ...dataForm,
        CodePlan: row.code,
        Name: row.title,
      });
  };

  const handleSubmit = (dataItem: any) => {
    if (isUpdate) {
      let id = dataForm.Id;
      setPlanRequest({ id: id, name: dataItem.Name, code: dataItem.code, projectId:dataItem.projectId});
      setTriggerPatch(true);
    } else {      
      let body = {
        clientId: dataItem.clientId,
        name: dataItem.Name,  
    };
    setPlanRequest({name: dataItem.Name, code:dataItem.code, projectId:dataItem.projectId});
      setTriggerPost(true);
    }
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOnNext = (data: PlanFormModel) => {

    let datax:PlanRequest =
    {
      name: data.Name, 
      code: data.CodePlan,
      projectId: "f52f2599-aba1-4fa9-be8a-4f71c7a1d33a"
    }

    setPlanRequest(datax);
    setPlanFormData({PlanName: data.Name, CodePlan: data.CodePlan, CoverTypeCode: data.CodeInfoPlan, CoverTypeDescription: data.Description, IdOption: data.IdOption});

    setTriggerPost(true);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };
  
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenModal(true);
    setDataForm({
      ...dataForm,
      CodePlan: "",
      Name: "",
    });
  };

  const handleOnClickButtonOptionsDropdown = (opt?: any, item?: any) => {
    if (opt.id === "1") {
        setIsUpdate(true);
        setOpenModal(true);
        showEdit(item);
    } else if (opt.id === "2") {
        setitemModal(item);
        setOpenModalConfirm(true);
    } else if (opt.id === "3") {
        setitemModal(item);
        setOpenModalCloneConfirm(true);
    }
  }

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

 const handleCloseModalConfirm = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };
  
  const handleDelete = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
    dispatch(deleteEntity({ id: itemModal.id, entity: "PlanEntity", idUser: "d2bd577e-c8c6-4519-b30a-b8e674c42952" }));
  };

  const handleClone = () => {
  };
  
  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}
      <View
        title="Plan"
        list={list}
        
        openModal={openModal}
        openModalConfirm={openModalConfirm}
        openModalCloneConfirm = {openModalCloneConfirm} 
        loadingPost={null}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        Options={listPremiumEvalOption}
        filters= {filters}
        clickView={clickView}

        onSubmit={handleDelete}
        onSubmitClone={handleClone}

        onClose={handleCloseModal}
        onCloseConfirm={handleCloseModalConfirm}
        onCloseCloneModal={handleCloseCloneModal}

        handleOpenModal={handleOpenModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}

        onNext={(data?: any): void => {
          handleOnNext(data);
        }}


        onSaveData={handleSubmit}
        onClickAdd={handleOnClickAdd}
        onClickButtonOptionsDropdown={(opt?: any, item?: any): void => { handleOnClickButtonOptionsDropdown(opt,item); }}
      />
    </React.Fragment>
  );
};

export default PlanAdmin;