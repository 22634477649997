import React, { useEffect, useState } from "react";
import { View } from "./view";
import { useNavigate } from "react-router-dom";
import { UseUserResetPassword } from "../../fnx/user/userPasswordReset";
import {
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ResponseError } from "../../resources/ResponseError";
import showToast from "../../services/toast";
import { useDispatch } from "react-redux";
import LogoAfy from "../../assets/images/logoAffinity.svg";
import { userPasswordResetClearData } from "../../store/auth/actions";

export const PasswordReset = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [email, setEmail] = useState<string>("");
  const { responsePasswordReset, errorPasswordReset, loadingPasswordReset } =
    UseUserResetPassword(trigger, email);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  useEffect(() => {
    if (responsePasswordReset && responsePasswordReset?.length > 0) {
      setTrigger(false);
      dispatch(userPasswordResetClearData());
      navigate("/confirmPasswordReset");
    }
  }, [responsePasswordReset]);

  useEffect(() => {
    if (errorPasswordReset) {
      const error = errorPasswordReset as ResponseError;
      let message = "Error al restablecer contraseña";
      if (error.response?.errors !== undefined) {
        message = error.response?.errors[0].message;
      } else {
        message = error.response?.message;
      }
      setSeeToast({
        show: true,
        message: message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
      setTrigger(false);
      dispatch(userPasswordResetClearData());
    }
  }, [errorPasswordReset]);

  const handleSubmit = (dataItem: any) => {
    setEmail(dataItem.email);
    setTrigger(true);
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <View
        title="Restablecer contraseña"
        subTitle="Ingrese su correo electrónico y enviaremos un codigo por correo para restablecer su contraseña"
        emailText="Correo electrónico"
        logo={LogoAfy}
        emailPlaceHolder="Ingresa un Usuario"
        emailRequired="El correo electrónico es requerido"
        emailError="Formato de correo no aceptado"
        emailHelperText="Ingrese su correo electrónico"
        buttonText="Restaurar contraseña"
        onSaveData={handleSubmit}
      />
    </React.Fragment>
  );
};

export default PasswordReset;
