import React from "react";
import { typeAlert } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { TFunction } from "i18next";

interface ToastState {
  show: boolean;
  message: string;
  type: typeAlert;
}

type SetSeeToastFunction = React.Dispatch<React.SetStateAction<ToastState>>;

export const showErrorToast = (
  error: { errors: string | any[]; message: string },
  setSeeToastFunction: SetSeeToastFunction,
  tl: TFunction,
) => {
  if (error && error.errors) {
    let message =
      "Ha ocurrido un error. Por favor, contacte con un administrador.";
    if (Array.isArray(error.errors)) {
      message = error.errors
        .map((error: { code: ""; message: "" }) =>
          tl(`errors.${error.message}`),
        )
        .join("\n");
    } else if (error?.message) {
      message = error?.message;
    }
    setSeeToastFunction({
      show: true,
      message: message,
      type: typeAlert.error,
    });

    setTimeout(() => {
      setSeeToastFunction({
        show: false,
        message: "",
        type: typeAlert.error,
      });
    }, 5000);
  }
};
