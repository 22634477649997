import {
  icons,
  MenuItem,
  select,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const menuItem: MenuItem[] = [
  {
    disabled: false,
    icon: {
      color: "#006ac6",
      icon: icons.Edit,
      size: sizesIcon.standard,
    },
    id: "1",
    name: "Editar",
  },
  {
    icon: {
      color: "#006ac6",
      icon: icons.Delete,
      size: sizesIcon.standard,
    },
    disabled: false,
    id: "2",
    name: "Eliminar",
  },
  // {
  //   icon: {
  //     color: "#006ac6",
  //     icon: icons.FileCopy,
  //     size: sizesIcon.standard,
  //   },
  //   disabled: false,
  //   id: "3",
  //   name: "Clonar",
  // },
];

export const optionSelect: select[] = [
  {
    id: "1",
    name: "Tipo",
    value: "type",
  },
  {
    id: "2",
    name: "Descripción",
    value: "description",
  },
  {
    id: "3",
    name: "Codigo",
    value: "code",
  },
];

export const optionsSelectClient: select[] = [
  {
    id: "1",
    name: "Banesco",
    value: "1",
  },
];
export const selectValueDefault: string = "type";
