import {
  CATALOG_PAYMENT_MODE,
  CATALOG_PAYMENT_MODE_CLEAR_DATA,
  CATALOG_PAYMENT_MODE_ERROR,
  CATALOG_PAYMENT_MODE_SUCCESS,
  GET_CATALOG_ACCOUNT_TYPE,
  GET_CATALOG_ACCOUNT_TYPE_CLEAR_DATA,
  GET_CATALOG_ACCOUNT_TYPE_ERROR,
  GET_CATALOG_ACCOUNT_TYPE_SUCCESS,
  GET_CATALOG_BY_ID,
  GET_CATALOG_BY_ID_CLEAR_DATA,
  GET_CATALOG_BY_ID_ERROR,
  GET_CATALOG_BY_ID_SUCCESS,
  GET_CATALOG_STATUS_QUOTE,
  GET_CATALOG_STATUS_QUOTE_CLEAR_DATA,
  GET_CATALOG_STATUS_QUOTE_ERROR,
  GET_CATALOG_STATUS_QUOTE_SUCCESS,
  GET_CATALOGS_TYPE,
  GET_CATALOGS_TYPE_CLEAR_DATA,
  GET_CATALOGS_TYPE_ERROR,
  GET_CATALOGS_TYPE_SUCCESS,
} from "./actionTypes";

export const getCatalogById = (getCatalogById, history) => {
  return {
    type: GET_CATALOG_BY_ID,
    payload: { getCatalogById, history },
  };
};

export const getCatalogByIdSuccess = (success) => {
  return {
    type: GET_CATALOG_BY_ID_SUCCESS,
    payload: success,
  };
};

export const getCatalogByIdError = (error) => {
  return {
    type: GET_CATALOG_BY_ID_ERROR,
    payload: error,
  };
};

export const getCatalogByIdClearData = () => {
  return {
    type: GET_CATALOG_BY_ID_CLEAR_DATA,
    payload: {},
  };
};

export const getCatalogStatusQuote = (catalogStatusQuote, history) => {
  return {
    type: GET_CATALOG_STATUS_QUOTE,
    payload: { catalogStatusQuote, history },
  };
};

export const getCatalogStatusQuoteSuccess = (success) => {
  return {
    type: GET_CATALOG_STATUS_QUOTE_SUCCESS,
    payload: success,
  };
};

export const getCatalogStatusQuoteError = (error) => {
  return {
    type: GET_CATALOG_STATUS_QUOTE_ERROR,
    payload: error,
  };
};

export const getCatalogStatusQuoteClearData = () => {
  return {
    type: GET_CATALOG_STATUS_QUOTE_CLEAR_DATA,
    payload: {},
  };
};

export const getCatalogsType = (catalogsType, history) => {
  return {
    type: GET_CATALOGS_TYPE,
    payload: { catalogsType, history },
  };
};

export const getCatalogsTypeSuccess = (success) => {
  return {
    type: GET_CATALOGS_TYPE_SUCCESS,
    payload: success,
  };
};

export const getCatalogsTypeError = (error) => {
  return {
    type: GET_CATALOGS_TYPE_ERROR,
    payload: error,
  };
};

export const getCatalogsTypeClearData = () => {
  return {
    type: GET_CATALOGS_TYPE_CLEAR_DATA,
    payload: {},
  };
};

///

export const getCatalogPaymentMode = (catalogPaymentMode, history) => {
  return {
    type: CATALOG_PAYMENT_MODE,
    payload: { catalogPaymentMode, history },
  };
};

export const getCatalogPaymentModeSuccess = (success) => {
  return {
    type: CATALOG_PAYMENT_MODE_SUCCESS,
    payload: success,
  };
};

export const getCatalogPaymentModeError = (error) => {
  return {
    type: CATALOG_PAYMENT_MODE_ERROR,
    payload: error,
  };
};

export const getCatalogPaymentModeClearData = () => {
  return {
    type: CATALOG_PAYMENT_MODE_CLEAR_DATA,
    payload: {},
  };
};

export const getCatalogAccountType = (catalogAccountType, history) => {
  return {
    type: GET_CATALOG_ACCOUNT_TYPE,
    payload: { catalogAccountType, history },
  };
};

export const getCatalogAccountTypeSuccess = (success) => {
  return {
    type: GET_CATALOG_ACCOUNT_TYPE_SUCCESS,
    payload: success,
  };
};

export const getCatalogAccountTypeError = (error) => {
  return {
    type: GET_CATALOG_ACCOUNT_TYPE_ERROR,
    payload: error,
  };
};

export const getCatalogAccountTypeClearData = () => {
  return {
    type: GET_CATALOG_ACCOUNT_TYPE_CLEAR_DATA,
    payload: {},
  };
};
