import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrum,
  InsurancePageProps,
  insuranceStorybook,
} from "./insuranceDto";
import { InsuranceView } from "../insurance/view";
import LogoAFY from "../../assets/images/insurance.png";
import {
  breadcrumb,
  CardProps,
  MenuItem,
  position,
  theme,
  ToastAFY,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../services/localStorage";
import { InsuranceTypeModel } from "../../model/insuranceType";
import { SponsorModel } from "../../model/sponsor";
import { Steps } from "../../enum/steps";
import { stepProgressBanesco1 } from "../../dataBase/stepProgress";
import { transformToSelect } from "../../services/functions";
import { personClearData } from "../../store/person/actions";
import { regionClearData } from "../../store/region/actions";
import { GetNavigationPages } from "../../services/getNavigationPages";
import {
  pageActionFlagsApi,
  pageActionFlagsClearData,
} from "../../store/pageActionFlags/actions";
import { quoteClearData } from "../../store/generateQuote/actions";
import { planBenefitClearData } from "../../store/planBenefit/actions";
import { plansClearData } from "../../store/plans/actions";
import { paymentModeClearData } from "../../store/paymentModes/action";
import showToast from "../../services/toast";
import { showErrorToast } from "../../fnx/showError";

import { useTranslation } from "react-i18next";
import { sponsor } from "../../store/sponsor/actions";
import { GetProjectAll } from "../../fnx/project/projectAll";
import { projectClearData } from "../../store/project/actions";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

const storageKey3 = "afy-action-flags";
const lsPageActionFlags = new LocalStorageService(storageKey3);

const Insurance = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [insuranceType] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insuranceType].id,
  );
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(100);
  const [descriptionInsurance, setDescriptionInsurance] =
    React.useState<string>("");
  const [insurancePage, setInsurancePage] = useState<number>(1);
  const [insuranceLimit, setInsuranceLimit] = useState<number>(20);
  const [countPagination, setCountPagination] = useState<number>(0);
  const [insuranceTrigger, setInsuranceTrigger] = useState<boolean>(false);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [insurance, setInsurance] = React.useState<InsurancePageProps>(
    insuranceStorybook(t),
  );
  const [sponsorsIds, setSponsorsIds] = React.useState("");
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseSponsor, errorSponsor, loadingSponsor } = useSelector(
    (state: any) => ({
      responseSponsor: state.Sponsor.responseSponsor?.data,
      errorSponsor: state.Sponsor.errorSponsor,
      loadingSponsor: state.Sponsor.loadingSponsor,
    }),
  );

  const { responseProject, errorProject, loadingProject } = GetProjectAll(
    insuranceTrigger,
    enable,
    insurancePage,
    insuranceLimit,
    sponsorsIds,
    descriptionInsurance,
  );

  const callSponsor = () => {
    setTrigger(!trigger);
  };

  const {
    responsePageActionFlags,
    errorPageActionFlags,
    loadingPageActionFlags,
  } = useSelector((state: any) => ({
    responsePageActionFlags:
      state.PageActionFlags.responsePageActionFlags?.data,
    errorPageActionFlags: state.PageActionFlags.errorPageActionFlags,
    loadingPageActionFlags: state.PageActionFlags.loadingPageActionFlags,
  }));

  useEffect(() => {
    setBreadCrumbs(Breadcrum);
    setSteps(stepProgressBanesco1());
    callSponsor();
    dispatch(personClearData());
    dispatch(regionClearData());
    dispatch(planBenefitClearData());
    dispatch(plansClearData());
    dispatch(paymentModeClearData());
    dispatch(pageActionFlagsClearData());
    dispatch(
      sponsor({
        enable: enable,
        page: page,
        limit: limit,
        clientId: clientId,
      }),
    );
  }, []);

  useEffect(() => {
    if (responseSponsor && responseSponsor?.length > 0) {
      const idSponsor = responseSponsor
        .map((item: any) => item.id)
        .join("&sponsorId=");
      setSponsorsIds(idSponsor);
      setInsuranceTrigger(true);
    }
  }, [responseSponsor]);

  useEffect(() => {
    if (errorSponsor) {
      showErrorToast(errorSponsor, setSeeToast, t);
    }
  }, [errorSponsor]);

  useEffect(() => {
    if (errorProject) {
      showErrorToast(errorProject, setSeeToast, t);
    }
  }, [errorProject]);

  useEffect(() => {
    const loadData = async () => {
      if (responseProject && responseProject?.data) {
        const dataST = await Promise.all(
          responseProject?.data.map(async (item: InsuranceTypeModel) => {
            const imageExists = await checkImage(item.logoUrl);
            return {
              id: item.id,
              title: item.description,
              text:
                item.logoUrl && imageExists ? "" : item.description.slice(0, 2),
              logoUrl: item.logoUrl && imageExists ? item.logoUrl : LogoAFY,
              logo: item.logoUrl && imageExists ? item.logoUrl : LogoAFY,
              backgroundColor: "#fff",
              textColor: "#3A56A1",
              campaign: transformToSelect(item.campaign),
            };
          }),
        );
        setInsurance({ ...insurance, data: dataST });
      }
      if (responseProject?.pagingInfo) {
        setCountPagination(responseProject?.pagingInfo.totalItems);
      }
      dispatch(projectClearData());
    };
    loadData();
  }, [responseProject]);

  useEffect(() => {
    if (responsePageActionFlags && responsePageActionFlags?.length > 0) {
      lsPageActionFlags.setPageActionFlagsStored(responsePageActionFlags);
      dispatch(pageActionFlagsClearData());
      navigate("/plan");
    }

    if (responsePageActionFlags && responsePageActionFlags?.length === 0) {
      setSeeToast({
        show: true,
        message: "Este producto no tiene planes asignados.",
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [responsePageActionFlags]);

  useEffect(() => {
    if (responseSponsor) {
      const dataST: MenuItem[] = [];
      responseSponsor.map((item: SponsorModel) => {
        dataST.push({
          id: item.id,
          name: item.name,
          active: true,
        });
      });
      setInsurance({
        ...insurance,
        filter: {
          ...insurance.filter,
          insurers: dataST,
        },
      });
    }
  }, [responseSponsor]);

  // useEffect(() => {
  //   //callDispatchInsurance();
  // }, [sponsorId]);
  //
  // useEffect(() => {
  //   //callDispatchInsurance();
  // }, [descriptionInsurance]);

  const checkImage = (url: any) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });
  };

  const clickCard = (card: CardProps) => {
    dispatch(quoteClearData());
    localStorageService.setStepStored({
      id: card.id,
      name: card.title as string,
      step: Steps.insurance,
      completed: true,
      campaign: card.campaign!,
      campaignSelected: card.campaign ? card.campaign[0] : "",
    });
    if (responseProject && responseProject.data) {
      let ri = responseProject?.data.find((i: any) => i.id === card.id);

      if (ri) {
        localStorageService.setStepStored({
          id: ri.projectTypeInfo.id,
          name: ri.projectTypeInfo.description as string,
          step: Steps.insuranceType,
          completed: true,
        });
      }
    }
    //Get pageActionFlags
    callDispatchPageActionFlags(card.id);

    return (
      <ToastAFY
        autoclose={5000}
        closeOnClick
        newstOnTop
        pauseOnHover
        position={position.topRight}
        theme={theme.light}
        title="Seguro seleccionado, Exitosamente!"
        type={typeAlert.success}
      />
    );
  };

  const clickChip = (chips: MenuItem[]) => {
    const idSponsor = chips.map((item: any) => item.id).join("&sponsorId=");
    setSponsorsIds(idSponsor);
  };

  // const callDispatchInsurance = () => {
  //   dispatch(
  //     insuranceApi({
  //       enable: enable,
  //       page: page,
  //       limit: limit,
  //       sponsorId: sponsorId,
  //       descriptionInsurance: descriptionInsurance,
  //       projectType: insuranceType,
  //     }),
  //   );
  // };

  const clickView = (view: MenuItem) => {
    let insuranceObj = { ...insurance, viewType: view.id };
    setInsurance(insuranceObj);
  };

  const searchFilter = (term: string) => {
    setDescriptionInsurance(term);
  };

  const callDispatchPageActionFlags = (id: string) => {
    dispatch(
      pageActionFlagsApi({
        enable: enable,
        page: page,
        limit: limit,
        clientId: clientId,
        projectId: id,
      }),
    );
  };

  const onChangePage = (e: number) => {
    setInsurancePage(e);
    setInsuranceTrigger(true);
  };

  const onChangeRowsPerPage = (e: number) => {
    setInsuranceLimit(e);
    setInsuranceTrigger(true);
  };

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <InsuranceView
        loading={loadingSponsor || loadingProject}
        insurance={insurance}
        breadCrumbs={breadCrumbs}
        searchFilter={searchFilter}
        clickView={clickView}
        clickCard= {GetNavigationPages().permissionsConfig.find((i) => i.name === "sale-product-create") ? clickCard : ( () => {}) }
        clickChip={clickChip}
        steps={steps}
        insurancePage={insurancePage}
        insuranceLimit={insuranceLimit}
        countPagination={countPagination}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </>
  );
};
export default Insurance;
