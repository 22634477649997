import { call, put, takeEvery } from "redux-saga/effects";
import {
  benefitError,
  benefitSuccess,
  getBeneficiaryError,
  getBeneficiarySuccess,
  patchBeneficiaryError,
  patchBeneficiarySuccess,
} from "./actions";
import {
  BENEFICIARY_PATCH,
  BENEFIT,
  BENEFIT_CLEAR_DATA,
  GET_BENEFICIARY,
} from "./actionTypes";
import {
  getBeneficiaryAPI,
  postCreateBenefit,
  updateBeneficiary,
} from "../../resources/helper/beneficiary";

function* benefit({ payload: { beneficiary } }) {
  try {
    const response = yield call(postCreateBenefit, beneficiary);
    yield put(benefitSuccess(response));
  } catch (error) {
    yield put(benefitError(error));
  }
}

function* benefitClearData() {
  try {
    yield put(benefitClearData(true));
  } catch (error) {
    yield put(benefitError(error));
  }
}

function* updateBeneficiaryApi({ payload: { beneficiary } }) {
  try {
    const response = yield call(updateBeneficiary, beneficiary);
    yield put(patchBeneficiarySuccess(response));
  } catch (error) {
    yield put(patchBeneficiaryError(error));
  }
}

function* getBeneficiary({ payload: { beneficiary } }) {
  try {
    const response = yield call(
      getBeneficiaryAPI,
      beneficiary.quoteId,
      beneficiary.enable,
      beneficiary.page,
      beneficiary.limit,
    );
    yield put(getBeneficiarySuccess(response));
  } catch (error) {
    yield put(getBeneficiaryError(error));
  }
}

function* BeneficiarySaga() {
  yield takeEvery(BENEFIT, benefit);
  yield takeEvery(GET_BENEFICIARY, getBeneficiary);
  yield takeEvery(BENEFICIARY_PATCH, updateBeneficiaryApi);
  yield takeEvery(BENEFIT_CLEAR_DATA, benefitClearData);
}

export default BeneficiarySaga;
