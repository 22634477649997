import React, { useEffect, useState } from "react";
import { View } from "./view";
import { ConfirmPasswordRequest } from "../../model/user";
import { useNavigate } from "react-router-dom";
import { UseUserConfirmPassword } from "../../fnx/user/userConfirmPassword";
import {
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ResponseError } from "../../resources/ResponseError";
import showToast from "../../services/toast";
import LogoAfy from "../../assets/images/logoAffinity.svg";

export const ConfirmPasswordReset = () => {
  const navigate = useNavigate();
  const [trigger, setTrigger] = useState(false);
  const [bodyRequest, setBodyRequest] = useState<ConfirmPasswordRequest>({});
  const {
    responseConfirmPassword,
    errorConfirmPassword,
    loadingConfirmPassword,
  } = UseUserConfirmPassword(trigger, bodyRequest);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  useEffect(() => {
    if (responseConfirmPassword && responseConfirmPassword?.length > 0) {
      navigate("/login");
      setTrigger(false);
    }
  }, [responseConfirmPassword]);

  useEffect(() => {
    if (errorConfirmPassword) {
      const error = errorConfirmPassword as ResponseError;
      let message = "Error al reestablecer contraseña";
      if (error.response?.errors !== undefined) {
        message = error.response?.errors[0].message;
      } else {
        message = error.response?.message;
      }
      setSeeToast({
        show: true,
        message: message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
      setTrigger(false);
    }
  }, [errorConfirmPassword]);

  const handleSubmit = (dataItem: any) => {
    setBodyRequest({
      userName: dataItem.email,
      newPassword: dataItem.password,
      code: dataItem.code,
    });
    setTrigger(true);
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message)}
      <View
        title="Restablecer contraseña"
        subTitle="Ingrese su correo electrónico contraseña y el codigo de su correo electronico"
        emailText="Correo electrónico"
        emailPlaceHolder="Ingresa un Usuario"
        emailRequired="El correo electrónico es requerido"
        emailError="Formato de correo no aceptado"
        emailHelperText="Ingrese su correo electrónico"
        passwordText="Contraseña"
        passwordRequired="La contraseña es requerida"
        passwordError="La contraseña debe tener al menos 8 caracteres"
        passwordHelperText="Ingrese su contraseña"
        codeText="Codigo"
        codeRequired="El codigo es requerido"
        codeHelperText="Ingrese su codigo"
        logo={LogoAfy}
        btnColor="#fffff"
        buttonText="Restaurar contraseña"
        onSaveData={handleSubmit}
      />
    </React.Fragment>
  );
};

export default ConfirmPasswordReset;
