import { call, put, takeEvery } from "redux-saga/effects";
import { LOGIN_USER, USER_CREATE, USER_PASSWORD_RESET, USER_CONFIRM_PASSWORD, USER_CONFIRM_EMAIL, USER_PATCH, USER  } from "./actionTypes";
import { 
  loginUserSuccess, loginUserError,
  userCreateSuccess, userCreateError,
  userSuccess, userError,
  userPasswordResetSuccess, userPasswordResetError,
  userConfirmPasswordSuccess, userConfirmPasswordError,
  userConfirmEmailSuccess, userConfirmEmailError,
  userPatchSuccess, userPatchError
} from "./actions";
import { postLoginAPI, userCreateUser, userInitPasswordReset, userConfirmPasswordReset, userConfirmEmail, userPatch, UserGet } from "../../resources/helper/login";

function* loginUser({ payload: { user } }) {
  try {
    const response = yield call(postLoginAPI, user);
    yield put(loginUserSuccess(response));
  } catch (error) {
    yield put(loginUserError(error));
  }
}

function* userGetApi({ payload: { body } }) {
  try {
    const response = yield call(
      UserGet,
      body,
    );
    yield put(userSuccess(response));
  } catch (error) {
    yield put(userError(error));
  }
}

function* userCreateUserApi({ payload:  user  }) {
  try {
    const response = yield call(userCreateUser, user);
    yield put(userCreateSuccess(response));
  } catch (error) {
    yield put(userCreateError(error));
  }
}

function* userInitPasswordResetApi({ payload:  email  }) {
  try {
    const response = yield call(userInitPasswordReset, email);
    yield put(userPasswordResetSuccess(response));
  } catch (error) {
    yield put(userPasswordResetError(error));
  }
}

function* userConfirmPasswordResetApi({ payload:  user  }) {
  try {
    const response = yield call(userConfirmPasswordReset, user);
    yield put(userConfirmPasswordSuccess(response));
  } catch (error) {
    yield put(userConfirmPasswordError(error));
  }
}

function* userConfirmEmailApi({ payload:  body  }) {
  try {
    const response = yield call(userConfirmEmail, body);
    yield put(userConfirmEmailSuccess(response));
  } catch (error) {
    yield put(userConfirmEmailError(error));
  }
}

function* userPatchApi({ payload:  body  }) {
  try {
    const response = yield call(userPatch, body, body.id);
    yield put(userPatchSuccess(response));
  } catch (error) {
    yield put(userPatchError(error?.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(USER_CREATE, userCreateUserApi);
  yield takeEvery(USER, userGetApi);
  yield takeEvery(USER_PASSWORD_RESET, userInitPasswordResetApi);
  yield takeEvery(USER_CONFIRM_PASSWORD, userConfirmPasswordResetApi);
  yield takeEvery(USER_CONFIRM_EMAIL, userConfirmEmailApi);
  yield takeEvery(USER_PATCH, userPatchApi);
}

export default authSaga;
