import {
  LocalStorageProps,
  LocalStorageStepsProps,
  Step,
  UserLogin,
  Variables,
} from "../interface/localstorage";

import { Steps } from "../enum/steps";
import { MenuItem } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import { NavigateStructure } from "../interface/nav";
import { ActionFlagsModel } from "../model/actionFlags";

class LocalStorageService {
  private storageKey: string;
  private _ls: LocalStorageProps;
  private _lsLogin: UserLogin;
  private _lsStep: LocalStorageStepsProps;
  private _mh: MenuItem[];
  private _pageActionFlags: ActionFlagsModel[];
  private _mhSide: NavigateStructure[];
  private _variables: Variables[];

  constructor(storageKey: string) {
    this.storageKey = storageKey;
    this._ls = { email: "", token: "", userId: "", userName: "" };
    this._lsLogin = {
      token: "",
      clientId: "",
      countryId: "",
      branchId: "",
      userId: "",
      roles: [],
      email: "",
      userName: "",
      idToken: {
        jwtToken: "",
        payload: {
          name: "",
          family_name: "",
        },
      },
    };
    this._mh = [];
    this._pageActionFlags = [];
    this._variables = [];
    this._mhSide = [];
    this._lsStep = {
      steps: [
        {
          step: Steps.insuranceType,
          id: "",
          name: "",
          completed: false,
        },
        {
          step: Steps.insurance,
          id: "",
          name: "",
          completed: false,
        },
        {
          step: Steps.plan,
          id: "",
          name: "",
          completed: false,
        },
        {
          step: Steps.customer,
          id: "",
          name: "",
          completed: false,
        },
        {
          step: Steps.beneficiary,
          id: "",
          name: "",
          completed: false,
        },
        {
          step: Steps.confirmData,
          id: "",
          name: "",
          completed: false,
        },
        {
          step: Steps.pay,
          id: "",
          name: "",
          completed: false,
        },
      ],
    };
  }

  public getUserStored(): LocalStorageProps {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._ls = JSON.parse(storedData);
      return this._ls;
    }
    return this._ls;
  }

  public setUserStored(user: string): void {
    const storedData = JSON.parse(user);
    localStorage.setItem(this.storageKey, JSON.stringify(storedData));
  }

  public setLogout(): void {
    localStorage.removeItem(this.storageKey);
  }

  public isAuthenticated(): boolean {
    return !!this.getUserStored().token;
  }

  public getUserLoginStored(): UserLogin {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._lsLogin = JSON.parse(storedData);
      return this._lsLogin;
    }
    return this._lsLogin;
  }

  public setUserLoginStored(userLogin: UserLogin): void {
    localStorage.setItem(this.storageKey, JSON.stringify(userLogin));
  }

  /*Steps*/
  public getStepStored(): LocalStorageStepsProps {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._lsStep = JSON.parse(storedData);
      return this._lsStep;
    }
    return this._lsStep;
  }

  public setStepStored(step: Step): void {
    const steps = this.getStepStored();
    const foundStep = steps.steps.find((e) => e.step === step.step);

    if (foundStep) {
      foundStep.id = step.id;
      foundStep.name = step.name;
      foundStep.completed = step.completed;
      foundStep.campaign = step.campaign;
      foundStep.coverType = step.coverType;
      foundStep.campaignSelected = step.campaignSelected;
      foundStep.paymentMode = step.paymentMode;
      foundStep.paymentModeSelected = step.paymentModeSelected;
      foundStep.quoteId = step.quoteId;
      foundStep.policyId = step.policyId;
      foundStep.email = step.email;
    }

    //const storedData = JSON.parse(steps);
    localStorage.setItem(this.storageKey, JSON.stringify(steps));
  }

  /*END Steps*/

  /*Set Menu Options*/
  public setMenuHorizontalStored(menuHorizontal: MenuItem[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(menuHorizontal));
  }

  public getMenuHorizontalStored(): MenuItem[] {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._mh = JSON.parse(storedData);
      return this._mh;
    }
    return this._mh;
  }

  /*End*/

  /*Set Page Action Flags*/
  public setPageActionFlagsStored(pageActionFlags: ActionFlagsModel[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(pageActionFlags));
  }

  public getPageActionFlagsStored(): ActionFlagsModel[] {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._pageActionFlags = JSON.parse(storedData);
      return this._pageActionFlags;
    }
    return this._pageActionFlags;
  }

  public setVariablesStored(variables: any): void {
    console.log("variables", variables);
    console.log(this.storageKey);
    console.log(JSON.stringify(variables));
    localStorage.setItem(this.storageKey, JSON.stringify(variables));
  }

  public getVariablesStored(): any {
    const storedData = localStorage.getItem(this.storageKey);

    if (storedData) {
      this._variables = JSON.parse(storedData);
      return this._variables;
    }
    return this._variables;
  }

  /*Set Menu SideBar*/
  public setMenuSideBarStored(menuSideBar: NavigateStructure[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(menuSideBar));
  }

  public getMenuSideBarStored(): NavigateStructure[] {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._mhSide = JSON.parse(storedData);
      return this._mhSide;
    }
    return this._mhSide;
  }

  /*End*/
}

export default LocalStorageService;
