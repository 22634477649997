import React, { useEffect, useState } from "react";
import {
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { ReportView } from "./view";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogStatusQuote } from "../../store/catalog/actions";
import { showErrorToast } from "../../fnx/showError";
import {
  Status,
  StatusOptionsModel,
} from "../../model/insurancePoliciesSummary";
import showToast from "../../services/toast";
import { useTranslation } from "react-i18next";

export const ReportPage = () => {
  const { t } = useTranslation("global");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(5000);
  const [loadingAllQuote] = useState<boolean>(false);
  const [idStatus, setIdStatus] = useState<Status>({});
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const {
    responseGetCatalogStatusQuote,
    errorGetCatalogStatusQuote,
    loadingGetCatalogStatusQuote,
  } = useSelector((state: any) => ({
    responseGetCatalogStatusQuote:
      state.Catalog.responseGetCatalogStatusQuote?.data,
    errorGetCatalogStatusQuote: state.Catalog.errorGetCatalogStatusQuote,
    loadingGetCatalogStatusQuote: state.Catalog.loadingGetCatalogStatusQuote,
  }));

  useEffect(() => {
    if (!responseGetCatalogStatusQuote) {
      dispatch(
        getCatalogStatusQuote({ enable: enable, page: page, limit: limit }),
      );
    }
  }, []);

  useEffect(() => {
    if (errorGetCatalogStatusQuote) {
      showErrorToast(errorGetCatalogStatusQuote, setSeeToast, t);
    }
  }, [errorGetCatalogStatusQuote]);

  useEffect(() => {
    if (Array.isArray(responseGetCatalogStatusQuote)) {
      setStatusOptionsSto(responseGetCatalogStatusQuote);
    }
  }, [responseGetCatalogStatusQuote]);

  const setStatusOptionsSto = (
    responseGetCatalogStatusQuote: StatusOptionsModel[],
  ) => {
    let status: Status = {};

    responseGetCatalogStatusQuote.forEach((item) => {
      if (item.id && item.description) {
        if (item.description === "Emitida") {
          status.emitida = item.id;
        } else if (item.description === "Cancelada") {
          status.cancelada = item.id;
        }
      }
    });
    setIdStatus(status);
  };

  const openReport = (e: MenuItem) => {
    switch (e.code) {
      case "Emitida":
        navigate(`/report/sales/${idStatus.emitida}`);
        break;
      case "Cancelada":
        navigate(`/report/sales/${idStatus.cancelada}`);
        break;
      case "VentasCanceladas":
        navigate(`/report/sales/`);
        break;
    }
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}
      <LoadingAFY
        loading={loadingAllQuote}
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Cargando Pólizas."
        bgColor={themeCore.colors.backgroundLoader}
      />
      <ReportView openReport={openReport} />
    </React.Fragment>
  );
};
