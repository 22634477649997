import {
  iconsSvg,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const dataCard: MenuItem[] = [
  {
    code: "Emitida",
    id: "1",
    name: "Reporte de ventas completo",
    iconSvg: iconsSvg.salesReport,
  },
  {
    code: "Cancelada",
    id: "2",
    name: "Reporte de pólizas canceladas",
    iconSvg: iconsSvg.canceledSales,
  },
  {
    code: "VentasCanceladas",
    id: "3",
    name: "Reporte de ventas y cancelaciones",
    iconSvg: iconsSvg.insuranceReport,
  },
];

export const dataCard2: MenuItem[] = [
  {
    id: "1",
    name: "Ventas totales",
    total: 100,
  },
  {
    id: "2",
    name: "Canceladas totales",
    total: 50,
  },
];

export const dataBarchartInsurance: any[] = [
  {
    Ventas: 75,
    insurance: "Cancer",
  },
  {
    Ventas: 66,
    insurance: "Vida",
  },
  {
    Ventas: 80,
    insurance: "Auto",
  },
  {
    Ventas: 13,
    insurance: "Mascota",
  },
  {
    Ventas: 56,
    insurance: "Dental",
  },
];

export const dataBarchart: any[] = [
  {
    Canceladas: 13,
    Ventas: 75,
    month: "January",
  },
  {
    Canceladas: 17,
    Ventas: 66,
    month: "February",
  },
  {
    Canceladas: 6,
    Ventas: 80,
    month: "March",
  },
  {
    Canceladas: 6,
    Ventas: 13,
    month: "April",
  },
  {
    Canceladas: 25,
    Ventas: 56,
    month: "May",
  },
  {
    Canceladas: 17,
    Ventas: 33,
    month: "June",
  },
  {
    Canceladas: 20,
    Ventas: 45,
    month: "July",
  },
  {
    Canceladas: 4,
    Ventas: 20,
    month: "August",
  },
  {
    Canceladas: 9,
    Ventas: 15,
    month: "September",
  },
  {
    Canceladas: 5,
    Ventas: 85,
    month: "October",
  },
  {
    Canceladas: 25,
    Ventas: 65,
    month: "November",
  },
  {
    Canceladas: 15,
    Ventas: 75,
    month: "December",
  },
];
