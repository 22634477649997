import { URLs } from "../url";
import { getHeader } from "../headers";
import { RolResponse } from "../../model/rol";

const getRol = async (
  clientId: string,
  enable: boolean,
  page: number,
  limit: number,
  name: string,
  description: string,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.rol +
    `?clientId=${clientId}` +
    `&enable=${enable}&page=${page}&limit=${limit}` +
    (name ? `&name=${name}` : "") +
    (description ? `&description=${description}` : "");

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as RolResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postRol = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.rol;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as RolResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const patchRol = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.rol + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as RolResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const disableRol = async (id: string) => {
  let newURL =
    process.env.REACT_APP_HOST_API + URLs.rol + `/${id}` + "/Disable";
  const requestOptions = {
    method: "PATCH",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as RolResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getRol, postRol, patchRol, disableRol };