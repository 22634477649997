import {
  MenuItem,
  state,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const stepProgressDTO = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.inProgress,
    },
    {
      id: "4",
      name: "Asegurado --",
      state: state.initialState,
    },
    {
      id: "5",
      name: "Beneficiario --",
      state: state.initialState,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.initialState,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.initialState,
    },
  ];
};

export const stepProgress1DTO = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.inProgress,
    },
    {
      id: "2",
      name: "Nombre del seguro",
      state: state.initialState,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.initialState,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.initialState,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.initialState,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.initialState,
    },
  ];
};

export const stepProgress2DTO = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Nombre del seguro",
      state: state.inProgress,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.initialState,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.initialState,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.initialState,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.initialState,
    },
  ];
};

export const stepProgress3DTO = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.inProgress,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.initialState,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.initialState,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.initialState,
    },
  ];
};

export const stepProgress4DTO = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.inProgress,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.initialState,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.initialState,
    },
  ];
};

export const stepProgress5DTO = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.complete,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.complete,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.inProgress,
    },
  ];
};

export const stepProgress6DTO = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.complete,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.complete,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.inProgress,
    },
  ];
};

export const stepProgress7DTO = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.complete,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.complete,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.complete,
    },
  ];
};

//BANESCO
export const stepProgressBanesco1 = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Nombre del producto",
      state: state.inProgress,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.initialState,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.initialState,
    },
    {
      id: "4",
      name: "Confirmación",
      state: state.initialState,
    },
    {
      id: "5",
      name: "Datos de pago",
      state: state.initialState,
    },
    {
      id: "6",
      name: "Confirmación de pago",
      state: state.initialState,
    },
  ];
};

export const stepProgressBanesco2 = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.inProgress,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.initialState,
    },
    {
      id: "4",
      name: "Confirmación",
      state: state.initialState,
    },
    {
      id: "5",
      name: "Datos de pago",
      state: state.initialState,
    },
    {
      id: "6",
      name: "Confirmación de pago",
      state: state.initialState,
    },
  ];
};
export const stepProgressBanesco3 = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.inProgress,
    },
    {
      id: "4",
      name: "Confirmación",
      state: state.initialState,
    },
    {
      id: "5",
      name: "Datos de pago",
      state: state.initialState,
    },
    {
      id: "6",
      name: "Confirmación de pago",
      state: state.initialState,
    },
  ];
};

export const stepProgressBanesco4 = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.complete,
    },
    {
      id: "4",
      name: "Confirmación",
      state: state.inProgress,
    },
    {
      id: "5",
      name: "Datos de pago",
      state: state.initialState,
    },
    {
      id: "6",
      name: "Confirmación de pago",
      state: state.initialState,
    },
  ];
};

export const stepProgressBanesco5 = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.complete,
    },
    {
      id: "4",
      name: "Confirmación",
      state: state.complete,
    },
    {
      id: "5",
      name: "Datos de pago",
      state: state.inProgress,
    },
    {
      id: "6",
      name: "Confirmación de pago",
      state: state.initialState,
    },
  ];
};
export const stepProgressBanesco6 = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.complete,
    },
    {
      id: "4",
      name: "Confirmación",
      state: state.complete,
    },
    {
      id: "5",
      name: "Datos de pago",
      state: state.complete,
    },
    {
      id: "6",
      name: "Confirmación de pago",
      state: state.inProgress,
    },
  ];
};

export const stepProgressFinished = (): MenuItem[] => {
  return [
    {
      id: "1",
      name: "Nombre del producto",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.complete,
    },
    {
      id: "4",
      name: "Confirmación",
      state: state.complete,
    },
    {
      id: "5",
      name: "Datos de pago",
      state: state.complete,
    },
    {
      id: "6",
      name: "Confirmación de pago",
      state: state.complete,
    },
  ];
};
