import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "./view";
import { Branches, BranchesRequest } from "../../model/branches";
import LocalStorageService from "../../services/localStorage";
import { useBranchesGetAll } from "../../fnx/branches/branchesGetAll";
import { deleteEntityClearData } from "../../store/delete/actions";

import {
  CardGeneralCatalogProps,
  MenuItem,
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { useBranchesPost } from "../../fnx/branches/branchesPost";
import { useBranchesPatch } from "../../fnx/branches/branchesPatch";
import { showErrorToast } from "../../fnx/showError";
import { menuItem, optionSelect, selectValueDefault } from "./branches.Dto";
import { SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const BranchesAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("global");
  const [listBranches, setListBranches] = useState<CardGeneralCatalogProps[]>(
    [],
  );
  const [trigger, setTrigger] = useState(false);
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [idBranches, setIdBranches] = useState("");
  const [valueName, setValueName] = useState();
  const [valueCode, setValueCode] = useState();
  const [bodyRequest, setBodyRequest] = useState<BranchesRequest>({
    data: {
      clientId: "",
    },
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [dataForm, setDataForm] = useState<any>({
    Id: "",
    Name: "",
    Code: "",
  });

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseBranches, errorBranches, loadingBranches } =
    useBranchesGetAll(trigger, clientId, valueName, valueCode);

  const { responseBranchesPost, errorBranchesPost, loadingBranchesPost } =
    useBranchesPost(triggerPost, bodyRequest);

  const { responseBranchesPatch, errorBranchesPatch, loadingBranchesPatch } =
    useBranchesPatch(triggerPatch, bodyRequest, idBranches);

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const callBranches = () => {
    setTrigger(!trigger);
  };

  useEffect(() => {
    callBranches();
  }, []);

  const callBranchesPost = () => {
    setTriggerPost(!triggerPost);
  };

  const callBranchesPatch = () => {
    setTriggerPatch(!triggerPatch);
  };

  /*Valida respuesta del getAll y mapea objetos*/
  useEffect(() => {
    if (responseBranches && responseBranches?.length > 0) {
      const listBranches: CardGeneralCatalogProps[] = responseBranches.map(
        (item: Branches) => ({
          id: item.id,
          codeLabel: "Name: ",
          code: item.name,
          typeLabel: "Codigo: ",
          type: item.code,
          fontSizeType: 11,
          clientId: item.clientId,
        }),
      );
      setListBranches(listBranches);
      setTrigger(false);
    } else if (responseBranches && responseBranches?.length == 0) {
      setTrigger(false);
      setListBranches([]);
    }
  }, [responseBranches]);

  /*Valida respuesta cuando ya se inserto el registro*/
  useEffect(() => {
    setTriggerPost(false);
    if (responseBranchesPost) {
      setSeeToast({
        show: true,
        message: "Creado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenModal(false);
    }
    callBranches();
  }, [responseBranchesPost]);

  /*Valida respuesta cuando ya se actualizo el registro*/
  useEffect(() => {
    setTriggerPost(false);
    if (responseBranchesPatch) {
      setSeeToast({
        show: true,
        message: "Registro actualizado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenModal(false);
    }
    callBranches();
  }, [responseBranchesPatch]);

  /*Muestra si tubo errores el GET ALL  de sucursales*/
  useEffect(() => {
    if (errorBranches) {
      showErrorToast(errorBranches, setSeeToast, t);
    }
  }, [errorBranches]);

  /*Muestra si tubo errores el POST de sucursales*/
  useEffect(() => {
    if (errorBranchesPost) {
      showErrorToast(errorBranchesPost, setSeeToast, t);
    }
  }, [errorBranchesPost]);

  /*Muestra si tubo errores el Patch de sucursales*/
  useEffect(() => {
    if (errorBranchesPatch) {
      showErrorToast(errorBranchesPatch, setSeeToast, t);
    }
  }, [errorBranchesPatch]);

  useEffect(() => {
    if (responseDeleteEntity) {
      setSeeToast({
        show: true,
        message: "Eliminado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenModal(false);
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      setSeeToast({
        show: true,
        message: errorDeleteEntity?.message.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      Id: row.id,
      Name: row.code,
      Code: row.type,
      Status: row.status,
      clientId: clientId,
    });
  };

  /*Submit para actualizar o agregar un registro*/
  const handleSubmitBranches = (dataBranches: any) => {
    if (isUpdate) {
      /*Se llena la informacion al momento de acutalizar*/
      /*Se crea objeto en base al body del api*/
      setBodyRequest({
        data: {
          name: dataBranches.Name,
          code: dataBranches.Code,
          status: "1",
          clientId: clientId,
        },
      });
      // Se asigna el id del ProjectType para a actualizar
      setIdBranches(dataForm.Id);

      /*Llamda al FNX para actualizar PATCH*/
      callBranchesPatch();
    } else {
      /*Se procede a crear un nuevo Registro*/
      /*Se crea objeto en base al body del api*/
      setBodyRequest({
        data: {
          name: dataBranches.Name,
          code: dataBranches.Code,
          status: "1",
          clientId: clientId,
        },
      });
      /*Llamada al FNX para crear Registro*/
      callBranchesPost();
    }
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const onClickClearData = (Data: any) => {
    // Valida clean filtro y limpia los filtros
    if (Data.filter.type_filter === "empty") {
      setValueName(undefined);
      setValueCode(undefined);
    }
    // Llama a la consulta general sin filtros
    callBranches();
  };
  const onClickApplyFilter = (Data: any) => {
    if (Data.filter.type_filter === "name") {
      setValueName(Data.filter.filter_value);
      setValueCode(undefined);
    } else if (Data.filter.type_filter === "code") {
      setValueCode(Data.filter.filter_value);
      setValueName(undefined);
    }
    callBranches();
  };
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    console.log("Event", event);
  };
  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenModal(true);
    setDataForm({
      ...dataForm,
      Id: "",
      Name: "",
      code: "",
      status: "1",
      clientId: "",
    });
  };
  const onClickContextsMenu = (
    opt: MenuItem,
    card: CardGeneralCatalogProps,
  ) => {
    if (opt.id === "1") {
      // Opcion Editar
      setIsUpdate(true);
      setOpenModal(true);
      showEdit(card);
    } else if (opt.id === "2") {
      // Opcion eliminar
      // setitemModal(item);
      // setOpenModalConfirm(true);
      setSeeToast({
        show: true,
        message: "Operación no permitida!",
        type: typeAlert.warning,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.warning,
        });
      }, 3000);
      setOpenModal(false);
    }
  };

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleCloseModalConfirm = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };

  /*Api que se ejecuta cuando el catalogo tiene la opcion
   * para eliminar registros*/
  const handleDelete = () => {
    setSeeToast({
      show: true,
      message: "Operación no permitida!",
      type: typeAlert.warning,
    });
    setTimeout(() => {
      setSeeToast({
        show: false,
        message: "",
        type: typeAlert.warning,
      });
    }, 3000);
    setOpenModal(false);
  };
  const handleClone = () => {};

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}
      <View
        title="Sucursales"
        generalCatalogTitle={
          isUpdate ? "Modificar Sucursal" : "Agregar Sucursal"
        }
        list={listBranches}
        openModal={openModal}
        openModalConfirm={openModalConfirm}
        openModalCloneConfirm={openModalCloneConfirm}
        loadingPost={null}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        clickView={clickView}
        onSubmit={handleDelete}
        onSubmitClone={handleClone}
        onClose={handleCloseModal}
        onCloseConfirm={handleCloseModalConfirm}
        onCloseCloneModal={handleCloseCloneModal}
        handleOpenModal={handleOpenModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}
        onFilterApply={onClickApplyFilter}
        onSaveData={handleSubmitBranches}
        onClickAdd={handleOnClickAdd}
        menuItem={menuItem}
        onClickContextMenu={onClickContextsMenu}
        onClickCancel={handleCloseModal}
        optionsSelect={optionSelect}
        onChangeSelect={handleSelectChange}
        onFilterEmpty={onClickClearData}
        selectValueDefault={selectValueDefault}
      />
    </React.Fragment>
  );
};

export default BranchesAdmin;
