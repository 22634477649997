import { 
  DataFormClient,
  ContextMenuAFY,
  icons,
  sizesIcon,
 } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
 import { SpanStatus } from "./style";
 import { hasPermission } from "../../services/getNavigationPages";

export const userDataForm = {
  id: "",
  name: "",
  lastName: "",
  birthDate: "",
  identificationType: "",
  identificationNumber: "",
  genderType: "",
  userName: "",
  email: "",
  password: "", 
  passwordConfirm: "",
  companyType: "",
  branchType: "", 
  roleType: "",
  intermediaryType: ""
};

export const ColumnsUser = (handleClickContextMenu: any) => {
  return [
    {
      field: "actions",
      headerName: "Acciones",
      width: 80,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            
              <ContextMenuAFY
                colorButton="#fff"
                iconButton={icons.MoreVert}
                iconColor="#3A56A1"
                menuItem={[
                  {
                    disabled: hasPermission("admin-users-edit") ? false : true,
                    icon: {
                      color: "#006ac6",
                      icon: icons.Edit,
                      size: sizesIcon.standard,
                    },
                    id: "1",
                    name: "Modificar",
                  },
                  {
                    icon: {
                      color: "#006ac6",
                      icon: icons.Delete,
                      size: sizesIcon.standard,
                    },
                    disabled: hasPermission("admin-users-delete") ? false : true,
                    id: "2",
                    name: "Eliminar",
                  },
                  
                ]}
                onClickContextMenu={(item: any) =>
                   handleClickContextMenu(item, params)
                }
                sizeIcon={sizesIcon.standard}
              />
          </div>
        );
      },
    },
    {
      editable: false,
      field: "Enable",
      headerName: "Estado",
      width: 100,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <SpanStatus
              style={{
                backgroundColor:
                  params.row.Enable === "true" ? "#46c51a" : "#ef2f24",
              }}
            ></SpanStatus>
            {params.row.Enable ==="true" ? "Activo" : "Inactivo"}
          </div>
        );
      },
    },
    {
      field: "UserName",
      headerName: "User Name",
      width: 300,
    },
    {
      field: "Name",
      headerName: "Nombre",
      width: 180,
    },
    {
      field: "LastNames",
      headerName: "Apellidos",
      width: 180,
    },
    {
      field: "BranchName",
      headerName: "Sucursal",
      width: 180,
    },
    {
      field: "RoleName",
      headerName: "Rol",
      width: 180,
    },
  ];
};