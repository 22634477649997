import { call, put, takeEvery } from "redux-saga/effects";
import { 
	plansError,
	planOneSuccess,
	plansSuccess,
	postPlanError,
	postPlanSuccess,
	patchPlanError,
	patchPlanSuccess,	
} from "./actions";
import {  POST_PLAN, PLANS, PLAN_ONE, PATCH_PLAN } from "./actionTypes";
import { getPlans, getPlanIdApi, postPlanApi, patchPlanApi} from "../../resources/helper/plans";


function* plans({ payload: { plans } }) {
  try {
    const response = yield call(
      getPlans,
      plans.enable,
      plans.page,
      plans.limit,
      plans.projectId,
      plans.name,
      plans.paymentModeId,
      plans.coverTypeId,
      plans.clientId,
    );
    yield put(plansSuccess(response));
  } catch (error) {
    yield put(plansError(error));
  }
}

function* onePlan({ payload: { plan } }) {
  try {
    const response = yield call(getPlanIdApi, plan.id);
    yield put(planOneSuccess(response));
  } catch (error) {
    yield put(plansError(error));
  }
}

function* postPlan({ payload: { planBody } }) {
  try {
    const response = yield call(postPlanApi, planBody);
    yield put(postPlanSuccess(response));
  } catch (error) {
    yield put(postPlanError(error));
  }
}

function* patchPlan({ payload: { planBody } }) {
  try {
    const response = yield call(patchPlanApi, planBody);
    yield put(patchPlanSuccess(response));
  } catch (error) {
    yield put(patchPlanError(error));
  }
}

function* plansSaga() {
  yield takeEvery(PLANS, plans);
  yield takeEvery(PLAN_ONE, onePlan);
  yield takeEvery(POST_PLAN, postPlan);
  yield takeEvery(PATCH_PLAN, patchPlan);
}

export default plansSaga;
