export enum Steps {
  insuranceType = 0,
  insurance = 1,
  plan = 2,
  customer = 3,
  beneficiary = 4,
  confirmData = 5,
  pay = 6,
}

export enum StepsNames {
  insuranceType = 0,
  insurance = 1,
  plan = 2,
  customer = 3,
  beneficiary = 4,
  confirmData = 5,
  pay = 6,
}
