import { URLs } from "../url";
import { InsuranceResponse } from "../../model/insurance";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { OneProjectResponse } from "../../model/project";
import { getHeader } from "../headers";

const getProject = async (
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
  projectType: string,
  sponsorId: string,
  description: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.insurance +
    `?enable=${enable}&page=${page}&limit=${limit}` +
    (clientId ? `&clientId=${clientId}` : "") +
    (projectType ? `&projectType=${projectType}` : "") +
    (sponsorId ? `&sponsorId=${sponsorId}` : "") +
    (description ? `&description=${description}` : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getProjectId = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.project + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as OneProjectResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postProjectApi = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.project;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as InsuranceResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const patchProjectApi = async (projectBody: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.project + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: projectBody }),
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getProject, postProjectApi, patchProjectApi, getProjectId };
