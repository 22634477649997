import {
  icons,
  MenuItem,
  select,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ButtonOption } from "../../model/sponsor";

export const userView: MenuItem[] = [
  {
    active: false,
    icon: {
      icon: icons.List,
    },
    id: "1",
    name: "List",
  },
  {
    active: true,
    icon: {
      icon: icons.GridView,
    },
    id: "2",
    name: "GridView",
  },
];

export const buttonActionOptions: ButtonOption[] = [
  {
    icons: icons.Menu,
    id: "1",
    name: "Modificar",
  }
];

export const buttonActionOptionsList: MenuItem[] = [
  {
    disabled: false,
    id: "1",
    name: "Modificar",
    divider: false,
    icon: {
      color: "#006ac6",
      icon: icons.Edit,
      size: sizesIcon.standard,
    },
  },
];

export const optionsSelect: select[] = [
  {
    id: "1",
    name: "Banesco",
    value: "1",
  },
];
