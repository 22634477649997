import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { themeCore } from "../../assets/themes/theme";

export const LoginFormDocPlace = styled.section`
  background-color: ${themeCore.colors.primary};
`;

export const useStyles = makeStyles((theme) => ({
  content: {
    margin: "0",
    width: "100%",
    backgroundColor: "#ffffff",
    height: "calc(100vh - 0px)",
    padding: "10px",
    display: "contents",
  },
  card: {
    padding: "10px",
  },
}));
