import React, { useEffect } from "react"; // @ts-ignore
import { stepProgress1DTO, stepProgressDTO } from "../../dataBase/stepProgress";
import { insuranceType } from "../../store/insuranceType/actions";
import LogoAFY from "../../assets/images/insuranceType.png";
import { useDispatch, useSelector } from "react-redux";
import {
  breadcrumb,
  CardProps,
  MenuItem,
  position,
  theme,
  ToastAFY,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { InsuranceTypeView } from "./view";
import { useNavigate } from "react-router-dom";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import {
  InsuranceTypeModel,
  InsuranceTypeSetStoryBook,
  InsuranceTypeStoryBook,
} from "../../model/insuranceType";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

const InsuranceTypePage = () => {
  const navigate = useNavigate();
  const [onInit] = React.useState<boolean>(true);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(10);
  const [insuranceTypeData, setInsuranceTypeData] = React.useState<
    InsuranceTypeStoryBook[]
  >([]);
  const { responseInsuranceType, errorInsuranceType, loading } = useSelector(
    (state: any) => ({
      responseInsuranceType: state.InsuranceType.responseInsuranceType?.data,
      errorInsuranceType: state.InsuranceType.errorInsuranceType,
      loading: state.InsuranceType.loadingInsuranceType,
    }),
  );

  useEffect(() => {
    setBreadCrumbs([
      {
        name: "Inicio",
        key: "home",
        ariaCurrent: "",
      },
      {
        name: "Tipo Seguro",
        key: "insuranceType",
        ariaCurrent: "",
      },
    ]);
    setSteps(stepProgress1DTO());
    if (!responseInsuranceType) {
      dispatch(
        insuranceType({
          enable: enable,
          page: page,
          limit: limit,
          clientId: clientId,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (responseInsuranceType) {
      const dataST: InsuranceTypeSetStoryBook = { data: [] };
      responseInsuranceType.map((item: InsuranceTypeModel) => {
        dataST.data?.push({
          id: item.id,
          title: item.description,
          text: item.logoUrl ? "" : item.description.slice(0, 2).toLowerCase(),
          logoUrl: item.logoUrl && item.logoUrl,
        });
      });
      setInsuranceTypeData(dataST.data);
    }
  }, responseInsuranceType);

  const dispatch = useDispatch();
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);

  useEffect(() => {
    if (onInit) {
      setBreadCrumbs([
        {
          name: "Inicio",
          key: "home",
          ariaCurrent: "",
        },
        {
          name: "Tipo Seguro",
          key: "insuranceType",
          ariaCurrent: "",
        },
      ]);
      setSteps(stepProgress1DTO());
      if (!responseInsuranceType) {
        dispatch(
          insuranceType({
            enable: enable,
            page: page,
            limit: limit,
            clientId: clientId,
          }),
        );
      }
    }
  }, [onInit]);

  useEffect(() => {
    if (responseInsuranceType && Array.isArray(responseInsuranceType)) {
      const dataST: InsuranceTypeSetStoryBook = { data: [] };
      responseInsuranceType.map((item: InsuranceTypeModel) => {
        dataST.data?.push({
          id: item.id,
          title: item.description,
          text: item.logoUrl ? "" : item.description.slice(0, 2).toLowerCase(),
          logoUrl: item.logoUrl ? item.logoUrl : LogoAFY,
        });
      });
      setInsuranceTypeData(dataST.data);
    }
  }, [responseInsuranceType]);

  const choiceInsuranceType = (value: CardProps) => {
    localStorageService.setStepStored({
      id: value.id,
      name: value.title as string,
      step: Steps.insuranceType,
      completed: true,
      campaign: value.campaign!,
      campaignSelected: value.campaign ? value.campaign[0] : "",
    });

    const toastComponent = (
      <ToastAFY
        autoclose={5000}
        closeOnClick
        newstOnTop
        pauseOnHover
        position={position.topRight}
        theme={theme.light}
        title="Tipo seguro seleccionado, Exitosamente!"
        type={typeAlert.success}
      />
    );

    navigate("/insurance");
    return toastComponent;
  };

  return (
    <InsuranceTypeView
      steps={steps}
      insurance={insuranceTypeData}
      onClick={choiceInsuranceType}
      breadCrumbs={breadCrumbs}
      title={"Affinity Sales - Insurance"}
      loading={loading}
    />
  );
};
export default InsuranceTypePage;
