import { URLs } from "../url";
import { CatalogResponse } from "../../model/catalog";
import { getHeader } from "../headers";

const deleteEntityApi = async (
  id: string,
  entity: string,
  idUser: string,
  body: any,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.deleteEntity +
    `/${entity}/${id}/user-mod/${idUser}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify(body),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as CatalogResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { deleteEntityApi };
