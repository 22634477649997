import React, { useEffect } from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  ButtonAFY,
  icons,
  InformationAFY,
  InputAFY,
  ModalComponentAFY,
  PoliciesAFY,
  ReportFiltersAFY,
  SelectAFY,
  sizes,
  sizesIcon,
  sizesSelect,
  sizesTextField,
  variants,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { buttonActionOptions } from "./insurancePoliciesDTO";
import { themeCore } from "../../assets/themes/theme";
import { Title } from "../../components/layout/style";
import MetaTags from "react-meta-tags";
import { hasPermission } from "../../services/getNavigationPages";

const View = (props: any) => {
  const classes = useStyles();
  const [reasonCancellation, setReasonCancellation] = React.useState<any>("");

  const handleChange = (value: any) => {
    setReasonCancellation(value);
  };

  useEffect(() => {
    if (props.reasonCancellation.length > 0)
      setReasonCancellation(props.reasonCancellation[0].value);
  }, [props.reasonCancellation]);

  return (
    <React.Fragment>
      <Title>Pólizas</Title>
      <InformationAFY
        backgroundColor="#F5F5F5"
        colorText="#666666"
        description="si desea aplicar un filtro al resultado de ventas completo, debe seleccionar los diferentes filtros que se muestra en la parte superior."
        icon={icons.Help}
        iconColor="#3A56A1"
        iconSize={sizesIcon.standard}
      />
      <ModalComponentAFY
        openModal={props.openModalConfirm}
        backgroundColor="#ffffff"
        onClose={props.onCloseConfirm}
        border={0}
        minWidthModal={"500px"}
        padding={40}
      >
        <div>
          <h2 className={classes.title}>Confirmación </h2>
          <div className={classes.components}>
            <div className={classes.component}>
              <div className={classes.label}>{"Seleccione la razón"}</div>
              <SelectAFY
                value={""}
                variant={variantsTextField.filled}
                disableUnderline={true}
                fontSize={14}
                size={sizesSelect.small}
                options={props.reasonCancellation}
                onChange={(e: any) => {
                  e.stopPropagation();
                  handleChange(e.target.value);
                }}
              />
            </div>
            <div className={classes.component}>
              <div className={classes.label}>{"Ingrese la contraseña"}</div>
              <InputAFY
                defaultValue={""}
                fullWidth
                type="password"
                size={sizesTextField.small}
                variant={variantsTextField.outlined}
              />
            </div>
          </div>
          <div className={classes.buttonsContainerConfirm}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Cancelar"}
                backgroundColor="red"
                onClick={props.onCloseConfirm}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Guardar"}
                onClick={(e) => {
                  e.stopPropagation();
                  props.onSubmit(reasonCancellation);
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <ModalComponentAFY
        openModal={props.openModal}
        backgroundColor="#ffffff"
        onClose={props.onClose}
        border={0}
        minWidthModal={"500px"}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Cancelar {props.modalTitle}</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea cancelar la ${props.modalTitle}?`}
          </p>

          <div className={classes.components}>
            <div>
              <div className={classes.modalLabel}>{props.modalTitle}:</div>{" "}
              {props.row.certificate}
            </div>

            <div>
              <div className={classes.modalLabel}>Nombre:</div>{" "}
              {props.row.names}
            </div>
            <div>
              <div className={classes.modalLabel}>Estatus:</div>{" "}
              {props.row.status}
            </div>
          </div>

          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                backgroundColor="red"
                onClick={props.onClose}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, cancelar"}
                onClick={(e) => {
                  e.stopPropagation();
                  if (props.modalTitle === "póliza") {
                    props.handleOpenModalConfirm();
                  } else {
                    props.onSubmit("Cancelado");
                  }
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      <div style={{ marginTop: "15px", width: "calc(86vw - 100px)" }}>
        <PoliciesAFY
          disabledButtonActions={!hasPermission("sale-policies-sumary-export")}
          onChangeSearch={(term) => props.searchFilter(term)}
          onCloseSearch={props.onCloseSearch}
          pageSize={8}
          textFilterSearch="Buscar"
          titleButton="Limpiar filtros"
          titlePage="Pólizas"
          titleSelectBranch="Sucursal"
          titleSelectInsurance="Producto"
          titleSelectStatus="Estado"
          placeholderTextPicker="Seleccione rango de fechas"
          placeholderTextSearch="Buscar"
          showSelectBranch={true}
          showSelectInsurance={true}
          showSelectStatus={true}
          avatarVariant={variantsAvatar.rounded}
          backgroundColorSearch="#fff"
          buttonActionOptions={buttonActionOptions}
          colorTextTitlePage={themeCore.colors.primary}
          heightGrid={"calc(100vh - 353px)"}
          iconAvatar={icons.Search}
          iconBgColor={themeCore.colors.lightBlue}
          iconColor={themeCore.colors.primaryText}
          iconColorSearch={themeCore.colors.primaryText}
          iconSearch={icons.Search}
          labelButtonActions="Exportar"
          onBlurSearch={function noRefCheck() {}}
          onChangeData={props.filterStatus}
          onClickButtonOptions={props.onClickDownload}
          onClickCancel={function noRefCheck() {}}
          onClickClear={props.getQuoteAll}
          onClickSeeDetails={function noRefCheck() {}}
          sizeIcon={sizesIcon.standard}
          sizesIconSearch={sizesIcon.standard}
          statusOptions={props.statusOptions}
          branchOptions={props.branchOptions}
          insuranceOptions={props.insuranceOptions}
          columns={props.columns}
          rows={props.rows}
        />
      </div>
    </React.Fragment>
  );
};

export default View;
