import { combineReducers } from "redux";
import InsuranceType from "./insuranceType/reducer";
import IdentificationType from "./identificationType/reducer";
import Countries from "./countries/reducer";
import Region from "./region/reducer";
import Project from "./project/reducer";
import Sponsor from "./sponsor/reducer";
import Client from "./client/reducer";
import Person from "./person/reducer";
import Plan from "./plan/reducer";
import Insurance from "./insurance/reducer";
import Campaign from "./campaign/reducer";
import CampaignProject from "./campaignProject/reducer";
import Quote from "./generateQuote/reducer";
import Plans from "./plans/reducer";
import Insured from "./insured/reducer";
import Policy from "./policy/reducer";
import PlanBenefit from "./planBenefit/reducer";
import UpdateQuote from "./updateQuote/reducer";
import Catalog from "./catalog/reducer";
import Beneficiary from "./beneficiary/reducer";
import Insurances from "./insurances/reducer";
import PageActionFlags from "./pageActionFlags/reducer";
import DeleteEntity from "./delete/reducer";
import Login from "./auth/reducer";
import Avatar from "./avatar/reducer";
import Branches from "./branches/reducer";
import PaymentMode from "./paymentModes/reducer";
import AccountType from "./accountType/reducer";
import Catalogues from "./catalogue/reducer";
import ProjectType from "./projectType/reducer";
import CoverType from "./coverType/reducer";
import CoverTypePlan from "./coverTypePlan/reducer";
import PremiumEvalOption from "./premiumEvalOption/reducer";
import GeneralCatalog from "./generalCatalog/reducer";
import CancelQuote from "./cancelQuote/reducer";
import Currencies from "./currencies/reducer";
import Rol from "./rol/reducer";
import Collections from "./collections/reducer";
import Permission from "./permissions/reducer";

const rootReducer = combineReducers({
  InsuranceType,
  Insurance,
  IdentificationType,
  Countries,
  Region,
  Project,
  Sponsor,
  Client,
  Person,
  Plan,
  Campaign,
  CampaignProject,
  Quote,
  Plans,
  Insured,
  Policy,
  PlanBenefit,
  UpdateQuote,
  Catalog,
  Beneficiary,
  Insurances,
  PageActionFlags,
  DeleteEntity,
  Avatar,
  Login,
  Branches,
  PaymentMode,
  AccountType,
  Catalogues,
  ProjectType,
  CoverType,
  CoverTypePlan,
  PremiumEvalOption,
  GeneralCatalog,
  CancelQuote,
  Currencies,
  Rol,
  Collections,
  Permission,
});

export default rootReducer;