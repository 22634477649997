import React from "react";
import { useStyles } from "./style";
import {
  breadcrumb,
  BreadcrumbAFY,
  CardProps,
  InsuranceAFY,
  LoadingAFY,
  MenuItem,
  StepProgressAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { InsurancePageProps } from "./insuranceDto";
import { Title } from "../../components/layout/style";
import { themeCore } from "../../assets/themes/theme";
import "./style.css";

export const InsuranceView = (props: {
  insurance: InsurancePageProps;
  loading: boolean;
  breadCrumbs: breadcrumb[];
  searchFilter: (term: string) => void;
  clickView: (view: MenuItem) => void;
  clickCard: (card: CardProps) => void;
  clickChip: (chips: MenuItem[]) => void;
  insurancePage: number;
  insuranceLimit: number;
  countPagination: number;
  onChangePage: (e: number) => void;
  onChangeRowsPerPage: (e: number) => void;
  steps: any;
}) => {
  const classes = useStyles();
  return (
    <>
      <LoadingAFY
        loading={props.loading}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Cargando Productos"
        bgColor={themeCore.colors.backgroundLoader}
      />
      <BreadcrumbAFY options={props.breadCrumbs} />

      <Title>
        {props.insurance.titlePage}
        <article
          style={{
            position: "absolute",
            top: "-9px",
            right: "391px",
            zIndex: "9",
          }}
        >
          <StepProgressAFY
            onClickStepProgress={() => {}}
            stepProgress={props.steps}
            collapsed
            sticky
          />
        </article>
      </Title>

      <InsuranceAFY
        data={props.insurance.data}
        filter={{
          backgroundColor: props.insurance.filter.backgroundColor,
          bgChipInsurers: props.insurance.filter.bgChipInsurers,
          bgChipInsurersSelected: props.insurance.filter.bgChipInsurersSelected,
          bgColorButton: props.insurance.filter.bgColorButton,
          bgColorIconButton: props.insurance.filter.bgColorIconButton,
          bgColorIconButtonSelected:
            props.insurance.filter.bgColorIconButtonSelected,
          colorIconButton: props.insurance.filter.colorIconButton,
          colorIconButtonSelected:
            props.insurance.filter.colorIconButtonSelected,
          colorIconChip: props.insurance.filter.colorIconChip,
          colorText: props.insurance.filter.colorText,
          insurers: props.insurance.filter.insurers,
          title: props.insurance.filter.title,
          titleFirstChip: props.insurance.filter.titleFirstChip,
        }}
        onChangeFilterTerm={(term) => {
          props.searchFilter && props.searchFilter(term);
        }}
        onClick={(item) => props.clickCard && props.clickCard(item)}
        onClickButtonView={(option) => {
          props.clickView && props.clickView(option);
        }}
        onClickChip={(menu) => {
          props.clickChip && props.clickChip(menu);
        }}
        onCancelSearch={() => {
          props.searchFilter && props.searchFilter("");
        }}
        title="producto"
        titlePagination={"Registros por página"}
        countPagination={props.countPagination}
        rowsPerPage={props.insuranceLimit}
        onChangePage={props.onChangePage}
        rowsPerPageOption={[20, 25, 50, 100]}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        pagePagination={props.insurancePage}
      />
    </>
  );
};
