import { URLs } from "../url";
import { UpdateQuoteResponse } from "../../model/updateQuote";
import { getHeader } from "../headers";

const cancelQuoteAPI = async (id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.cancelQuote + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: {} }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as UpdateQuoteResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { cancelQuoteAPI };
