import { Navigate, Route, Routes } from "react-router-dom";
import Insurance from "../salesCore/insurance/insurance";
import Plan from "../salesCore/plan/plan";
import InsuranceType from "../salesCore/insuranceType/insuranceType";
import Dashboard from "../salesCore/dashboard/dashboard";
import CustomerForms from "../salesCore/customerForms/customerForms";
import ConfirmationForm from "../salesCore/confirmationForm/confirmationForm";
import PaymentSummary from "../salesCore/paymentSummary/paymentSummary";
import PaymentConfirmation from "../salesCore/paymentConfirmation/paymentConfirmation";
import InsurancePoliciesSummary from "../salesCore/insurancePoliciesSummary/insurancePoliciesSummary";
import { GetNavigationPages } from "../services/getNavigationPages";
import { SponsorAdmin } from "../adminCore/sponsor/sponsor";
import { BranchesAdmin } from "../adminCore/branches/branches";
import { AccountTypeAdmin } from "../adminCore/accountType/accountType";
import { InsurancesAdmin } from "../adminCore/insurances/insurances";
import { InsuranceTypeAdmin } from "../adminCore/insuranceType/insuranceType";
import { RegionAdmin } from "../adminCore/region/region";
import { BranchAdmin } from "../adminCore/branch/branch";
import { PlanAdmin } from "../adminCore/plan/plan";
import { UserAdmin } from "../adminCore/user/user";
import { IdentificationTypeAdmin } from "../adminCore/identificationType/identificationType";
import { PolicyReport } from "../salesCore/policyReport/policyReport";
import { GridPolicyReport } from "../salesCore/policyReport/resources/gridPolicyReport";
import { ReportPage } from "../salesCore/reportsPage/reportPage";
import { Completed } from "../salesCore/reportsPage/completedSales/completed";
import CataloguesAdmin from "../adminCore/catalogue/catalogue";
import ProjectTypesAdmin from "../adminCore/projectType/projectType";
import { ClientsPage } from "../salesCore/clients/clients";
import { ClientRecordPage } from "../salesCore/clientRecord/clientRecord";
import GeneralCatalogAdmin from "../adminCore/generalCatalog/generalCatalog";
import { ProductCreation } from "../adminCore/insurances/productCreation/productCreation";
import { PlanCreation } from "../adminCore/plan/planCreation/planCreation";
import { RolAdmin } from "../adminCore/rol/rol";
import Billing from "../adminCore/billing/billing";
export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Insurance />} />

      <Route path="/insurance-type" element={<InsuranceType />} />
      <Route path="/insurance" element={<Insurance />} />
      <Route path="/plan" element={<Plan />} />
      <Route path="/customerForm" element={<CustomerForms />} />
      <Route
        path="/confirmationForm/:id?/:isQuote?/:isPlan?"
        element={<ConfirmationForm />}
      />

      <Route path="/dashboard" element={<Dashboard />} />

      <Route path="/checkout/payment-method" element={<PaymentSummary />} />

      <Route
        path="/checkout/payment-confirmation"
        element={<PaymentConfirmation />}
      />

      <Route
        path="/insurance-policies-summary"
        element={<InsurancePoliciesSummary />}
      />

      <Route
        path="/insurance-policies-summary"
        element={<InsurancePoliciesSummary />}
      />

      {GetNavigationPages().permissionsConfig.find(
        (i) => i.name === "admin-product-view",
      ) && <Route path="/insurances-admin" element={<InsurancesAdmin />} />}
    

      <Route path="/sponsor-admin" element={<SponsorAdmin />} />
      <Route path="/branches-admin" element={<BranchesAdmin />} />

      <Route
        path="/identification-type-admin"
        element={<IdentificationTypeAdmin />}
      />

      <Route path="/account-type-admin" element={<AccountTypeAdmin />} />


      {GetNavigationPages().permissionsConfig.find(
        (i) => i.name === "admin-product-type-view",
      ) && <Route path="/insurance-type-admin" element={<InsuranceTypeAdmin />} />}



      <Route path="/cataloguesadmin" element={<CataloguesAdmin />} />
      <Route path="/project-types-admin" element={<ProjectTypesAdmin />} />
      
      {GetNavigationPages().permissionsConfig.find(
        (i) => i.name === "admin-catalog-view",
      ) && <Route path="/general-catalog-admin" element={<GeneralCatalogAdmin />} />}

    
      
      <Route path="/rol-admin" element={<RolAdmin />} />
      <Route
        path="/identificationtypeadmin"
        element={<IdentificationTypeAdmin />}
      />

      <Route path="/branch-admin" element={<BranchAdmin />} />
      <Route path="/region-admin" element={<RegionAdmin />} />
      <Route path="/planadmin" element={<PlanAdmin />} />

      {GetNavigationPages().permissionsConfig.find(
        (i) => i.name === "admin-users-view",
      ) && <Route path="/user-admin" element={<UserAdmin />} />}

      <Route path="/policy-report" element={<PolicyReport />} />
      <Route path="/report" element={<ReportPage />} />
      <Route path="/clients" element={<ClientsPage />} />

      <Route path="/client-record/:id?" element={<ClientRecordPage />} />
      <Route path="/report/sales/:id?" element={<Completed />} />
      <Route path="/grid-policy-report" element={<GridPolicyReport />} />

      {GetNavigationPages().permissionsConfig.find(
        (i) => i.name === "admin-product-plan-view",
      ) && <Route
          path="/product-creation/:idProduct?/:isEdit?/:plans?"
          element={<ProductCreation />}
        />}

    




      <Route
        path="/plan-creation/:idProduct?/:idPlan?/:tab?"
        element={<PlanCreation />}
      />
      <Route path="/billing" element={<Billing />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};