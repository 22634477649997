import { URLs } from "../url";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { QuoteOneResponse } from "../../model/quote";
import { getHeader, getHeader2 } from "../headers";

const postGenerateQuote = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.quote;

  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getOneGenerateQuote = async (id: string, fullBody: boolean) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.quote +
    `/${id}?fullBody=${fullBody ? fullBody : false}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as QuoteOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getAllGenerateQuote = async (
  clientId: string,
  statuses: string,
  creationDateStart: string,
  creationDateEnd: string,
  projectIds: string,
  branches: string,
  enable: boolean,
  page: number,
  limit: number,
  numberId: number,
  fields: string,
  search: string,
  catalogStatusQuote: string,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.quote +
    "/All" +
    `?clientId=${clientId}` +
    (statuses ? `&statuses=${statuses}` : "") +
    (creationDateStart ? `&creationDateStart=${creationDateStart}` : "") +
    (creationDateEnd ? `&creationDateEnd=${creationDateEnd}` : "") +
    (projectIds ? `&projectIds=${projectIds}` : "") +
    (branches ? `&branches=${branches}` : "") +
    (numberId ? `&numberId=${numberId}` : "") +
    (fields ? `&fields=${fields}` : "") +
    (search ? `&search=${search}` : "") +
    (catalogStatusQuote ? `&catalogStatusQuote=${catalogStatusQuote}` : "") +
    `&enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as QuoteOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const downloadGenerateQuoteAPI = async (
  clientId: string,
  enable: boolean,
  page: number,
  limit: number,
  catalogStatusQuote: string,
  creationDateStart: string,
  creationDateEnd: string,
  fields: string,
  labels: string,
  contentType: string,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.quote +
    "/All" +
    `?clientId=${clientId}` +
    `&enable=${enable}&page=${page}&limit=${limit}` +
    (catalogStatusQuote ? `&catalogStatusQuote=${catalogStatusQuote}` : "") +
    (creationDateStart ? `&creationDateStart=${creationDateStart}` : "") +
    (creationDateEnd ? `&creationDateEnd=${creationDateEnd}` : "") +
    (fields ? `&fields=${fields}` : "") +
    (labels ? `&labels=${labels}` : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader2(contentType),
  };

  try {
    const responseAPI = await fetch(newURL, requestOptions);
    if (!responseAPI.ok) {
      throw await responseAPI.blob();
    }
    return await responseAPI.blob();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  postGenerateQuote,
  getOneGenerateQuote,
  getAllGenerateQuote,
  downloadGenerateQuoteAPI,
};
