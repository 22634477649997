import {
  UPDATE_QUOTE,
  UPDATE_QUOTE_CLEAR_DATA,
  UPDATE_QUOTE_ERROR,
  UPDATE_QUOTE_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorUpdateQuote: null,
  responseUpdateQuote: null,
  loadingUpdateQuote: false,
};

const UpdateQuote = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_QUOTE:
      state = {
        ...state,
        loadingUpdateQuote: true,
        errorUpdateQuote: null,
        responseUpdateQuote: true,
      };
      break;
    case UPDATE_QUOTE_SUCCESS:
      state = {
        ...state,
        errorUpdateQuote: null,
        loadingUpdateQuote: false,
        responseUpdateQuote: action.payload,
      };
      break;
    case UPDATE_QUOTE_ERROR:
      state = {
        ...state,
        errorUpdateQuote: action.payload,
        loadingUpdateQuote: false,
        responseUpdateQuote: null,
      };
      break;
    case UPDATE_QUOTE_CLEAR_DATA:
      state = {
        ...state,
        errorUpdateQuote: null,
        loadingUpdateQuote: null,
        responseUpdateQuote: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UpdateQuote;
