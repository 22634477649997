import React from "react";
import {
  icons,
  InformationAFY,
  MenuItem,
  ReportsAFY,
  sizes,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {
  dataBarchart,
  dataBarchartInsurance,
  dataCard,
  dataCard2,
} from "./reportPageDTO";
import { Title } from "../../components/layout/style";
import { themeCore } from "../../assets/themes/theme";

interface ReportViewProps {
  openReport: (e: MenuItem) => void;
}

export const ReportView = (props: ReportViewProps) => {
  return (
    <React.Fragment>
      <Title>Reportes</Title>
      <InformationAFY
        backgroundColor="#F5F5F5"
        colorText="#666666"
        description="Seleccione el reporte a consultar"
        icon={icons.Help}
        iconColor="#3A56A1"
        iconSize={sizesIcon.standard}
      />
      <div style={{ marginTop: "25px" }}>
        <ReportsAFY
          buttonTitle="Buscar"
          canceledColor={themeCore.colors.secondaryText}
          cardTotalsHeight={104}
          cardTotalsWidth={270}
          dataBarchart={dataBarchart}
          dataBarchartInsurance={dataBarchartInsurance}
          dataCard={dataCard}
          dataCard2={dataCard2}
          insuranceSalesColor="#3A56A1"
          onClickCard={props.openReport}
          onClickSearch={function noRefCheck() {}}
          reportCardHeight={104}
          reportCardWidth={270}
          salesColor={themeCore.colors.primaryText}
          sizeButton={sizes.large}
          titleColor={themeCore.colors.primaryText}
          titleColorTotals={themeCore.colors.primaryText}
          titleDate1="Fecha Inicio"
          titleDate2="Fecha Fin"
          titleSizeTotals={35}
        />
      </div>
    </React.Fragment>
  );
};
