import { call, put, takeEvery } from "redux-saga/effects";
import { updateQuoteError, updateQuoteSuccess } from "./actions";
import { updateQuoteAPI } from "../../resources/helper/updateQuote";
import { UPDATE_QUOTE } from "./actionTypes";

function* updateQuote({ payload: { quote } }) {
  try {
    const response = yield call(updateQuoteAPI, quote.body, quote.id);
    yield put(updateQuoteSuccess(response));
  } catch (error) {
    yield put(updateQuoteError(error));
  }
}

function* UpdateQuoteSaga() {
  yield takeEvery(UPDATE_QUOTE, updateQuote);
}

export default UpdateQuoteSaga;
