import { URLs } from "../url";
import { CurrenciesResponse } from "../../model/currencies";
import { getHeader } from "../headers";

const getCurrenciesAPI = async (
  enable: boolean,
  orderBy: string,
  orderDir: string,
  page: number,
  limit: number,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.currencies +
    `?enable=${enable}&orderBy=${orderBy}&orderDir=${orderDir}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as CurrenciesResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getCurrenciesAPI };
