import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "./view";
import LocalStorageService from "../../services/localStorage";
import { deleteEntityClearData } from "../../store/delete/actions";

import {
  CardProps,
  MenuItem,
  position,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { useAccountTypeGetAll } from "../../fnx/accountType/accountTypeGetAll";
import { AccountType, AccountTypeRequest } from "../../model/accountType";
import { useAccountTypePost } from "../../fnx/accountType/accountTypePost";
import { showErrorToast } from "../../fnx/showError";
import { useAccountTypePatch } from "../../fnx/accountType/accountTypePatch";
import { useTranslation } from "react-i18next";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const AccountTypeAdmin = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("global");
  const [list, setList] = useState<CardProps[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [idAccountType, setIdAccountType] = useState("");
  const [bodyRequest, setBodyRequest] = useState<AccountTypeRequest>({
    data: { clientId: "" },
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );

  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [dataForm, setDataForm] = useState<any>({
    Id: "",
    Name: "",
  });

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseAccountType, errorAccountType, loadingAccountType } =
    useAccountTypeGetAll(trigger, clientId);

  const {
    responseAccountTypePost,
    errorAccountTypePost,
    loadingAccountTypePost,
  } = useAccountTypePost(triggerPost, bodyRequest);

  const {
    responseAccountTypePatch,
    errorAccountTypePatch,
    loadingAccountTypePatch,
  } = useAccountTypePatch(triggerPatch, bodyRequest, idAccountType);

  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const callAccountTypes = () => {
    setTrigger(!trigger);
  };

  useEffect(() => {
    callAccountTypes();
  }, []);

  const callAccountTypePost = () => {
    setTriggerPost(!triggerPost);
  };

  const callAccountTypePatch = () => {
    setTriggerPatch(!triggerPatch);
  };

  useEffect(() => {
    if (responseAccountType && responseAccountType?.length > 0) {
      const listAccountType: CardProps[] = responseAccountType.map(
        (item: AccountType) => ({
          id: item.id,
          title: item.name,
          clientId: item.clientId,
        }),
      );
      setList(listAccountType);
    }
  }, [responseAccountType]);

  /*Valida respuesta cuando ya se inserto el registro*/
  useEffect(() => {
    setTriggerPost(false);
    if (responseAccountTypePost) {
      setSeeToast({
        show: true,
        message: "Creado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenModal(false);
    }
    callAccountTypes();
  }, [responseAccountTypePost]);

  /*Muestra si tubo errores el GET ALL de Tipo de Proyectos*/
  useEffect(() => {
    if (errorAccountType) {
      showErrorToast(errorAccountType, setSeeToast, t);
    }
  }, [errorAccountType]);

  /*Muestra si tubo errores el POST de Tipo de Proyectos*/
  useEffect(() => {
    if (errorAccountTypePost) {
      showErrorToast(errorAccountTypePost, setSeeToast, t);
    }
  }, [errorAccountTypePost]);

  /*Muestra si tubo errores el Patch de Tipo de Proyectos*/
  useEffect(() => {
    if (errorAccountTypePatch) {
      showErrorToast(errorAccountTypePatch, setSeeToast, t);
    }
  }, [errorAccountTypePatch]);

  useEffect(() => {
    if (responseDeleteEntity) {
      setSeeToast({
        show: true,
        message: "Eliminado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenModal(false);
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      setSeeToast({
        show: true,
        message: errorDeleteEntity?.message.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  //// pregunta amilcar 1, en el lado del projectType solo tiene clientId: clientId y no tiene el .row
  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      Id: row.id,
      Name: row.title,
      clientId: clientId,
    });
  };

  /*Submit para actualizar o agregar un registro*/
  const handleSubmitAccountType = (dataProject: any) => {
    if (isUpdate) {
      /*Se llena la informacion al momento de acutalizar*/
      /*Se crea objeto en base al body del api*/
      setBodyRequest({
        data: {
          clientId: clientId,
          description: dataProject.Description,
        },
      });
      // Se asigna el id del ProjectType para a actualizar
      setIdAccountType(dataForm.Id);

      /*Llamda al FNX para actualizar PATCH*/
      callAccountTypePatch();
      // setAvatarFile(file);
    } else {
      /*Se procede a crear un nuevo Registro*/
      /*Se crea objeto en base al body del api*/
      setBodyRequest({
        data: {
          clientId: clientId,
          description: dataProject.Description,
        },
      });
      // setAvatarFile(file);

      /*Llamada al FNX para crear Registro*/
      callAccountTypePost();
    }
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  //pregunta amilcar 2 aqui tengo name y del otro lado no
  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenModal(true);
    setDataForm({
      ...dataForm,
      Id: "",
      Name: "",
      clientId: "",
      Image: "",
      Description: "",
    });
  };
  // anterior handleOnclickButto
  /*
      const handleOnClickButtonOptionsDropdown = (opt?: any, item?: any) => {
        if (opt.id === "1") {
          setIsUpdate(true);
          setOpenModal(true);
          showEdit(item);
        } else if (opt.id === "2") {
          setitemModal(item);
          setOpenModalConfirm(true);
        } else if (opt.id === "3") {
          setitemModal(item);
          setOpenModalCloneConfirm(true);
        }
      };
    */

  /// nuevo handleOnclickButto
  const handleOnClickButtonOptionsDropdown = (opt?: any, item?: any) => {
    if (opt.id === "1") {
      // Opcion Editar
      setIsUpdate(true);
      setOpenModal(true);
      showEdit(item);
    } else if (opt.id === "2") {
      // Opcion eliminar
      // setitemModal(item);
      // setOpenModalConfirm(true);
      setSeeToast({
        show: true,
        message: "Operación no permitida!",
        type: typeAlert.warning,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.warning,
        });
      }, 3000);
      setOpenModal(false);
    } else if (opt.id === "3") {
      setitemModal(item);
      setOpenModalCloneConfirm(true);
    }
  };

  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleCloseModalConfirm = () => {
    setOpenModal(false);
    setOpenModalConfirm(false);
  };

  const handleCloseCloneModal = () => {
    setOpenModal(false);
    setOpenModalCloneConfirm(false);
  };
  /*Api que se ejecuta cuando el catalogo tiene la opcion
   * para eliminar registros*/
  const handleDelete = () => {
    setSeeToast({
      show: true,
      message: "Operación no permitida!",
      type: typeAlert.warning,
    });
    setTimeout(() => {
      setSeeToast({
        show: false,
        message: "",
        type: typeAlert.warning,
      });
    }, 3000);
    setOpenModal(false);
  };
  const handleClone = () => {};

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}
      <View
        title="Tipo de cuenta"
        accountTypeTitle={
          isUpdate ? "Modificar Tipo Proyecto" : "Agregar Tipo Proyecto"
        }
        list={list}
        openModal={openModal}
        openModalConfirm={openModalConfirm}
        openModalCloneConfirm={openModalCloneConfirm}
        loadingPost={null}
        dataForm={dataForm}
        modalTitle={itemModal?.title}
        clickView={clickView}
        onSubmit={handleDelete}
        onSubmitClone={handleClone}
        onClose={handleCloseModal}
        onCloseConfirm={handleCloseModalConfirm}
        onCloseCloneModal={handleCloseCloneModal}
        handleOpenModal={handleOpenModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}
        onSaveData={handleSubmitAccountType}
        onClickAdd={handleOnClickAdd}
        onClickButtonOptionsDropdown={(opt?: any, item?: any): void => {
          handleOnClickButtonOptionsDropdown(opt, item);
        }}
      />
    </React.Fragment>
  );
};

export default AccountTypeAdmin;
