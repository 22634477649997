import React, { ChangeEvent, useEffect, useState } from "react";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PolicyRequest } from "../../model/policy";
import {
  Breadcrum,
  dataFilter,
  paymentSummaryStorybook,
} from "./paymentSummaryDto";
import {
  policyCreate,
  policyCreateClearData,
} from "../../store/policy/actions";
import {
  BreadcrumbAFY,
  DataFormClient,
  DataFormPayment,
  LoadingAFY,
  MenuItem,
  position,
  select,
  StepProgressAFY,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { PlanModel } from "../../model/plan";
import { PaymentSummaryView } from "./view";
import { stepProgressBanesco5 } from "../../dataBase/stepProgress";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import { themeCore } from "../../assets/themes/theme";

import { useGetCatalogAccountType } from "../../fnx/getCatalogAccountType";
import { quoteOne } from "../../store/generateQuote/actions";
import { showErrorToast } from "../../fnx/showError";
import showToast from "../../services/toast";
import { useTranslation } from "react-i18next";
import { catalogAccountTypeClearData } from "../../store/plan/actions";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

const PaymentSummary = () => {
  const { t } = useTranslation("global");
  const [trigger, setTrigger] = useState(false);
  const [listAccountTypes, setListAccountTypes] = useState<select[]>([]);
  const [dataFormPayment, setDataFormPayment] = useState<DataFormPayment>({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const [quote] = React.useState<string | undefined>(
    localStorageService.getStepStored().steps[Steps.customer].quoteId,
  );
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [projectId] = React.useState<string | undefined>(
    localStorageService.getStepStored().steps[Steps.insurance].id,
  );

  const [dataForm, setDataForm] = useState<
    DataFormClient & { Insurance?: string } & { CoverType?: string } & {
      PaymentMode?: string;
    } & { Currency?: string } & { Prima?: string } & { QuoteId?: string }
  >({
    Name: "",
    LastNames: "",
    DocumentNumber: "",
    Email: "",
    Insurance: "",
    PlanTypeName: "",
    CampaignName: "",
    CoverType: "",
    PaymentMode: "",
    Currency: "",
    Prima: "",
    QuoteId: "",
  });

  const { responseCreatePolicy, errorCreatePolicy, loadingCreatePolicy } =
    useSelector((state: any) => ({
      responseCreatePolicy: state.Policy.responseCreatePolicy,
      errorCreatePolicy: state.Policy.errorCreatePolicy,
      loadingCreatePolicy: state.Policy.loadingCreatePolicy,
    }));

  const { responseCatalogAccountType, errorCatalogAccountType } =
    useGetCatalogAccountType(trigger, projectId);

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
    (state: any) => ({
      responseOneQuote: state.Quote.responseOneQuote?.data,
      errorOneQuote: state.Quote.errorOneQuote,
      loadingOneQuote: state.Quote.loadingOneQuote,
    }),
  );

  useEffect(() => {
    dispatch(policyCreateClearData());
    setTrigger(true);
    setSteps(stepProgressBanesco5());
    dispatch(
      quoteOne({
        id: quote,
        fullBody: true,
      }),
    );
  }, []);

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (errorCreatePolicy) {
      showErrorToast(errorCreatePolicy, setSeeToast, t);
    }
  }, [errorCreatePolicy]);

  useEffect(() => {
    if (errorCatalogAccountType) {
      showErrorToast(errorCatalogAccountType, setSeeToast, t);
    }
  }, [errorCatalogAccountType]);

  useEffect(() => {
    if (
      responseCatalogAccountType &&
      responseCatalogAccountType.data?.length > 0
    ) {
      const data: select[] = responseCatalogAccountType.data?.map(
        (account: PlanModel) => {
          return {
            id: account.catalogAccountTypeId,
            value: account.catalogAccountTypeId,
            name: account.catalogAccountType?.description,
          };
        },
      );
      setListAccountTypes(data);
      dispatch(catalogAccountTypeClearData());
    }
  }, [responseCatalogAccountType]);

  useEffect(() => {
    if (responseOneQuote) {
      setDataForm({
        Name: `${responseOneQuote?.firstNameA} ${responseOneQuote?.firstNameB}`,
        LastNames: `${responseOneQuote?.lastNameA} ${responseOneQuote?.lastNameB}`,
        DocumentNumber: responseOneQuote?.numberId,
        Email: responseOneQuote?.email,
        Insurance: responseOneQuote?.productId?.description,
        PlanTypeName: responseOneQuote?.planId?.name,
        CampaignName: responseOneQuote.campaignId?.name,
        CoverType: responseOneQuote?.coverType?.description,
        PaymentMode: responseOneQuote?.catalogPaymentMode?.description,
        Currency: responseOneQuote?.planId.price.currencySymbol,
        Prima: responseOneQuote?.planId.price.amount,
        QuoteId: responseOneQuote.id,
      });
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (responseCreatePolicy && responseCreatePolicy.data) {
      localStorageService.setStepStored({
        id: responseCreatePolicy.data.id,
        name: "Pay form",
        step: Steps.pay,
        completed: true,
        quoteId: "",
        campaign: "",
        policyId: responseCreatePolicy.data.id,
        email: dataForm.Email,
      });
      dispatch(policyCreateClearData());
      navigate("/checkout/payment-confirmation/");
    }
  }, [responseCreatePolicy]);

  const createPolicy = (data: any) => {
    console.log(data);
    const dataSubmit: PolicyRequest = {
      data: {
        effectiveFrom: new Date().toISOString(),
        creationDate: new Date().toISOString(),
        userId: lsLogin.getUserLoginStored().userId,
        quoteId: dataForm.QuoteId,
        catalogStatus: "60c01b66-f07a-42f3-9ce4-bd973f515fed",
        paymentCurrency: "4442d70e-4013-4db2-b7e4-8d2265b219f0",
        accountHolderType: "e9b361e2-def7-4789-90b2-b2168c696b92",
        accountType: data.type,
        accountTypeDescription: data.paymentName,
        accountName: data.AccountName.replace(/\s+/g, "")
          ? data.AccountName
          : `${dataForm.Name} ${dataForm.LastNames}`,
        accountMonth: 12,
        accountYear: 2022,
        accountNumber: data.AccountNumber,
      },
    };

    dispatch(policyCreate({ ...dataSubmit }));
    setSteps(stepProgressBanesco5());
  };

  const handlePrevious = () => {
    navigate("/confirmationForm");
  };

  const handleChangePayment = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "1") {
      setDataFormPayment({
        AccountName: `${dataForm.Name} ${dataForm.LastNames}`,
      });
    }
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}
      <BreadcrumbAFY options={Breadcrum} />
      <LoadingAFY
        loading={loadingCreatePolicy || loadingOneQuote}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        bgColor={themeCore.colors.backgroundLoader}
        text={loadingCreatePolicy ? "Generando pago" : "Cargando datos"}
      />
      <article
        style={{
          position: "absolute",
          top: "42px",
          right: "427px",
          zIndex: "9",
        }}
      >
        <StepProgressAFY
          onClickStepProgress={() => {}}
          stepProgress={steps}
          collapsed
          sticky
        />
      </article>
      <div style={{ marginTop: "15px" }}>
        <PaymentSummaryView
          accountName={`${dataForm.Name} ${dataForm.LastNames}`}
          nextLabel={paymentSummaryStorybook.nextLabel}
          title={paymentSummaryStorybook.title}
          options={listAccountTypes}
          paymentOption={paymentSummaryStorybook.paymentOption}
          paymentOptions={paymentSummaryStorybook.paymentOptions}
          previousLabel={paymentSummaryStorybook.previousLabel}
          stepProgress={paymentSummaryStorybook.stepProgress}
          onBack={handlePrevious}
          filters={dataFilter(dataForm)}
          dataForm={dataForm}
          onNext={createPolicy}
          onChangePayment={handleChangePayment}
        />
      </div>
    </React.Fragment>
  );
};

export default PaymentSummary;
