import React, { Suspense, useEffect, useState } from "react";
import { useStyles } from "./style";
import "./style.css";
// @ts-ignore
import {
  BreadcrumbAFY,
  ButtonAFY,
  DataFormClient,
  FormBeneficiaryAFY,
  FormConfirmationAFY,
  LoadingAFY,
  MenuItem,
  ModalComponentAFY,
  position,
  select,
  sizes,
  StepProgressAFY,
  typeAlert,
  typeLoaders,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { formatDocument } from "../../services/functions";
import { useDispatch, useSelector } from "react-redux";
import { themeCore } from "../../assets/themes/theme";
import {
  insured,
  patchInsured,
  patchInsuredClearData,
  postInsured,
  postInsuredClearData,
} from "../../store/insured/actions";
import {
  personByDocumentClearData,
  updatePerson,
} from "../../store/person/actions";
import { useNavigate, useParams } from "react-router-dom";
import { catalogGender, plan } from "../../store/plan/actions";
import * as catalogConst from "../../const/catalog";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import { identificationType } from "../../store/identificationType/actions";
import { IdentificationType } from "../../model/identificationType";
import { PlanModel } from "../../model/plan";
import { Countries } from "../../model/countries";
import { Region } from "../../model/region";
import { Level } from "../../enum/region";
import {
  formattedDate,
  formattedDateWithTime,
  newFormattedDate,
  newFormattedDateYYMMDD,
} from "../../utils/date";
import showToast from "../../services/toast";
import {
  pageActionFlagsApi,
  pageActionFlagsClearData,
} from "../../store/pageActionFlags/actions";
import {
  stepProgressBanesco4,
  stepProgressFinished,
} from "../../dataBase/stepProgress";
import {
  benefit,
  benefitClearData,
  getBeneficiary,
  getBeneficiryClearData,
  patchBeneficiary,
  patchBeneficiaryClearData,
} from "../../store/beneficiary/actions";
import {
  TableAdditionalPaymentInfo,
  TableBeneInsured,
} from "../../model/beneficiary";
import { region } from "../../store/region/actions";
import { countries } from "../../store/countries/actions";
import { quoteClearData, quoteOne } from "../../store/generateQuote/actions";
import {
  Breadcrum,
  Tab1Columns,
  Tab2Columns,
  Tab3Columns,
} from "./confirmationFormDto";
import { showErrorToast } from "../../fnx/showError";
import { ReadFlags } from "../../fnx/readFlags";
import { ActionFlagsModel } from "../../model/actionFlags";
import { useTranslation } from "react-i18next";
import { GetPersonByDocument } from "../../fnx/person/getPersonByDocument";
import { Person } from "../../model/person";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { useGetPermissionList } from "../../fnx/getPermissions";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const storageKey3 = "afy-action-flags";
const lsPageActionFlags = new LocalStorageService(storageKey3);

const ConfirmationForm = () => {
  const { t } = useTranslation("global");
  const classes = useStyles();
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const [triggerPermissionList, setTriggerPermissionList] =
    React.useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [actionFlags, setActionFlags] = React.useState<ActionFlagsModel[]>(
    lsPageActionFlags.getPageActionFlagsStored(),
  );
  const [showInsured, setShowInsured] = React.useState<boolean>(true);
  const [showBeneficiary, setShowBeneficiary] = React.useState<boolean>(true);
  const [addInsured, setAddInsured] = React.useState<boolean>(true);
  const [addBeneficiary, setAddBeneficiary] = React.useState<boolean>(false);
  const [showComponent, setShowComponent] = React.useState<boolean>(false);
  const [openModalBackConfirm, setOpenModalBackConfirm] =
    useState<boolean>(false);
  const [canEdit, setCanEdit] = React.useState<boolean>(true);
  const [certificates, setCertificates] = React.useState<any[]>([]);
  const [rows, setRows] = useState<TableBeneInsured[]>([]);
  const [rows2, setRows2] = useState<TableAdditionalPaymentInfo[]>([]);
  const [rowsBeneficiary, setRowsBeneficiary] = useState<TableBeneInsured[]>(
    [],
  );
  const [isMaskedDocument, setIsMaskedDocument] = useState<boolean>(false);
  const [personQuoteId, setPersonQuoteId] = useState<string>("");
  const [prime, setPrime] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [insuranceType] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insuranceType].name
      ? localStorageService
          .getStepStored()
          .steps[Steps.insuranceType].name.toLowerCase()
      : "",
  );
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [listIdentificationType, setListIdentificationType] = React.useState<
    select[]
  >([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const { id } = useParams();
  const { isQuote } = useParams();
  const { isPlan } = useParams();
  const [listGenders, setListGenders] = useState<select[]>([]);
  const [isQuoteType, setIsQuoteType] = useState<boolean>(isQuote === "true");
  const [canEditBeneficiary, setCanEditBeneficiary] = useState<boolean>(false);
  const [canEditInsured, setCanEditInsured] = useState<boolean>(false);
  const [listCountries, setListCountries] = useState<select[]>([]);
  const [listRegionLevel1, setListRegionLevel1] = useState<select[]>([]);
  const [listRegionLevel2, setListRegionLevel2] = useState<select[]>([]);
  const [listRegionLevel3, setListRegionLevel3] = useState<select[]>([]);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(10000);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [personId, setPersonId] = React.useState<string>("");
  const [openModalBeneficiary, setOpenModalBeneficiary] =
    React.useState<boolean>(false);
  const [paymentMode, setPaymentMode] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [coverageType, setCoverageType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [insuredType, setInsuredType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [beneficiaryType, setBeneficiaryType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);

  const [campaignInfo, setCampaignInfo] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign,
  );
  const level1: Level = "REGION_LEVEL1";
  const level2: Level = "REGION_LEVEL2";
  const level3: Level = "REGION_LEVEL3";
  const level4: Level = "REGION_LEVEL4";
  const [titleInsured, setTitleInsured] =
    useState<string>("Ingresar asegurado");
  const [titleBeneficiary, setTitleBeneficiary] = useState<string>(
    "Ingresar beneficiario",
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [dataForm, setDataForm] = useState<DataFormClient>({
    DocumentNumber: "",
    QuoteNumber: "",
    QuoteStatusName: "",
    QuoteDate: "",
    Type: "",
    PlanTypeName: "",
    CampaignName: "-",
    IdTypeName: "",
    Name: "",
    SecondName: "",
    FirstLastName: "",
    SecondLastName: "",
    MarriedSurName: "-",
    Gender: "",
    Birthday: "",
    Address1: "-",
    Address2: "-",
    CountryName: "",
    ProvinceName: "",
    TownName: "",
    SectorName: "",
    PostalCode: "",
    PhoneOffice: "",
    PhoneHome: "-",
    PhoneNumber: "-",
    Email: "",
  });
  const [dataFormBeneficiary, setDataFormBeneficiary] = useState<any>({
    Id: "",
    IdType: "",
    idTypeInsured: "",
    Percentage: "",
    DocumentNumber: "",
    Name: "",
    LastNameA: "",
    LastNameB: "",
    marriedName: "",
    Birthday: "",
    PhoneNumber: "",
    IdTypeName: "",
  });
  const [dataFormInsurance, setDataFormInsurance] = useState<any>({
    Id: "",
    IdType: "",
    idTypeInsured: "",
    DocumentNumber: "",
    Name: "",
    LastNameA: "",
    LastNameB: "",
    marriedName: "",
    Birthday: "",
    PhoneNumber: "",
    IdTypeName: "",
  });
  const [initialPercentage, setInitialPercentage] = useState(45);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [document, setDocument] = useState<number>();
  const [isInsurance, setIsInsurance] = useState<boolean>(false);

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
    (state: any) => ({
      responseOneQuote: state.Quote.responseOneQuote,
      errorOneQuote: state.Quote.errorOneQuote,
      loadingOneQuote: state.Quote.loadingOneQuote,
    }),
  );

  const { responseIdentificationType, errorIdentificationType } = useSelector(
    (state: any) => ({
      responseIdentificationType:
        state.IdentificationType.responseIdentificationType,
      errorIdentificationType: state.IdentificationType.errorIdentificationType,
    }),
  );

  const { responseCatalogGender, errorCatalogGender } = useSelector(
    (state: any) => ({
      responseCatalogGender: state.Plan.responseCatalogGender,
      errorCatalogGender: state.Plan.errorCatalogGender,
    }),
  );

  const { responseCountries, errorCountries } = useSelector((state: any) => ({
    responseCountries: state.Countries.responseCountries,
    errorCountries: state.Countries.errorCountries,
  }));

  const { responseRegion, errorRegion } = useSelector((state: any) => ({
    responseRegion: state.Region.responseRegion,
    errorRegion: state.Region.errorRegion,
  }));

  const { responseInsured, errorInsured } = useSelector((state: any) => ({
    responseInsured: state.Insured.responseInsured,
    errorInsured: state.Insured.errorInsured,
  }));

  const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
    responsePlan: state.Plan.responsePlan,
    errorPlan: state.Plan.errorPlan,
    loading: state.Plan.loadingPlan,
  }));

  const { responseUpdatePerson, errorUpdatePerson, loadingUpdatePerson } =
    useSelector((state: any) => ({
      responseUpdatePerson: state.Person.responseUpdatePerson,
      errorUpdatePerson: state.Person.errorUpdatePerson,
      loadingUpdatePerson: state.Person.loadingUpdatePerson,
    }));

  const { responseBenefit, errorBenefit, loadingBenefit } = useSelector(
    (state: any) => ({
      responseBenefit: state.Beneficiary.responseBenefit,
      errorBenefit: state.Beneficiary.errorBenefit,
      loadingBenefit: state.Beneficiary.loadingBenefit,
    }),
  );

  const { responseBeneficiary, errorBeneficiary, loadingBeneficiary } =
    useSelector((state: any) => ({
      responseBeneficiary: state.Beneficiary.responseBeneficiary,
      errorBeneficiary: state.Beneficiary.errorBeneficiary,
      loadingBeneficiary: state.Beneficiary.loadingBeneficiary,
    }));

  const {
    responsePatchBeneficiary,
    errorPatchBeneficiary,
    loadingPatchBeneficiary,
  } = useSelector((state: any) => ({
    responsePatchBeneficiary: state.Beneficiary.responsePatchBeneficiary,
    errorPatchBeneficiary: state.Beneficiary.errorPatchBeneficiary,
    loadingPatchBeneficiary: state.Beneficiary.loadingPatchBeneficiary,
  }));

  const { responsePostInsured, errorPostInsured, loadingPostInsured } =
    useSelector((state: any) => ({
      responsePostInsured: state.Insured.responsePostInsured,
      errorPostInsured: state.Insured.errorPostInsured,
      loadingPostInsured: state.Insured.loadingPostInsured,
    }));

  const { responsePatchInsured, errorPatchInsured, loadingPatchInsured } =
    useSelector((state: any) => ({
      responsePatchInsured: state.Insured.responsePatchInsured,
      errorPatchInsured: state.Insured.errorPatchInsured,
      loadingPatchInsured: state.Insured.loadingPatchInsured,
    }));

  const {
    responsePageActionFlags,
    errorPageActionFlags,
    loadingPageActionFlags,
  } = useSelector((state: any) => ({
    responsePageActionFlags:
      state.PageActionFlags.responsePageActionFlags?.data,
    errorPageActionFlags: state.PageActionFlags.errorPageActionFlags,
    loadingPageActionFlags: state.PageActionFlags.loadingPageActionFlags,
  }));

  const {
    responsePersonByDocument,
    errorPersonByDocument,
    loadingPersonByDocument,
  } = GetPersonByDocument(trigger, document);

  const { permissionList } = useGetPermissionList(triggerPermissionList);

  useEffect(() => {
    if (permissionList?.length > 0) {
      setTriggerPermissionList(false);
    }
  }, [permissionList]);

  useEffect(() => {
    if (
      listIdentificationType.length > 0 &&
      listGenders.length > 0 &&
      listCountries.length > 0 &&
      listRegionLevel1.length > 0 &&
      listRegionLevel2.length > 0 &&
      listRegionLevel3.length > 0 &&
      !showComponent
    ) {
      setShowComponent(true);
    }
  }, [
    listIdentificationType,
    listGenders,
    listCountries,
    listRegionLevel1,
    listRegionLevel2,
    listRegionLevel3,
  ]);

  useEffect(() => {
    if (errorPersonByDocument) {
      showErrorToast(errorPersonByDocument, setSeeToast, t);
    }
  }, [errorPersonByDocument]);

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (errorInsured) {
      showErrorToast(errorInsured, setSeeToast, t);
    }
  }, [errorInsured]);

  useEffect(() => {
    if (errorCatalogGender) {
      showErrorToast(errorCatalogGender, setSeeToast, t);
    }
  }, [errorCatalogGender]);

  useEffect(() => {
    if (errorCountries) {
      showErrorToast(errorCountries, setSeeToast, t);
    }
  }, [errorCountries]);

  useEffect(() => {
    if (errorRegion) {
      showErrorToast(errorRegion, setSeeToast, t);
    }
  }, [errorRegion]);

  useEffect(() => {
    if (errorPlan) {
      showErrorToast(errorPlan, setSeeToast, t);
    }
  }, [errorPlan]);

  useEffect(() => {
    if (errorBeneficiary) {
      showErrorToast(errorBeneficiary, setSeeToast, t);
    }
  }, [errorBeneficiary]);

  useEffect(() => {
    if (errorIdentificationType) {
      showErrorToast(errorIdentificationType, setSeeToast, t);
    }
  }, [errorIdentificationType]);

  useEffect(() => {
    if (errorUpdatePerson) {
      showErrorToast(errorUpdatePerson, setSeeToast, t);
    }
  }, [errorUpdatePerson]);

  useEffect(() => {
    if (errorPatchBeneficiary) {
      showErrorToast(errorPatchBeneficiary, setSeeToast, t);
    }
  }, [errorPatchBeneficiary]);

  useEffect(() => {
    if (errorPatchInsured) {
      showErrorToast(errorPatchInsured, setSeeToast, t);
    }
  }, [errorPatchInsured]);

  useEffect(() => {
    if (errorBenefit) {
      showErrorToast(errorBenefit, setSeeToast, t);
    }
  }, [errorBenefit]);

  useEffect(() => {
    if (responsePageActionFlags) {
      lsPageActionFlags.setPageActionFlagsStored(responsePageActionFlags);
      setActionFlags(responsePageActionFlags);
      getActionFlags();
      dispatch(pageActionFlagsClearData());
    } else {
    }
  }, [responsePageActionFlags]);

  useEffect(() => {
    if (responsePatchBeneficiary && responsePatchBeneficiary.data) {
      showToastSuccessMessage(
        "Beneficiario actualizado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(patchBeneficiaryClearData());
      getInsuredBeneficiary();
    }
  }, [responsePatchBeneficiary]);

  useEffect(() => {
    if (responsePatchInsured && responsePatchInsured.data) {
      showToastSuccessMessage(
        "Asegurado actualizado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(patchInsuredClearData());
      getInsuredBeneficiary();
    }
  }, [responsePatchInsured]);

  useEffect(() => {
    if (responsePostInsured) {
      showToastSuccessMessage(
        "Asegurado ingresado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(postInsuredClearData());
      getInsuredBeneficiary();
      //postInsuredClearData();
    }
  }, [responsePostInsured]);
  useEffect(() => {
    if (errorPostInsured) {
      showErrorToast(errorPostInsured, setSeeToast, t);
      //setOpenModal(false);
    }
  }, [errorPostInsured]);

  useEffect(() => {
    //Obtener los action flags
    getActionFlags();

    if (!responseIdentificationType) {
      dispatch(
        identificationType({ enable: enable, page: page, limit: limit }),
      );
    }

    if (isQuote === "false") {
      setSteps(stepProgressFinished());
    } else {
      setSteps(stepProgressBanesco4());
    }
    if (!responseCountries) {
      dispatch(countries({ enable: enable, page: page, limit: limit }));
    }
    if (!responseCatalogGender?.data) {
      dispatch(catalogGender({ enable: enable, page: page, limit: limit }));
    }
    //Get Quote //
    if (id !== undefined) {
      dispatch(quoteClearData());
      setCanEdit(isQuoteType);
      //Get the quote by id
      dispatch(quoteOne({ id: id, fullBody: true }));
    } else {
      dispatch(
        quoteOne({
          id: localStorageService.getStepStored().steps[Steps.customer].quoteId,
          fullBody: true,
        }),
      );
    }

    // dispatch(person({ enable: true, page: 1, limit: 1000 }));
    if (!responsePlan) {
      dispatch(plan({ enable: enable, page: page, limit: limit }));
    }

    if (permissionList?.length === 0) {
      setTriggerPermissionList(true);
    }
  }, []);

  useEffect(() => {
    if (responseUpdatePerson?.data) {
      localStorageService.setStepStored({
        id: "",
        name: "Confirmation Form",
        step: Steps.confirmData,
        completed: true,
        quoteId: "",
        campaign: "",
      });
      showToastSuccessMessage(
        "Actualización realizada con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(quoteOne({ id: id ? id : "", fullBody: true }));
    }
  }, [responseUpdatePerson]);

  useEffect(() => {
    if (responseBenefit && responseBenefit?.data) {
      showToastSuccessMessage(
        "Beneficiario agregado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setOpenModalBeneficiary(false);
      // dispatch(person({ enable: true, page: 1, limit: 10000 }));
      getInsuredBeneficiary();
      dispatch(benefitClearData());
    }
  }, [responseBenefit]);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      setPersonQuoteId(responseOneQuote?.data?.personId);

      /*date = formattedDate(date);*/

      //Province
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level1,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );

      //Municipality
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level2,
          parentRegionId: responseOneQuote.data?.municipality.parentRegionId,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );
      //District
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level3,
          parentRegionId: responseOneQuote.data?.district.parentRegionId,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );

      let status = responseOneQuote.data?.catalogStatusQuote?.description;

      if (status == "Cancelada") {
        status = "Fecha de cancelación";
      } else if (status == "Emitida") {
        status = "Fecha de emisión";
      } else if (status == "Expirada") {
        status = "Fecha de expiración";
      } else {
        status = "Fecha de cotización";
      }
      let date =
        responseOneQuote?.data?.policyId?.effectiveFrom ||
        responseOneQuote?.data?.creationDate;
      let nextYearDate = undefined;
      let originalDate;
      if (date) {
        let originalDate = new Date(date);
        originalDate.setFullYear(originalDate.getFullYear() + 1);
        nextYearDate = formattedDate(originalDate.toISOString());
        nextYearDate = originalDate.toISOString();
      }
      setDataForm({
        DocumentNumber: formatDocument(
          responseOneQuote && responseOneQuote.data?.numberId,
        ),
        IdType: responseOneQuote.data?.identificationTypeId?.id
          ? responseOneQuote.data?.identificationTypeId?.id
          : "",
        Type:
          responseOneQuote &&
          responseOneQuote.data?.identificationTypeId?.description,
        QuoteNumber: responseOneQuote.data?.policyId
          ? responseOneQuote.data?.policyId.code
          : responseOneQuote && responseOneQuote.data?.code,
        Name: responseOneQuote && responseOneQuote.data?.firstNameA,
        SecondName: responseOneQuote && responseOneQuote.data?.firstNameB,
        FirstLastName: responseOneQuote && responseOneQuote.data?.lastNameA,
        SecondLastName: responseOneQuote && responseOneQuote.data?.lastNameB,
        MarriedSurName: responseOneQuote && responseOneQuote.data?.marriedName,
        Birthday: responseOneQuote && responseOneQuote.data?.dob,
        Address1: responseOneQuote && responseOneQuote.data?.address1,
        Address2: responseOneQuote && responseOneQuote.data?.address2,
        PhoneOffice: responseOneQuote && responseOneQuote.data?.phone2,
        PhoneHome: responseOneQuote && responseOneQuote.data?.phone1,
        PhoneNumber: responseOneQuote && responseOneQuote?.data?.phone3,
        Email: responseOneQuote && responseOneQuote.data?.email,
        QuoteStatusName:
          responseOneQuote &&
          responseOneQuote.data?.catalogStatusQuote?.description,
        QuoteDate: date,
        effectiveDate:
          responseOneQuote && responseOneQuote.data?.policyId
            ? nextYearDate
            : "",
        PlanTypeName: responseOneQuote && responseOneQuote.data?.planId?.name,
        CampaignName:
          responseOneQuote && responseOneQuote.data?.campaignId?.name,
        IdTypeName:
          responseOneQuote &&
          responseOneQuote.data?.identificationTypeId?.description,
        Gender: responseOneQuote && responseOneQuote.data?.catalogGender?.id,
        Country: responseOneQuote && responseOneQuote.data?.nationality?.id,
        CountryName:
          responseOneQuote && responseOneQuote.data?.nationality?.name,
        Province: responseOneQuote && responseOneQuote.data?.province?.id,
        ProvinceName: responseOneQuote && responseOneQuote.data?.province?.name,
        TownName: responseOneQuote && responseOneQuote.data?.municipality?.name,
        Town: responseOneQuote && responseOneQuote.data?.municipality?.id,
        Sector: responseOneQuote && responseOneQuote.data?.district?.id,
        SectorName: responseOneQuote && responseOneQuote.data?.district?.name,
        PostalCode: responseOneQuote && responseOneQuote.data?.zip,
      });

      // if (id) {
      setPrime(responseOneQuote?.data?.planId?.price.amount);
      setCurrency(responseOneQuote?.data?.planId?.price.currencySymbol);
      // }
      callDispatchPageActionFlags(responseOneQuote?.data?.planId?.projectId);
      if (id) {
        setLocalStorage(responseOneQuote.data);
        let certs = responseOneQuote?.data?.certificates;
        //Get the certificates
        if (certs.length > 0) {
          certs = certs.map((c: any, id: any) => {
            return {
              id: c.id,
              name: c.title,
              url: c.url,
            };
          });
        }
        setCertificates(certs);

        //Get the additional payment info
        if (responseOneQuote?.data && isQuote && isQuote === "false") {
          let additionalInfo: TableAdditionalPaymentInfo[] = [];
          additionalInfo.push({
            id: responseOneQuote?.data?.id!,
            accountName: responseOneQuote?.data?.customerAccount?.accountName,
            accountNumber:
              responseOneQuote?.data?.customerAccount?.accountNumber,
            accountType:
              responseOneQuote?.data?.customerAccount?.accountType?.description,
            paymentMode:
              responseOneQuote?.data?.catalogPaymentMode?.description,
            prime: `${responseOneQuote?.data?.productId?.fkCurrency?.symbol}  ${responseOneQuote?.data?.customerAccount?.amount}`,
            lastDigits: "N/A",
          });
          setRows2(additionalInfo);
        }
      } else {
        setRows2([
          {
            id: "1",
            accountName: "N/A",
            accountNumber: "N/A",
            accountType: "N/A",
            paymentMode: "N/A",
            prime: "N/A",
            lastDigits: "N/A",
          },
        ]);
      }
    }
    getInsuredBeneficiary();
  }, [responseOneQuote]);

  useEffect(() => {
    if (
      responseIdentificationType &&
      responseIdentificationType.data?.length > 0
    ) {
      let idf: select[] = [];
      responseIdentificationType.data?.map((item: IdentificationType) => {
        idf.push({
          id: item.id,
          name: item.description ? item.description : "",
          value: item.id,
        });
      });
      setListIdentificationType(idf);
      setDataFormBeneficiary({
        ...dataFormBeneficiary,
        IdType: idf[0].value ?? "",
      });
      setDataFormInsurance({
        ...dataFormInsurance,
        IdType: idf[0].value ?? "",
      });
    } else {
      //navigate("/customerForm");
    }
  }, [responseIdentificationType]);

  useEffect(() => {
    if (responseCatalogGender && responseCatalogGender.data?.length > 0) {
      const data: select[] = responseCatalogGender.data?.map(
        (gender: PlanModel) => {
          return {
            id: gender.id,
            value: gender.id,
            name: gender.description,
          };
        },
      );
      setListGenders(data);
    } else {
      //navigate("/customerForm");
    }
  }, [responseCatalogGender]);

  useEffect(() => {
    if (responseCountries && responseCountries.data?.length > 0) {
      const data: select[] = responseCountries.data?.map(
        (country: Countries) => {
          return {
            id: country.id,
            value: country.id,
            name: country.description,
          };
        },
      );
      setListCountries(data);
    } else {
      //navigate("/customerForm");
    }
  }, [responseCountries]);

  useEffect(() => {
    if (responseRegion && responseRegion?.data) {
      const data = responseRegion.data?.map((region: Region) => {
        return {
          id: region.id,
          value: region.id,
          name: region.name,
        };
      });
      data.unshift({ id: "-1", value: "-1", name: " " });
      if (responseRegion.level === level1) {
        setListRegionLevel1(data);
      } else if (responseRegion.level === level2) {
        if (data.length > 0) {
          setListRegionLevel2(data);
        } else {
          setListRegionLevel2([{ id: "-1", value: "-1", name: " " }]);
        }
      } else if (responseRegion.level === level3) {
        if (data.length > 0) {
          setListRegionLevel3(data);
        } else {
          setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
        }
      }
    }
  }, [responseRegion]);

  useEffect(() => {
    if (responseBeneficiary?.data) {
      setBeneficiaryStorybook(responseBeneficiary.data);
    }
  }, [responseBeneficiary?.data]);

  useEffect(() => {
    if (responseInsured?.data) {
      setInsuranceStorybook(responseInsured?.data);
    }
  }, [responseInsured]);

  useEffect(() => {
    if (responsePlan && responsePlan.data) {
      /* Filtrar por payment mode */
      let result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_PAYMENT_MODE,
      );
      if (result.length > 0) {
        setPaymentMode(result);
      }

      /* Filtrar por coverage type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_BENEFICIARY_TYPE,
      );
      if (result.length > 0) {
        setCoverageType(result);
      }
      /* Filtrar por insured type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_INSURED_TYPE,
      );
      if (result.length > 0) {
        setInsuredType(result);
        setDataFormInsurance({
          ...dataFormInsurance,
          idTypeInsured: result[0].value ?? "",
        });
      }
      /* Filtrar por beneficiary type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_BENEFICIARY_TYPE,
      );
      if (result.length > 0) {
        setBeneficiaryType(result);
        setDataFormBeneficiary({
          ...dataFormBeneficiary,
          idTypeInsured: result[0].value ?? "",
        });
      }
    }
  }, [responsePlan]);

  useEffect(() => {
    if (actionFlags.length > 0) {
      getActionFlags();
    }
  }, [actionFlags]);

  useEffect(() => {
    if (responsePersonByDocument && responsePersonByDocument.length > 0) {
      const dataPerson = responsePersonByDocument[0];
      const updatedDataFormPerson = updateDataFormPerson(
        dataPerson,
        isInsurance ? dataFormInsurance : dataFormBeneficiary,
      );
      if (isInsurance) {
        setDataFormInsurance(updatedDataFormPerson);
      } else {
        setDataFormBeneficiary(updatedDataFormPerson);
      }
      setTrigger(false);
      dispatch(personByDocumentClearData());
    }
    if (responsePersonByDocument?.length === 0) {
      if (isInsurance) {
        setDataFormInsurance({
          Name: "",
          SecondName: "",
          LastNameA: "",
          LastNameB: "",
          marriedName: "",
          Birthday: "",
          PhoneNumber: "",
        });
      } else {
        setDataFormBeneficiary({
          Name: "",
          SecondName: "",
          LastNameA: "",
          LastNameB: "",
          marriedName: "",
          Birthday: "",
          PhoneNumber: "",
        });
      }
      dispatch(personByDocumentClearData());
    }
  }, [responsePersonByDocument, isInsurance, trigger]);

  const updateDataFormPerson = (dataPerson: Person, currentDataForm: any) => ({
    ...currentDataForm,
    Id: dataPerson.id,
    IdType: dataPerson.identificationTypeId,
    DocumentNumber: dataPerson.numberId,
    Name: dataPerson.firstNameA,
    SecondName: dataPerson.firstNameB,
    LastNameA: dataPerson.lastNameA,
    LastNameB: dataPerson.lastNameB,
    marriedName: dataPerson.marriedName,
    Birthday: dataPerson.dob,
    PhoneNumber: dataPerson.phone1,
  });

  const handleAdd = () => {
    setTitleInsured("Ingresar asegurado");
    setIsUpdate(false);
    setOpenModal(true);
    setDataFormInsurance({
      ...dataFormInsurance,
      Id: "",
      DocumentNumber: "",
      Name: "",
      SecondName: "",
      LastNameA: "",
      LastNameB: "",
      marriedName: "",
      Birthday: "",
      PhoneNumber: "",
      IdTypeName: "",
    });
  };

  const setBeneficiaryStorybook = (responseBeneficiary: TableBeneInsured[]) => {
    const beneficiaryRows = responseBeneficiary?.map((b: TableBeneInsured) => ({
      id: b.id,
      type: b.catalogBeneficiaryTypeInfo?.description ?? "",
      percentage: `${b.percentage} %` ?? "",
      names: `${b.personInfo?.firstNameA} ${b.personInfo?.firstNameB}`,
      lastNames: `${b.personInfo?.lastNameA} ${b.personInfo?.lastNameB}`,
      marriedName: b.personInfo?.marriedName ?? "",
      phone1: b.personInfo?.phone1 ?? "",
      dob: newFormattedDate(b.personInfo?.dob) ?? "",
      documentNumber: b.personInfo?.numberId ?? "",
      idType: b.personInfo?.identificationTypeId ?? "",
      idTypeInsured: b.catalogBeneficiaryTypeInfo?.id ?? "",
    }));
    setRowsBeneficiary(beneficiaryRows);
    dispatch(getBeneficiryClearData());
  };




  const setInsuranceStorybook = (responseInsured: TableBeneInsured[]) => {
    const beneficiaryRows = responseInsured?.map((I: TableBeneInsured) => ({
      id: I.id,
      type: I.catalogInsuredTypeInfo?.description ?? "",
      names: `${I.personInfo?.firstNameA} ${I.personInfo?.firstNameB}`,
      lastNames: `${I.personInfo?.lastNameA} ${I.personInfo?.lastNameB}`,
      marriedName: I.personInfo?.marriedName ?? "",
      phone1: I.personInfo?.phone1 ?? "",
      dob: newFormattedDate(I.personInfo?.dob) ?? "",
      documentNumber: I.personInfo?.numberId ?? "",
      idType: I.personInfo?.identificationTypeId ?? "",
      idTypeInsured: I.catalogInsuredTypeInfo?.id ?? "",
    }));
    setRows(beneficiaryRows);
    dispatch(patchInsuredClearData());
  };

  const getActionFlags = () => {
    if (actionFlags) {
      const rfShowInsured = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-show-insured",
      );

      const rfAddInsurance = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-insured",
      );

      const rfShowBeneficiary = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-beneficiary",
      );

      const rfAddBeneficiary = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-beneficiary",
      );

      setShowInsured(rfShowInsured !== "true");

      setAddInsured(
        isQuote !== undefined ? isQuote !== "true" : rfAddInsurance !== "true",
      );

      setShowBeneficiary(rfShowBeneficiary !== "true");

      setAddBeneficiary(
        isQuote !== undefined
          ? isQuote !== "true"
          : rfAddBeneficiary !== "true",
      );

      setCanEditInsured(isQuote === undefined || isQuote === "true");

      setCanEditBeneficiary(isQuote === undefined || isQuote === "true");
    }
  };

  const handleAddBeneficiary = () => {
    setIsUpdate(false);
    setInitialPercentage(45);
    setTitleBeneficiary("Ingresar beneficiario");
    setOpenModalBeneficiary(true);
    setDataFormBeneficiary({
      ...dataFormBeneficiary,
      Id: "",
      DocumentNumber: "",
      Name: "",
      LastNameA: "",
      LastNameB: "",
      marriedName: "",
      Birthday: "",
      PhoneNumber: "",
      IdTypeName: "",
    });
  };

  const handleUpdatePerson = (dataForm: any) => {
    let body = {
      clientId: clientId,
      identificationTypeId: dataForm.IdType,
      countryId: dataForm.Country,
      catalogGender: dataForm.Gender,
      catalogTitle: "3536c66a-2cf7-44b1-9a81-b7486902b997",
      catalogRegionLevel1: dataForm.Province,
      catalogRegionLevel2: dataForm.Town,
      catalogRegionLevel3: dataForm.Sector,
      catalogCivilStatus: "42bedbc9-91cf-4d40-818e-3fc643e13e51",
      catalogProfession: "811c8769-9e83-42a3-9381-0ae279faf525",
      numberId: dataForm.DocumentNumber,
      firstNameA: dataForm.Name,
      firstNameB: dataForm.SecondName,
      lastNameA: dataForm.FirstLastName,
      lastNameB: dataForm.SecondLastName,
      //merryName: dataForm.MarriedSurName,
      addressA: dataForm.Address1,
      addressB: dataForm.Address2,
      zip: dataForm.PostalCode,
      phone1: dataForm.PhoneHome,
      phone2: dataForm.PhoneOffice,
      phone3: dataForm.PhoneNumber,
      email: dataForm.Email,
      marriedName: dataForm.MarriedSurName,
      dob: formattedDateWithTime(dataForm.Birthday),
    };
    dispatch(
      updatePerson({
        body,
        id: personQuoteId,
      }),
    );
  };

  const handleSubmitBeneficiary = (data: any) => {
    let body = {
      percentage: data.Percentage.toString(),
      quoteId: responseOneQuote.data?.id,
      catalogBeneficiaryType: data.idTypeInsured,
      person: {
        identificationTypeId: data.IdType,
        firstNameA: data.Name,
        firstNameB: data.SecondName,
        numberId: data.DocumentNumber.replace(/-/g, ""),
        lastNameA: data.LastNameA,
        lastNameB: data.LastNameB,
        marriedName: data.marriedName,
        phone1: data.PhoneNumber,
        dob: data.Birthday,
        clientId: clientId,
      },
    };
    if (isUpdate) {
      dispatch(patchBeneficiary({ body, id: data.Id }));
      getInsuredBeneficiary();
      setOpenModalBeneficiary(false);
    } else {
      dispatch(benefit(body));
    }
  };

  const handleSubmitInsured = (data: any) => {
    let body = {
      quoteId: responseOneQuote.data?.id,
      catalogInsuredType: data.idTypeInsured,
      person: {
        identificationTypeId: data.IdType,
        firstNameA: data.Name,
        firstNameB: data.SecondName,
        numberId: data.DocumentNumber.replace(/-/g, ""),
        lastNameA: data.LastNameA,
        lastNameB: data.LastNameB,
        marriedName: data.marriedName,
        phone1: data.PhoneNumber,
        dob: data.Birthday,
        clientId: clientId,
      },
    };
    if (isUpdate) {
      dispatch(patchInsured({ body, id: personId }));
      setOpenModal(false);
    } else {
      dispatch(postInsured(body));
    }
  };

  const callDispatchPageActionFlags = (id: string) => {
    dispatch(
      pageActionFlagsApi({
        enable: enable,
        page: page,
        limit: limit,
        clientId: clientId,
        projectId: id,
      }),
    );
  };

  const setLocalStorage = (data: any) => {
    let insuranceId = data.planId?.projectId;
    let insuranceName = data.planId?.projectInfo?.description || "---";
    let insuranceTypeId = data.planId?.projectInfo?.fkProjectTypeId;
    let insuranceTypeName =
      data.planId?.projectInfo?.fkProjectType.description || "---";
    let planId = data.planId?.id;
    let planName = data.planId?.name || "---";
    let coverTypeId = data.planId?.coverTypes[0]?.id;
    let coverTypeName = data.planId?.coverTypes[0]?.description || "---";
    let paymentModeId = data.catalogPaymentMode.id;
    let paymentModeName = data.catalogPaymentMode.description || "---";
    let campaignId = data.campaignId.id;
    let campaignName = data.campaignId.name || "---";

    localStorageService.setStepStored({
      id: insuranceTypeId,
      name: insuranceTypeName,
      step: Steps.insuranceType,
      completed: true,
    });
    localStorageService.setStepStored({
      id: insuranceId,
      name: insuranceName,
      step: Steps.insurance,
      completed: true,
    });
    localStorageService.setStepStored({
      id: planId,
      name: planName,
      step: Steps.customer,
      completed: true,
      quoteId: data.id,
      campaign: campaignId,
      coverType: { id: coverTypeId, name: coverTypeName },
      paymentModeSelected: { id: paymentModeId, name: paymentModeName },
      campaignSelected: { id: campaignId, name: campaignName },
    });
  };

  const getInsuredBeneficiary = () => {
    if (responseOneQuote && responseOneQuote.data) {
      let _id = responseOneQuote.data?.id;
      if (_id !== undefined) {
        dispatch(
          getBeneficiary({
            enable: true,
            page: 1,
            limit: 100,
            quoteId: _id,
          }),
        );
        dispatch(
          insured({
            enable: true,
            page: 1,
            limit: 100,
            quoteId: _id,
          }),
        );
      }
    }
  };

  const handleNext = (data: any) => {
    //dispatch(updateQuoteClearData());
    navigate("/checkout/payment-method");
  };

  const handlePrevious = () => {
    if (id === undefined) {
      setOpenModalBackConfirm(true);
    } else {
      navigate(isPlan ? "/" : "/insurance-policies-summary");
    }
  };

  const handleCloseBackModal = () => {
    setOpenModalBackConfirm(false);
  };

  const handleBackFromModal = () => {
    navigate("/");
  };

  const filterData = (array: any, key: string, value: string) => {
    let temp: any = [];
    temp = array.filter(function (e: any) {
      return e[key] == value;
    });
    let result: any = [];
    temp.map((item: any) => {
      result.push({ id: item.id, value: item.id, name: item.description });
    });
    return result;
  };

  const handleChangeProvince = (id: string) => {
    dispatch(
      region({
        enable: enable,
        page: page,
        limit: limit,
        level: level2,
        parentRegionId: id,
      }),
    );
    setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
  };

  const handleChangeTown = (id: string) => {
    dispatch(
      region({
        enable: enable,
        page: page,
        limit: limit,
        level: level3,
        parentRegionId: id,
      }),
    );
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (name === "ProvinceName") handleChangeProvince(value);
    else if (name === "TownName") handleChangeTown(value);
  };

  const handleDownload = (obj: any) => {
    window.open(obj.url, "_blank");
  };

  const editBeneficiary = (row: any) => {
    setIsMaskedDocument(false);
    setIsUpdate(true);
    setPersonId(row.row.id);
    setTitleBeneficiary("Editar beneficiario");
    setOpenModalBeneficiary(true);
    setInitialPercentage(parseFloat(row.row.percentage.replace("%", "")));
    const names = row.row.names.split(" ");
    const firstNameA = names[0] || "";
    const firstNameB = names[1] || "";
    const lastNames = row.row.lastNames.split(" ");
    const lastNameA = lastNames[0] || "";
    const lastNameB = lastNames[1] || "";

    listIdentificationType.map((item: select) => {
      if (item.name === "Pasaporte") {
        if (item.id === row.row.idType) {
          setIsMaskedDocument(true);
        }
      }
    });

    setDataFormBeneficiary({
      ...dataFormBeneficiary,
      Id: row.row.id,
      IdType: row.row.idType,
      DocumentNumber: row.row.documentNumber,
      Name: firstNameA,
      SecondName: firstNameB,
      LastNameA: lastNameA,
      LastNameB: lastNameB,
      marriedName: row.row.marriedName,
      Birthday: newFormattedDateYYMMDD(row.row.dob),
      PhoneNumber: row.row.phone1,
      idTypeInsured: row.row.idTypeInsured,
      Percentage: initialPercentage,
    });
  };

  const editInsured = (row: any) => {
    setIsMaskedDocument(false);
    setIsUpdate(true);
    setPersonId(row.row.id);
    setTitleInsured("Editar asegurado");
    const names = row.row.names.split(" ");
    const firstNameA = names[0] || "";
    const firstNameB = names[1] || "";
    const lastNames = row.row.lastNames.split(" ");
    const lastNameA = lastNames[0] || "";
    const lastNameB = lastNames[1] || "";
    setOpenModal(true);

    listIdentificationType.map((item: select) => {
      if (item.name === "Pasaporte") {
        if (item.id === row.row.idType) {
          setIsMaskedDocument(true);
        }
      }
    });

    setDataFormInsurance({
      ...dataFormInsurance,
      Id: row.row.id,
      IdType: row.row.idType,
      DocumentNumber: row.row.documentNumber,
      Name: firstNameA,
      SecondName: firstNameB,
      LastNameA: lastNameA,
      LastNameB: lastNameB,
      marriedName: row.row.marriedName,
      Birthday: newFormattedDateYYMMDD(row.row.dob),
      PhoneNumber: row.row.phone1,
      idTypeInsured: row.row.idTypeInsured,
    });
  };

  const columnsInsurance = Tab1Columns(editInsured, canEditInsured);

  const columnsBeneficiary = Tab2Columns(editBeneficiary, canEditBeneficiary);

  const handleSearch = (
    value: React.ChangeEvent<HTMLInputElement>,
    isInsuranceFlag: boolean,
  ) => {
    const document = value.target.value;
    if (document) {
      const cleanedDocument = document.replace(/-/g, "");
      const numberDocument = Number(cleanedDocument);
      if (!isNaN(numberDocument)) {
        setIsInsurance(isInsuranceFlag);
        setDocument(numberDocument);
        setTrigger(true);
      }
    }
  };

  const searchPerson = (value: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(value, false);
  };

  const searchInsurancePerson = (
    value: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleSearch(value, true);
  };

  return (
    <React.Fragment>
      <LoadingAFY
        loading={
          loadingPostInsured ||
          loadingUpdatePerson ||
          loadingOneQuote ||
          loadingBenefit ||
          loadingPostInsured ||
          loadingPatchBeneficiary ||
          loadingPatchInsured ||
          loadingPersonByDocument
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={loadingUpdatePerson ? "Actualizando datos." : "Cargando..."}
        bgColor={themeCore.colors.backgroundLoader}
      />
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}
      <BreadcrumbAFY options={Breadcrum} />
      <article
        style={{
          position: "absolute",
          top: "34px",
          right: "427px",
          zIndex: "9",
        }}
      >
        <StepProgressAFY
          onClickStepProgress={() => {}}
          stepProgress={steps}
          collapsed
          sticky
        />
      </article>
      <ModalComponentAFY
        openModal={openModal}
        backgroundColor="#ffffff"
        onClose={() => setOpenModal(false)}
        border={0}
        modalWidth={986}
        padding={40}
        modalHeight={"90%"}
      >
        <FormBeneficiaryAFY
          isntMaskedDocument={isMaskedDocument}
          dataForm={dataFormInsurance}
          initialPercentageBeneficiaries={initialPercentage}
          onClickAcquire={function noRefCheck() {}}
          onClickCancel={() => setOpenModal(false)}
          onClickSave={function noRefCheck() {}}
          onNext={handleSubmitInsured}
          openModal
          subtitle="Asegurado"
          title={titleInsured}
          typeInsuredOption={insuredType}
          TypeList={listIdentificationType}
          formsCompleted={[true, false, false]}
          onBlurDocument={(document) => {
            searchInsurancePerson(document);
          }}
          onBlurInput={(document) => {
            searchInsurancePerson(document);
          }}
        />
      </ModalComponentAFY>
      <ModalComponentAFY
        openModal={openModalBeneficiary}
        backgroundColor="#ffffff"
        onClose={() => setOpenModalBeneficiary(false)}
        border={0}
        modalWidth={1170}
        padding={15}
        modalHeight={"90%"}
      >
        <FormBeneficiaryAFY
          isntMaskedDocument={isMaskedDocument}
          dataForm={dataFormBeneficiary}
          initialPercentageBeneficiaries={initialPercentage}
          labelPercentage="Porcentaje a beneficiar"
          onClickCancel={() => setOpenModalBeneficiary(false)}
          onClickSave={function noRefCheck() {}}
          onNext={handleSubmitBeneficiary}
          openModal
          stepPercentageBeneficiaries={5}
          subtitle="Beneficiario"
          title={titleBeneficiary}
          typeInsuredOption={beneficiaryType}
          TypeList={listIdentificationType}
          formsCompleted={[true, false, false]}
          showPercentage={true}
          onBlurDocument={(document) => {
            searchPerson(document);
          }}
          onBlurInput={(document) => {
            searchPerson(document);
          }}
        />
      </ModalComponentAFY>
      <ModalComponentAFY
        openModal={openModalBackConfirm}
        backgroundColor="#ffffff"
        onClose={handleCloseBackModal}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>Confirmación</h2>
          <p className={classes.description}>
            {`¿Está seguro que desea regresar, esto iniciara un nuevo proceso?`}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"No"}
                onClick={handleCloseBackModal}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={"Si, Regresar"}
                onClick={(e) => {
                  e.stopPropagation();
                  handleBackFromModal();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>

      {showComponent ? (
        <Suspense>
          <FormConfirmationAFY
          worker="/pdf.worker.js"
            onChangeSelect={handleChangeSelect}
            labelNumberQuote={
              responseOneQuote?.data?.policyId
                ? "No. de póliza"
                : "No. de cotización"
            }
            title={"Confirmación de datos"}
            identificationList={listIdentificationType}
            GenderList={listGenders}
            CountryList={listCountries}
            ProvinceList={listRegionLevel1}
            TownList={listRegionLevel2}
            SectorList={listRegionLevel3}
            dataForm={dataForm}
            hideTabs={[showInsured, showBeneficiary, !(isQuote === "false")]}
            hideAddButton={[addInsured, addBeneficiary]}
            showAdd={true}
            showActions={canEdit}
            showDownloads={!canEdit}
            onClickDownload={handleDownload}
            certificates={certificates}
            dateLabel={
              isQuote !== null && isQuote === "false"
                ? "Fecha efectiva"
                : "Fecha de cotización"
            }
            downloadsTitle="Descargas"
            labelAdd="Agregar"
            tab1="Personas aseguradas"
            tab1Description="Asegurados"
            tab2="Beneficiarios"
            tab2Description="Beneficiarios"
            tab3="Información de pago"
            tab3Description=""
            Tab1Columns={columnsInsurance}
            Tab1Rows={rows}
            Tab2Columns={columnsBeneficiary}
            Tab2Rows={rowsBeneficiary}
            Tab3Columns={Tab3Columns}
            Tab3Rows={rows2}
            hide2={true}
            options1={paymentMode}
            options2={[]}
            options3={campaignInfo}
            firstSubtitle="Información de cotización"
            secondSubtitle="Información general del cliente"
            thirdSubtitle="Información de ubicación"
            fourthSubtitle="Información de contacto"
            plan={{ id: "1", name: "Accidentes" }}
            plans={[]}
            onClickAdd={handleAdd}
            onClickRowGrid={() => {}}
            onClickAddBeneficiary={handleAddBeneficiary}
            filters={[
              {
                name: "Tipo de producto",
                label:
                  localStorageService.getStepStored().steps[Steps.insuranceType]
                    ?.name ?? "",
                type: "label",
              },
              {
                name: "Producto",
                label:
                  localStorageService.getStepStored().steps[Steps.insurance]
                    ?.name ?? "",
                type: "label",
              },
              {
                name: "Plan",
                label:
                  localStorageService.getStepStored().steps[Steps.customer]
                    ?.name ?? "",
                type: "label",
              },
              {
                name: "Tipo de pago",
                label:
                  localStorageService.getStepStored().steps[Steps.customer]
                    .paymentModeSelected?.name ?? "",
                type: "label",
              },
              {
                name: "Tipo de cobertura",
                label:
                  localStorageService.getStepStored().steps[Steps.customer]
                    .coverType?.name ?? "",
                type: "label",
              },
              {
                name: "Campaña",
                label:
                  localStorageService.getStepStored().steps[Steps.customer]
                    .campaignSelected?.name ?? "",
                type: "label",
              },
              {
                name: "Prima",
                label: `${currency} ${prime}`,
                type: "label",
              },
            ]}
            previousLabel="Anterior"
            nextLabel={"Siguiente"}
            showPrevious={canEdit}
            nextDisabled={
              !permissionList?.find(
                (i) => i.name === "sale-confirmation-form-create",
              ) || !canEdit
            }
            onBack={handlePrevious}
            onClickSave={handleUpdatePerson}
            onNext={handleNext}
            onClickPrevious={handlePrevious}
            onBlurInput={() => {}}
            onChangeInput={() => {}}
            onClickCancel={() => {}}
            disableDocumentType={true}
            disableDocumentNumber={true}
          />
        </Suspense>
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
};

export default ConfirmationForm;
