import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "./view";
import { ResponseError } from "../../resources/ResponseError";
import { Branches, BranchesRequest, } from "../../model/branches";
import LocalStorageService from "../../services/localStorage";
import { useBranchesGetAll  } from "../../fnx/branches/branchesGetAll";
import { useBranchesPatch  } from "../../fnx/branches/branchesPatch";
import { useBranchesPost  } from "../../fnx/branches/branchesPost";
import { SelectChangeEvent } from "@mui/material";
import { deleteEntity, deleteEntityClearData } from "../../store/delete/actions";
import {
  branchesClearData,
  branchesPatchClearData,
  branchesPostClearData,
} from "../../store/branches/actions";
import { Columns, dataFormBranch} from "./branchDTO";
import {
  LoadingAFY,
  position,
  typeAlert,
  MenuItem,
  typeLoaders
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const BranchAdmin = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState<Branches[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [bodyRequest, setBodyRequest] = useState<BranchesRequest>({ data: {}});
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [branchId] = React.useState<string>(
    lsLogin.getUserLoginStored().branchId,
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [dataForm, setDataForm] = useState(dataFormBranch);
  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseBranches, errorBranches, loadingBranches } = useBranchesGetAll(trigger, clientId,nameSearch,"");
  const { responseBranchesPost, errorBranchesPost, loadingBranchesPost } = useBranchesPost(triggerPost,bodyRequest);
  const { responseBranchesPatch, errorBranchesPatch, loadingBranchesPatch }  = useBranchesPatch (triggerPatch, bodyRequest, bodyRequest?.data?.id);
  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  useEffect(() => {
      if (!responseBranches) {
        setTrigger(true);
      }
    }, []);


    const callBranches = () => {
      setTrigger(!trigger);
    };
  
    useEffect(() => {
      callBranches();
    }, []);

    

  useEffect(() => {
    if (responseBranches ) {
      const list: Branches[] = responseBranches?.map(
        (item: Branches) => ({
          id: item.id,
          Code: item.code,
          Name: item.name,
        }),
      );
      setList(list);
    }
    dispatch(branchesClearData());
    setTrigger(false);
  }, [responseBranches, errorBranches]);

  useEffect(() => {
    if (responseBranchesPost) {
      setSeeToast({
        show: true,
        message: "Creado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenDrawer(false);
      setTriggerPost(false);
      setTrigger(true);
    } else if (errorBranchesPost) {
      const error = errorBranchesPost as ResponseError;
      let message = "Error al crear";
      if (error.response?.errors  !== undefined){
        message = error.response?.errors[0].message;
      } else {
        message = error.response?.message;
      }
      setSeeToast({
        show: true,
        message: message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
      setTriggerPost(false);
      setTrigger(false);
    }
    dispatch(branchesPostClearData());
  }, [responseBranchesPost, errorBranchesPost]);

  useEffect(() => {
    if (responseDeleteEntity) {
      setSeeToast({
        show: true,
        message: "Eliminado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenDrawer(false);
      setOpenModalDelete(false);
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      setSeeToast({
        show: true,
        message: errorDeleteEntity?.message.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  useEffect(() => {
    if (responseBranchesPatch) {
      setSeeToast({
        show: true,
        message: "Actualizado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenDrawer(false);
      setTriggerPatch(false);
      setTrigger(true);
    } else if (errorBranchesPatch) {
      setSeeToast({
        show: true,
        message: errorBranchesPatch?.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
      setTriggerPatch(false);
    }
    dispatch(branchesPatchClearData());
  }, [responseBranchesPatch, errorBranchesPatch]);

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  useEffect(() => {
    if (nameSearch) {
      setTrigger(true);
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  const onCloseSearch = () => {
    setNameSearch("");
    setTrigger(true);
  };

  const filterStatus = (item: any) => {
    // const idUser = item?.user.map((item: any) => item.id).join(",");
    // setTrigger(true);
  };

  const showEdit = (row: any) => {
       setDataForm({
        ...dataForm,
        id: row.id,
        Code: row.Code,
        Name: row.Name,
      });
  };

  const handleSubmit = (dataItem: any) => {
     if (isUpdate) {
      setBodyRequest({data : {id: dataItem.id, name: dataItem.Name, code: dataItem.Code, status: "1"}});
      setTriggerPatch(true);
    } else {      
      setBodyRequest({data : { name: dataItem.Name,  code: dataItem.Code, status: "1" }});
      setTriggerPost(true);    
    }
    setOpenDrawer(false);
  };

   
  const handleCloseModal = () => {
    setOpenDrawer(false);
  };

  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenDrawer(true);
    setDataForm({
      ...dataForm,
      id: "",
      Code: "",
      Name: "",
    });
  };


  const onClickContextMenu = (opt?: any, item?: any) => {
    if (opt.id === "1") {
      showEdit(item.row);
      setIsUpdate(true);
      setOpenDrawer(true);
    } else if (opt.id === "2") {
      setitemModal(item);
      setOpenModalDelete(true);
    } else if (opt.id === "3") {
      setitemModal(item);
    }
  }
  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleDelete = () => {
    setOpenModalDelete(false);
    dispatch(deleteEntity({ id: itemModal?.row?.id, entity: "BranchEntity", idUser: clientId }));
  };
  const ColumnsRegion = Columns(onClickContextMenu);
    
  return (
    <React.Fragment>
      <LoadingAFY
        loading={loadingBranches || loadingBranchesPost || loadingBranchesPatch || loadingDeleteEntity}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={
          loadingBranches || loadingBranchesPost || loadingBranchesPatch ||
          loadingDeleteEntity ? "Eliminando sucursal" : "Cargando sucursales..."
        }
        bgColor={themeCore.colors.backgroundLoader}
      />
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}

      <View
        // Props Admin
        title="Sucursales"
        columns={ColumnsRegion}          
        buttonTextAdd=  "Agregar sucursal"
        onClickAdd={handleOnClickAdd}

        // Props Form
        titleForm= "Edición de sucursal"
        dataForm={dataForm}
        editMode={false}
        buttonText= "Guardar"
        onSaveData={handleSubmit}
        
        // Props Drawer
        openDrawer={openDrawer}
        textDescription="Lorem ipsum dolor sit amet consectetur. Eu arcu venenatis diam convallis non faucibus sem fringilla suspendisse. Vitae pharetra enim quis augue enim. Tristique eu cras consectetur tincidunt sed velit. In tristique facilisis leo viverra. In malesuada diam ante nec mauris. Id volutpat enim parturient penatibus eu orci. Neque risus morbi diam"
        onCloseDrawer={handleCloseModal}

        // Props Filter
        filterStatus={filterStatus}
        searchFilter={searchFilter}
        onCloseSearch={onCloseSearch}

        list={list}
        modalTitle={itemModal?.row?.Name}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        clickView={clickView}
        onSubmit={handleDelete}
        onClickContextMenu={onClickContextMenu}
      />
    </React.Fragment>
  );
};

export default BranchAdmin;