import {
  breadcrumb,
  ButtonAFY,
  MenuItem,
  sizes,
  state,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";

interface TableColumn {
  field: string;
  headerName: string;
  width: number;
  renderCell?: (params: any) => JSX.Element;
}

export const stepProgress: MenuItem[] = [
  {
    id: "1",
    name: "Nombre del seguro",
    state: state.complete,
  },
  {
    id: "2",
    name: "Tipo de plan",
    state: state.complete,
  },
  {
    id: "3",
    name: "Datos del cliente",
    state: state.complete,
  },
  {
    id: "4",
    name: "Confirmación",
    state: state.complete,
  },
  {
    id: "5",
    name: "Datos de pago",
    state: state.complete,
  },
  {
    id: "6",
    name: "Confirmación de pago",
    state: state.complete,
  },
];

export const Tab1Columns = (showEdit: any, canEditInsurance: boolean) => {
  const columns: TableColumn[] = [
    {
      field: "type",
      headerName: "Tipo asegurado",
      width: 180,

    },
    {
      field: "names",
      headerName: "Nombres",
      width: 280,
    },

    {

      field: "lastNames",
      headerName: "Apellidos",
      width: 280,
    },

    {
      field: "marriedName",
      headerName: "Apellido de casada",
      width: 240,
    },
    {
      field: "phone1",
      headerName: "Teléfono",
      width: 150,
    },
    {
      field: "dob",
      headerName: "Fecha nacimiento",
      width: 220,
    },
  ];
  if (canEditInsurance) {
    columns.unshift({
      field: "actions",
      headerName: "Acciones",
      width: 100,
      renderCell: (params: any) => (
        <>
        {params.row.type !== "Titular" && (
        <ButtonAFY
          size={sizes.small}
          variant={variants.outlined}
          label={"Editar"}
          color={themeCore.colors.buttonPrimary}
          onClick={() => showEdit(params)}
        />
        )}
        </>
      ),
    });
  }
  return columns;
};

export const Tab2Columns = (showEdit: any, canEditBeneficiary: boolean) => {
  const columns: TableColumn[] = [
    {
      field: "type",
      headerName: "Tipo beneficiario",
      width: 200,
    },
    {

      field: "names",
      headerName: "Nombres",
      width: 280,
    },
    {
      field: "lastNames",
      headerName: "Apellidos",
      width: 280,
    },
    {
      field: "dob",
      headerName: "Fecha nacimiento",
      width: 230,
    },
    {
      field: "percentage",
      headerName: "Porcentaje",
      width: 170,
    },
  ];

  if (canEditBeneficiary) {
    columns.unshift({
      field: "actions",
      headerName: "Acciones",
      width: 100,
      renderCell: (params: any) => (
        <>
        {params.row.type !== "Titular" && (
        <ButtonAFY
          size={sizes.small}
          variant={variants.outlined}
          label={"Editar"}
          color={themeCore.colors.buttonPrimary}
          onClick={() => showEdit(params)}
        />
    
        )}
        </>
      ),
    });
  }
  return columns;
};

export const Tab3Columns: any = [
  {
    field: "accountName",
    headerName: "Nombre de la cuenta",
    width: 300,
  },
  {
    field: "accountNumber",
    headerName: "Número de cuenta",
    width: 200,
  },
  {
    field: "accountType",
    headerName: "Tipo de cuenta",
    width: 250,
  },
  {
    field: "paymentMode",
    headerName: "Modo de pago",
    width: 150,
  },
  {
    field: "prime",
    headerName: "Prima",
    width: 150,
  },
];

export const Breadcrum: breadcrumb[] = [
  {
    name: "Producto",
    key: "producto",
    ariaCurrent: "",
  },
  {
    name: "Plan",
    key: "plan",
    ariaCurrent: "",
  },
  {
    name: "Validar cliente",
    key: "cliente",
    ariaCurrent: "",
  },
];
