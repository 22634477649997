import * as React from "react";
import { useEffect, useState } from "react";
import { PaymentConfirmationView } from "./view";
import { useDispatch, useSelector } from "react-redux";
import { quoteOne } from "../../store/generateQuote/actions";
import {
  policyConfirm,
  policyConfirmClearData,
} from "../../store/policy/actions";
import { useNavigate } from "react-router-dom";
import showToast from "../../services/toast";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import {
  BreadcrumbAFY,
  DataFormClient,
  LoadingAFY,
  ModalComponentAFY,
  position,
  SuccessAFY,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import {
  Breadcrum,
  getDataFormFilters,
  stepProgress,
} from "./paymentConfirmationDTO";
import { ContainerBreadcrum } from "./style";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";

const PaymentConfirmation = () => {
  const { t } = useTranslation("global");
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const navigate = useNavigate();
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [showModal, setShowModal] = useState<boolean>(true);
  const [policyId] = useState<string>(
    localStorageService.getStepStored().steps[Steps.pay].id,
  );
  const [quoteId] = useState<string>(
    localStorageService.getStepStored().steps[Steps.customer].quoteId || "",
  );
  const [certificates, setCertificates] = useState<any[]>([]);
  const [email, setEmail] = useState<string>(
    localStorageService.getStepStored().steps[Steps.pay].email || "",
  );
  const [dataForm, setDataForm] = useState<
    DataFormClient & { Insurance?: string } & { CoverType?: string } & {
      PaymentMode?: string;
    } & { Currency?: string } & { Prima?: string } & { QuoteId?: string }
  >({});
  const dispatch = useDispatch();

  const { responseQuote } = useSelector((state: any) => ({
    responseQuote: state.Quote.responseQuote,
  }));

  const { responseOneQuote, errorOneQuote } = useSelector((state: any) => ({
    responseOneQuote: state.Quote.responseOneQuote,
    errorOneQuote: state.Quote.errorOneQuote,
  }));

  const {
    responsePolicyConfirm,
    responsePolicyConfirmError,
    responsePolicyConfirmLoading,
  } = useSelector((state: any) => ({
    responsePolicyConfirm: state.Policy.responsePolicyConfirm,
    responsePolicyConfirmError: state.Policy.errorPolicyConfirm,
    responsePolicyConfirmLoading: state.Policy.loadingPolicyConfirm,
  }));

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (responsePolicyConfirmError) {
      showErrorToast(responsePolicyConfirmError, setSeeToast, t);
    }
  }, [responsePolicyConfirmError]);

  useEffect(() => {
    dispatch(quoteOne({ id: quoteId, fullBody: true }));
  }, []);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      let certs = responseOneQuote.data.certificates;

      if (certs.length > 0) {
        certs = certs.map((c: any, id: any) => {
          return {
            id: c.id,
            name: c.title,
            url: c.url,
          };
        });
      }
      setCertificates(certs);
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (responsePolicyConfirm && responsePolicyConfirm?.data) {
      setSeeToast({
        show: true,
        message: "Correo enviado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);

      dispatch(policyConfirmClearData());
    }
  }, [responsePolicyConfirm]);

  useEffect(() => {
    if (responseQuote?.data || responseOneQuote?.data) {
      let df = dataForm;
      df.Name = responseOneQuote
        ? `${responseOneQuote?.data?.firstNameA} ${responseOneQuote?.data?.firstNameB}`
        : `${responseQuote.data.firstNameA} ${responseQuote.data.firstNameB}`;
      df.LastNames = responseOneQuote
        ? `${responseOneQuote?.data?.lastNameA} ${responseOneQuote?.data?.lastNameB}`
        : `${responseQuote.data.lastNameA} ${responseQuote.data.lastNameB}`;
      df.DocumentNumber = responseOneQuote
        ? `${responseOneQuote?.data?.numberId}`
        : `${responseQuote.data.numberId}`;
      df.Insurance = responseOneQuote
        ? `${responseOneQuote?.data?.productId?.description}`
        : `${responseQuote.data.productId.description}`;
      df.PlanTypeName = responseOneQuote
        ? `${responseOneQuote?.data?.planId?.name}`
        : `${responseQuote.data.planId.name}`;
      df.CampaignName = responseOneQuote
        ? responseOneQuote &&
          `${responseOneQuote?.data?.productId?.campaign[0]?.name}`
        : `${responseQuote.data.productId.campaign[0].name}`;
      df.CoverType = responseOneQuote?.data?.coverType?.description ?? "";
      df.PaymentMode = responseOneQuote
        ? `${responseOneQuote?.data?.catalogPaymentMode?.description}`
        : `${responseQuote.data.catalogPaymentMode.description}`;
      df.Currency = responseOneQuote?.data?.planId.price.currencySymbol;
      df.Prima = responseOneQuote?.data?.planId.price.amount
        ? responseOneQuote?.data?.planId.price.amount
        : "imformacion no encontrada";
      df.QuoteId = responseOneQuote
        ? `${responseOneQuote?.data?.id}`
        : `${responseQuote.data.id}`;

      setDataForm(df);
      setInterval(() => {
        setShowModal(false);
      }, 5000);
    }
  }, [responseQuote, responseOneQuote]);

  const handleSendEmail = (email: string) => {
    let body = {
      id: policyId,
      includeAttachments: false,
      ccEmail: [email],
    };
    dispatch(policyConfirm(body));
  };

  const handlePrevious = () => {
    navigate("/insurance-policies-summary");
  };

  const handleDownload = (obj: any) => {
    window.open(obj.url, "_blank");
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const showSuccess = () => {
    return (
      <ModalComponentAFY
        openModal={showModal}
        onClose={closeModal}
        backgroundColor={themeCore.colors.background}
        BgBackdrop={themeCore.colors.backgroundLoader}
        modalWidthNew={"auto"}
        minWidthModal={"250px"}
      >
        <SuccessAFY
          message="La póliza ha sido creada."
          numberAmount={responseOneQuote?.data?.policyId?.code}
          numberMessage="No. Póliza"
          onClickButton={() => {}}
          buttonColor={""}
          buttonText={""}
          title="Póliza creada"
          animate={false}
        />
      </ModalComponentAFY>
    );
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}

      <LoadingAFY
        loading={responsePolicyConfirmLoading}
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Enviando correo..."
        bgColor={themeCore.colors.backgroundLoader}
      />

      {showModal && showSuccess()}
      <ContainerBreadcrum>
        <BreadcrumbAFY options={Breadcrum} />
      </ContainerBreadcrum>

      <PaymentConfirmationView
        previousLabel="Ver pólizas"
        title="Pago"
        onNext={handlePrevious}
        nextLabel={"Finalizar"}
        section1Title="Descarga de documentos"
        section2Title="Enviar por correo"
        dataForm={dataForm}
        defaultEmail={email}
        stepProgress={stepProgress}
        documents={certificates}
        filters={getDataFormFilters(dataForm)}
        onBack={handlePrevious}
        onClickSendEmail={handleSendEmail}
        onClickDownload={handleDownload}
      />
    </React.Fragment>
  );
};

export default PaymentConfirmation;
