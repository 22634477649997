import { URLs } from "../url";
import { fetchRequest } from "../fetchRequest";
import { UpdateQuoteResponse } from "../../model/updateQuote";
import { getHeader } from "../headers";

const updateQuoteAPI = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.quote + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as UpdateQuoteResponse;
  } catch (error: any) {
    throw error;
  }
};

export { updateQuoteAPI };
