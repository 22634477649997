import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "./view";
import { ResponseError } from "../../resources/ResponseError";
import { Region, RegionRequest, } from "../../model/region";
import LocalStorageService from "../../services/localStorage";
import { useRegionGetAll } from "../../fnx/region/regionGetAll";
import { useRegionPatch } from "../../fnx/region/regionPatch";
import { useRegionPost } from "../../fnx/region/regionPost";
import { useGetRegion } from "../../fnx/region/regionGetById";
import { Level } from "../../enum/region";
import { SelectChangeEvent } from "@mui/material";
import { deleteEntity, deleteEntityClearData } from "../../store/delete/actions";
import {
  regionPatchClearData,
  regionPostClearData,
  regionClearData,
  regionGetOneClearData,
  region,
} from "../../store/region/actions";
import { Columns } from "./regionDTO";
import {
  LoadingAFY,
  position,
  typeAlert,
  MenuItem,
  typeLoaders,
  icons,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { dataFormRegion } from "./regionDTO";
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const RegionAdmin = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState<Region[]>([]);
  const level1: Level = "REGION_LEVEL1";
  const level2: Level = "REGION_LEVEL2";
  const level3: Level = "REGION_LEVEL3";
  const [levelForm, setLevelForm] = useState<Level>(level1);
  const [trigger, setTrigger] = useState(false);
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [bodyRequest, setBodyRequest] = useState<RegionRequest>({ data: {} });
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(100);
  const [countPagination, setCountPagination] = useState<number>(0);

  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [branchId] = React.useState<string>(
    lsLogin.getUserLoginStored().branchId,
  );
  const [treePrevious, setTreePrevious] = useState<any>(null);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [dataForm, setDataForm] = useState(dataFormRegion);

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [regionOptions1, setRegionOptions1] = useState<any>(null);
  const [treeData, setTreeData] = useState<any>([]);
  const [parentLevel, setParentLevel] = useState<any>([]);
  const [parentRegionOptions, setParentRegionOptions] = useState<any>(null);
  const regionLevelOptions = [{ id: 1, name: "Provincia", value: "REGION_LEVEL1" }, { id: 2, name: "Municipio", value: "REGION_LEVEL2" }, { id: 3, name: "Sector", value: "REGION_LEVEL3" }]

  const [breadcrumb, setBreadcrumb] = useState<any>(null);
  //trigger  name  zipcode  code: string,  type: Level,  parentRegionId: number,  clientId: string,35b1cb75-8bba-4b03-bcea-6413661b870a"
  const { responseRegion, errorRegion, loadingRegion } = useRegionGetAll(trigger, nameSearch, "", "", levelForm, parentLevel[0] ? parentLevel[parentLevel.length - 1].value : "", clientId, page, limit);
  //const { responseRegionOne, errorRegionOne, loadingRegionOne } = useGetRegion(triggerOne,parentLevel.value || "" );
  const { responsePostRegion, errorPostRegion, loadingPostRegion } = useRegionPost(triggerPost, bodyRequest);
  const { responsePatchRegion, errorPatchRegion, loadingPatchRegion } = useRegionPatch(triggerPatch, bodyRequest);
  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  useEffect(() => {
    if (!responseRegion) {
      setLevelForm(level1);
      setTrigger(true);
    }
  }, []);

  useEffect(() => {
    if (responseRegion && responseRegion?.data) {
      setCountPagination(responseRegion?.pagingInfo.totalItems);
      const data = responseRegion.data?.map((region: Region) => {

        return {
          id: region.id,
          //value: region.id,
          title: region.name,
          code: region.code,
          logo: "",
          parentId: region.parentRegionId,
          clientId: region.clientId,
          name: region.name,
          nameLower: region.name?.toLowerCase(),
          parent: region.parentRegionId,
          children: null,
          folder: true,
          level: region.type,
        };
      });

      const tree = responseRegion.data.map((item: any) => {
        return {
          name: item.name,
          nameLower: item.name?.toLowerCase(),
          parent: item.parentRegionId,
          children: null,
          id: item.id,
          folder: true,
          level: item.type,
        }
      });


      if (responseRegion.level === level1) {
        setParentLevel([]);

        setBreadcrumb([{
          name: "",
          ariaCurrent: "",
          key: ""
        }]);
      }


      setRegionOptions1(data);

      if (nameSearch === "") 
      setTreeData({ data: tree });

      const list: Region[] = responseRegion.data.map(
        (item: Region) => ({
          id: item.id,
          Code: item.code,
          Name: item.name,
          ZipCode: item.zipcode,
          RegionPadre: "",
          RegionType: item.type,
        }),
      );
      setList(list);
    }
    setTrigger(false);
    dispatch(regionClearData());
  }, [responseRegion]);

  useEffect(() => {
    if (errorRegion) {
      setTrigger(false);
    }
  }, [errorRegion]);




  useEffect(() => {
    if (responsePostRegion) {
      setSeeToast({
        show: true,
        message: "Creado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenDrawer(false);
      setTriggerPost(false);
      setTrigger(true);
    } else if (errorPostRegion) {

      const error = errorPostRegion as ResponseError;
      let message = "Error al crear";
      if (error.response?.errors !== undefined) {
        message = error.response?.errors[0].message;
      } else {
        message = error.response?.message;
      }
      setSeeToast({
        show: true,
        message: message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
      setTriggerPost(false);
      setTrigger(false);
    }
    dispatch(regionPostClearData());
  }, [responsePostRegion, errorPostRegion]);


  useEffect(() => {
    if (responseDeleteEntity) {
      setSeeToast({
        show: true,
        message: "Eliminado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenDrawer(false);
      setOpenModalDelete(false);
      dispatch(deleteEntityClearData());
      setTrigger(true);
    } else if (errorDeleteEntity) {
      setSeeToast({
        show: true,
        message: errorDeleteEntity?.message.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [responseDeleteEntity, errorDeleteEntity]);

  useEffect(() => {
    if (responsePatchRegion) {
      setSeeToast({
        show: true,
        message: "Actualizado con éxito",
        type: typeAlert.success,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.success,
        });
      }, 3000);
      setOpenDrawer(false);
      setTriggerPatch(false);
      setTrigger(true);
    } else if (errorPatchRegion) {
      setSeeToast({
        show: true,
        message: errorPatchRegion?.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
      setTriggerPatch(false);
    }
    dispatch(regionPatchClearData());
  }, [responsePatchRegion, errorPatchRegion]);

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  useEffect(() => {
    if (nameSearch) {
      setTrigger(true);
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  const onCloseSearch = () => {
    setNameSearch("");
    setTrigger(true);
  };

  const filterStatus = (item: any) => {
    // const idUser = item?.user.map((item: any) => item.id).join(",");
    // setTrigger(true);
  };

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      id: row.id,
      Code: row.Code,
      Name: row.Name,
      Zipcode: row.ZipCode,
      RegionType: row.RegionType,
      ParentRegion: row.RegionPadre,

    });
  };

  const handleSubmit = (dataItem: any) => {
    if (isUpdate) {
      setBodyRequest({ data: { id: dataItem.id, name: dataItem.Name, zipcode: dataItem.Zipcode, code: dataItem.Code, parentRegionId: dataItem.ParentRegion, type: dataItem.RegionType } });
      setTriggerPatch(true);
    } else {
      setBodyRequest({ data: { name: dataItem.Name, zipcode: dataItem.Zipcode, code: dataItem.Code, parentRegionId: dataItem.ParentRegion, type: dataItem.RegionType } });
      setTriggerPost(true);
    }
    setOpenDrawer(false);
  };


  const handleCloseModal = () => {
    setOpenDrawer(false);
  };

  const handleChangeRegionType = (value: string) => {
    /* if (value === "REGION_LEVEL1") {
       setParentRegionOptions(null);
     } else if (value === "REGION_LEVEL2") {
       setParentRegionOptions(regionOptions1);
     } else if (value === "REGION_LEVEL3") {
       setParentRegionOptions(regionOptions2);
     }*/
  };

  //const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { value, name } = event.target;
    if (name === "RegionType") {
      handleChangeRegionType(value);
    }
    else if (name === "a") {
    }
  };

  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenDrawer(true);
    setDataForm({
      ...dataForm,
      id: "",
      Code: "",
      Name: "",
      Zipcode: "",
      RegionType: "",
      ParentRegion: "",
    });
  };


  const onClickContextMenu = (opt?: any, item?: any) => {
    if (opt.id === "1") {
      showEdit(item.row);
      setIsUpdate(true);
      setOpenDrawer(true);
    } else if (opt.id === "2") {
      setitemModal(item);
      setOpenModalDelete(true);
    } else if (opt.id === "3") {
      setitemModal(item);
    }
  }
  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleDelete = () => {
    setOpenModalDelete(false);
    dispatch(deleteEntity({ id: itemModal?.row?.id, entity: "RegionEntity", idUser: clientId }));
  };
  const ColumnsRegion = Columns(onClickContextMenu);
  const buttonActionOptions = [
    {
      icons: icons.Menu,
      id: "1",
      name: "editar",
    },
    {
      icons: icons.Menu,
      id: "2",
      name: "eliminar",
    },
    {
      icons: icons.Menu,
      id: "3",
      name: "clonar",
    }];

  const handleOnClickBreadcrumb = (item: any) => {
    console.log("handleOnClickBreadcrumb", item);
  }
    const handleClickTree = (item: any) => {
      setNameSearch("");
    if (item?.value) {
      let itemRegion = regionOptions1.filter((i: any) => i.id === item.value);

      if (itemRegion[0].level === level1 || itemRegion[0].level === level2) {
        itemRegion[0].value = item.value;
        setTreePrevious(itemRegion);
        let bc = [...breadcrumb];
        let found = breadcrumb.some((el: any) => el.key === itemRegion[0].id);
        if (!found) {

          bc.push({
            name: itemRegion[0].title,
            ariaCurrent: itemRegion[0].level,
            key: itemRegion[0].id,

          });
        }
        setBreadcrumb(bc);

        let pl = [...parentLevel];
        pl.push(itemRegion[0]);
        setParentLevel(pl);

        if (itemRegion[0].level === level1) {
          setLevelForm(level2);
        }
        if (itemRegion[0].level === level2) {
          setLevelForm(level3);
        }

        setTrigger(true);

      } else {
        console.log("No se puede seleccionar un sector", itemRegion[0].level);
      }
    }
  };

  const handleClickBack = (item: any) => {
    setNameSearch("");
    let pl = [...parentLevel];
    pl.pop();
    setParentLevel(pl);

    //setParentLevel(item);

    let bc = [...breadcrumb];
    bc.pop();
    setBreadcrumb(bc);



    if (levelForm === level2) {
      setLevelForm(level1);
    } else if (levelForm === level3) {
      setLevelForm(level2);
    }
    setTrigger(true);    
  };

  const onChangeRowsPerPage = (e: number) => {
    setLimit(e);
    setTrigger(true);
  };

  const onChangePage = (e: number) => {
    setPage(e);
    setTrigger(true);
  };

  return (
    <React.Fragment>
      <LoadingAFY
        loading={loadingRegion || loadingPostRegion || loadingPatchRegion || loadingDeleteEntity}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={
          loadingRegion || loadingPostRegion || loadingPatchRegion ||
            loadingDeleteEntity ? "Eliminando region" : "Cargando regiones..."
        }
        bgColor={themeCore.colors.backgroundLoader}
      />
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}

      <View
        // Props Admin
        title="Regiones"
        columns={regionOptions1 || []}
        onClickBreadcrumb={handleOnClickBreadcrumb}
        buttonTextAdd="Agregar region"
        onChangeSelect={handleSelectChange}
        onClickAdd={handleOnClickAdd}
        parentLevel={parentLevel}
        buttonActionOptions={buttonActionOptions}
        onClickTree={handleClickTree}
        onClickBackTree={handleClickBack}
        breadcrumb={breadcrumb}
        treePrevious={parentLevel[0] ? [parentLevel[parentLevel.length-1]] : []}

        // Props Form
        titleForm="Edición de region"
        dataForm={dataForm}
        editMode={false}
        buttonText="Guardar"
        RegionTypeOptions={regionLevelOptions}
        ParentRegionTypeOptions={parentRegionOptions}
        onSaveData={handleSubmit}

        // Props Drawer
        openDrawer={openDrawer}
        textDescription="Lorem ipsum dolor sit amet consectetur. Eu arcu venenatis diam convallis non faucibus sem fringilla suspendisse. Vitae pharetra enim quis augue enim. Tristique eu cras consectetur tincidunt sed velit. In tristique facilisis leo viverra. In malesuada diam ante nec mauris. Id volutpat enim parturient penatibus eu orci. Neque risus morbi diam"
        onCloseDrawer={handleCloseModal}

        // Props Filter
        titleSelect1="Provincia"
        titleSelect2="Municipio"
        titleSelect3="Sector"
        regionTypeOptions1={regionOptions1}
        treeData={treeData}

        filterStatus={filterStatus}
        searchFilter={searchFilter}
        onCloseSearch={onCloseSearch}

        list={list}
        modalTitle={itemModal?.row?.Name}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        clickView={clickView}
        onSubmit={handleDelete}
        onClickContextMenu={onClickContextMenu}
        page={page}
        limit={limit}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        countPagination={countPagination}
      />
    </React.Fragment>
  );
};

export default RegionAdmin;