import React, { useEffect, useState } from "react";
import { ProductCreationView } from "./view";
import {
  ColumnsActionsFlags,
  ColumnsPaymentMode,
  ColumnsPaymentRecurrence,
  ColumnsPlans,
  dataForm,
  dataProductCreationForm,
} from "./productCreationsDTO";
import { GetInsurancesTypes } from "../../../fnx/insurances/getInsurancesTypes";
import { useDispatch, useSelector } from "react-redux";
import LocalStorageService from "../../../services/localStorage";
import {
  DataFormClient,
  LoadingAFY,
  position,
  select,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {
  RowsModel,
  StatusOptionsModel,
} from "../../../model/insurancePoliciesSummary";
import { sponsor } from "../../../store/sponsor/actions";
import {
  projectOneClearData,
  projectPatch,
  projectPatchClearData,
  projectPost,
  projectPostClearData,
} from "../../../store/project/actions";
import { hasPermission } from "../../../services/getNavigationPages";
import {
  campaignOneClearData,
  campaignPatch,
  campaignPatchClearData,
  campaignPost,
  campaignPostClearData,
} from "../../../store/campaign/actions";
import { showErrorToast } from "../../../fnx/showError";
import showToast from "../../../services/toast";
import { themeCore } from "../../../assets/themes/theme";
import { showToastSuccessMessage } from "../../../fnx/sendSuccessMessage";
import { useNavigate, useParams } from "react-router-dom";
import {
  paymentModeAction,
  paymentModeClearData,
  postPaymentMode,
  postPaymentModeClearData,
} from "../../../store/paymentModes/action";
import { GetCatalogPaymentMode } from "../../../fnx/catalog/getCatalogPaymentMode";
import { GetCatalogAccountType } from "../../../fnx/catalog/getCatalogAccountType";
import {
  accountType,
  accountTypeClearData,
  accountTypePost,
  accountTypePostClearData,
} from "../../../store/accountType/actions";
import {
  campaignProjectPost,
  campaignProjectPostClearData,
} from "../../../store/campaignProject/actions";

import { useTranslation } from "react-i18next";
import { GetProjectOne } from "../../../fnx/project/projectOne";
import {
  getCatalogAccountTypeClearData,
  getCatalogPaymentModeClearData,
} from "../../../store/catalog/actions";
import { GetCampaignOne } from "../../../fnx/campaign/getCampaignOne";
import { formattedDateYYMMDD } from "../../../utils/date";
import { GetCurrencies } from "../../../fnx/currencies/currencies";
import { useGetPlansAll } from "../../../fnx/plans/plansGetAll";
import { plansClearData } from "../../../store/plans/actions";
import { GetActionFlags } from "../../../fnx/actionsFlags/getActionFlags";
import {
  deleteEntity,
  deleteEntityClearData,
} from "../../../store/delete/actions";
import { PostActionFlags } from "../../../fnx/actionsFlags/postActionFlags";
import { clearDataPutAvatar, putAvatar } from "../../../store/avatar/actions";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const ProductCreation = () => {
  const { t } = useTranslation("global");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { idProduct } = useParams();
  const { isEdit } = useParams();
  const { plans } = useParams();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit] = React.useState<number>(1000);
  const [orderBy] = React.useState<string>("name");
  const [orderDir] = React.useState<string>("ASC");
  const [trigger, setTrigger] = useState(false);
  const [triggerActionF, setTriggerActionF] = useState(false);
  const [triggerPlans, setTriggerPlans] = useState(false);
  const [triggerDetails, setTriggerDetails] = useState(false);
  const [triggerCampaign, setTriggerCampaign] = useState(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [disableCampaign, setDisableCampaign] = React.useState(true);
  const [disableRecurrence, setDisableRecurrence] = React.useState(true);
  const [disablePaymentMode, setDisablePaymentMode] = React.useState(true);
  const [disablePlans, setDisablePlans] = React.useState(true);
  const [disableFlags, setDisableFlags] = React.useState(true);
  const [activateEditProduct, setActivateEditProduct] =
    React.useState<boolean>(true);
  const [activateEditCampaign, setActivateEditCampaign] =
    React.useState<boolean>(true);
  const [showCancelButton, setShowCancelButton] =
    React.useState<boolean>(false);
  const [showAddButtonRecurrence, setShowAddButtonRecurrence] =
    React.useState<boolean>(true);
  const [showAddButtonPaymentMode, setShowAddButtonPaymentMode] =
    React.useState<boolean>(true);
  const [showAddButtonPlans, setShowAddButtonPlans] =
    React.useState<boolean>(true);
  const [showButtonsActions, setShowButtonsActions] =
    React.useState<boolean>(true);
  const [showButtonAddCampaign, setShowButtonAddCampaign] =
    React.useState(true);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [insuranceTypeList, setInsuranceTypeList] = React.useState<select[]>(
    [],
  );
  const [sponsorList, setSponsorList] = React.useState<select[]>([]);
  const [currencyList, setCurrencyList] = React.useState<select[]>([]);
  const [paymentModeList, setPaymentModeList] = React.useState<select[]>([]);
  const [accountTypeList, setAccountTypeList] = React.useState<select[]>([]);
  const [dataProductCreation, setDataProductCreation] =
    useState<DataFormClient>(dataProductCreationForm);
  const [dataCampaignCreation, setDataCampaignCreation] =
    useState<DataFormClient>(dataForm);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<string>("");
  const [campaignId, setCampaignId] = useState<string>("");
  const [titleDelete, setTitleDelete] = React.useState("");
  const [subTitleDelete, setSubTitleDelete] = React.useState("");
  const [recurrenceId, setRecurrenceId] = React.useState("");
  const [paymentModeId, setPaymentModeId] = React.useState("");
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [descriptionAlert, setDescriptionAlert] = useState<string>("");
  const [panelActive, setPanelActive] = React.useState<number>(0);
  const [autoClose] = React.useState<number>(10000);
  const [autoCloseActive] = React.useState<boolean>(false);
  const [rowsPaymentRecurrence, setRowsPaymentRecurrence] = React.useState<any>(
    [],
  );
  const [rowsAccountType, setRowsAccountType] = React.useState<any>([]);
  const [rowsPlans, setRowsPlans] = React.useState<any>([]);
  const [rowsFlags, setRowsFlags] = React.useState<any>([]);
  const [uploadedImage, setUploadedImage] = React.useState<File | null>();
  const [showUploadButton, setShowUploadButton] = React.useState<boolean>(true);
  const [imgProject, setImgProject] = React.useState<string>("");
  const [openDrawerPaymentR, setOpenDrawerPaymentR] =
    React.useState<boolean>(false);
  const [openDrawerPaymentM, setOpenDrawerPaymentM] =
    React.useState<boolean>(false);

  const { responseInsuranceType, errorInsuranceType, loadingInsuranceType } =
    GetInsurancesTypes(trigger, enable, page, limit, clientId);

  const { responseOneProject, errorOneProject, loadingOneProject } =
    GetProjectOne(triggerDetails, idProduct);

  const { responseGetOneCampaign, errorGetOneCampaign, loadingGetOneCampaign } =
    GetCampaignOne(triggerCampaign, campaignId);

  const { responseCurrencies, errorCurrencies, loadingCurrencies } =
    GetCurrencies(trigger, enable, orderBy, orderDir, page, limit);

  const {
    responsePageActionFlags,
    errorPageActionFlags,
    loadingPageActionFlags,
  } = GetActionFlags(
    trigger,
    enable,
    page,
    limit,
    projectId ? projectId : idProduct || "",
  );

  const {
    responsePostActionFlags,
    errorPostActionFlags,
    loadingPostActionFlags,
  } = PostActionFlags(triggerActionF, projectId ? projectId : idProduct || "");

  const {
    responseGetCatalogPaymentMode,
    errorGetCatalogPaymentMode,
    loadingGetCatalogPaymentMode,
  } = GetCatalogPaymentMode(trigger, enable, page, limit, clientId);

  const { responsePlans, errorPlans, loadingPlans } = useGetPlansAll(
    triggerPlans,
    clientId,
    projectId || idProduct,
  );

  const {
    responseGetCatalogAccountType,
    errorGetCatalogAccountType,
    loadingGetCatalogAccountType,
  } = GetCatalogAccountType(trigger, enable, page, limit, clientId);

  const { responseSponsor, errorSponsor, loadingSponsor } = useSelector(
    (state: any) => ({
      responseSponsor: state.Sponsor.responseSponsor?.data,
      errorSponsor: state.Sponsor.errorSponsor,
      loadingSponsor: state.Sponsor.loadingSponsor,
    }),
  );

  const { responseProjectPost, errorProjectPost, loadingProjectPost } =
    useSelector((state: any) => ({
      responseProjectPost: state.Project.responseProjectPost,
      errorProjectPost: state.Project.errorProjectPost,
      loadingProjectPost: state.Project.loadingProjectPost,
    }));

  const { responseProjectPatch, errorProjectPatch, loadingProjectPatch } =
    useSelector((state: any) => ({
      responseProjectPatch: state.Project.responseProjectPatch?.data,
      errorProjectPatch: state.Project.errorProjectPatch,
      loadingProjectPatch: state.Project.loadingProjectPatch,
    }));

  const { responseCampaignPost, errorPostCampaign, loadingPostCampaign } =
    useSelector((state: any) => ({
      responseCampaignPost: state.Campaign.responseCampaignPost,
      errorPostCampaign: state.Campaign.errorPostCampaign,
      loadingPostCampaign: state.Campaign.loadingPostCampaign,
    }));

  const { responseCampaignPatch, errorCampaignPatch, loadingCampaignPatch } =
    useSelector((state: any) => ({
      responseCampaignPatch: state.Campaign.responseCampaignPatch?.data,
      errorCampaignPatch: state.Campaign.errorCampaignPatch,
      loadingCampaignPatch: state.Campaign.loadingCampaignPatch,
    }));

  const {
    responseCampaignProjectPost,
    errorPostCampaignProject,
    loadingPostCampaignProject,
  } = useSelector((state: any) => ({
    responseCampaignProjectPost:
      state.CampaignProject.responseCampaignProjectPost,
    errorPostCampaignProject: state.CampaignProject.errorPostCampaignProject,
    loadingPostCampaignProject:
      state.CampaignProject.loadingPostCampaignProject,
  }));

  const {
    responsePostPaymentMode,
    errorPostPaymentMode,
    loadingPostPaymentMode,
  } = useSelector((state: any) => ({
    responsePostPaymentMode: state.PaymentMode.responsePostPaymentMode,
    errorPostPaymentMode: state.PaymentMode.errorPostPaymentMode,
    loadingPostPaymentMode: state.PaymentMode.loadingPostPaymentMode,
  }));

  const permissions = {
    create: true,
    read: true ,
    update: true,
    delete: true,
  };
  const {
    responseAccountTypePost,
    errorAccountTypePost,
    loadingAccountTypePost,
  } = useSelector((state: any) => ({
    responseAccountTypePost: state.AccountType.responseAccountTypePost,
    errorAccountTypePost: state.AccountType.errorAccountTypePost,
    loadingAccountTypePost: state.AccountType.loadingAccountTypePost,
  }));

  const { responseAccountType, errorAccountType, loadingAccountType } =
    useSelector((state: any) => ({
      responseAccountType: state.AccountType.responseAccountType?.data,
      errorAccountType: state.AccountType.errorAccountType,
      loadingAccountType: state.AccountType.loadingAccountType,
    }));

  const { responsePaymentMode, errorPaymentMode, loadingPaymentMode } =
    useSelector((state: any) => ({
      responsePaymentMode: state.PaymentMode.responsePaymentMode?.data,
      errorPaymentMode: state.PaymentMode.errorPaymentMode,
      loadingPaymentMode: state.PaymentMode.loadingPaymentMode,
    }));

  const { responseDeleteEntity, errorDeleteEntity, loadingDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
    }));

  const { responsePutAvatar, errorPutAvatar, loadingPutAvatar } = useSelector(
    (state: any) => ({
      responsePutAvatar: state.Avatar.responsePutAvatar,
      errorPutAvatar: state.Avatar.errorPutAvatar,
      loadingPutAvatar: state.Avatar.loadingPutAvatar,
    }),
  );

  useEffect(() => {
    setTrigger(true);
    dispatch(
      sponsor({ enable: enable, page: page, limit: limit, clientId: clientId }),
    );
    dispatch(paymentModeClearData());
    dispatch(accountTypeClearData());
  }, []);

  useEffect(() => {
    if (idProduct && isEdit !== "true") {
      setDisableCampaign(false);
      setDisablePaymentMode(false);
      setDisableRecurrence(false);
      setDisablePlans(false);
      setDisableFlags(false);
      setActivateEditProduct(false);
      setActivateEditCampaign(false);
      setShowButtonsActions(false);
      setShowButtonAddCampaign(false);
      setShowAddButtonRecurrence(false);
      setShowAddButtonPaymentMode(false);
      setShowAddButtonPlans(false);
      setIsUpdate(false);
      setTriggerDetails(true);
      callDispatchPaymentMode();
      callDispatchAccountType();
      setTriggerPlans(true);
      setShowUploadButton(false);
    }
    if (idProduct && isEdit === "true") {
      setDisableCampaign(false);
      setDisablePaymentMode(false);
      setDisableRecurrence(false);
      setDisablePlans(false);
      setDisableFlags(false);
      setActivateEditProduct(false);
      setActivateEditCampaign(false);
      setShowAddButtonRecurrence(true);
      setShowAddButtonPaymentMode(true);
      setTriggerDetails(true);
      setShowCancelButton(true);
      callDispatchPaymentMode();
      callDispatchAccountType();
      setIsUpdate(true);
      setShowAddButtonPlans(true);
      setTriggerPlans(true);
    }
    if (idProduct && isEdit === "true" && plans === "true") {
      setPanelActive(3);
    }
  }, [idProduct, isEdit, plans]);

  useEffect(() => {
    if (errorGetCatalogPaymentMode) {
      showErrorToast(errorGetCatalogPaymentMode, setSeeToast, t);
    }
  }, [errorGetCatalogPaymentMode]);

  useEffect(() => {
    if (errorOneProject) {
      showErrorToast(errorOneProject, setSeeToast, t);
    }
  }, [errorOneProject]);

  useEffect(() => {
    if (errorPutAvatar) {
      showErrorToast(errorPutAvatar, setSeeToast, t);
    }
  }, [errorPutAvatar]);

  useEffect(() => {
    if (errorGetOneCampaign) {
      showErrorToast(errorGetOneCampaign, setSeeToast, t);
      dispatch(campaignOneClearData());
    }
  }, [errorGetOneCampaign]);

  useEffect(() => {
    if (errorGetOneCampaign) {
      showErrorToast(errorGetOneCampaign, setSeeToast, t);
      dispatch(campaignOneClearData());
    }
  }, [errorGetOneCampaign]);

  useEffect(() => {
    if (errorPaymentMode) {
      showErrorToast(errorPaymentMode, setSeeToast, t);
    }
  }, [errorPaymentMode]);

  useEffect(() => {
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
    }
  }, [errorPlans]);

  useEffect(() => {
    if (errorPostPaymentMode) {
      showErrorToast(errorPostPaymentMode, setSeeToast, t);
    }
  }, [errorPostPaymentMode]);

  useEffect(() => {
    if (errorGetCatalogAccountType) {
      showErrorToast(errorGetCatalogAccountType, setSeeToast, t);
    }
  }, [errorGetCatalogAccountType]);

  useEffect(() => {
    if (errorSponsor) {
      showErrorToast(errorSponsor, setSeeToast, t);
    }
  }, [errorSponsor]);

  useEffect(() => {
    if (errorCurrencies) {
      showErrorToast(errorCurrencies, setSeeToast, t);
    }
  }, [errorCurrencies]);

  useEffect(() => {
    if (errorPostCampaignProject) {
      showErrorToast(errorPostCampaignProject, setSeeToast, t);
    }
  }, [errorPostCampaignProject]);

  useEffect(() => {
    if (errorCampaignPatch) {
      showErrorToast(errorCampaignPatch, setSeeToast, t);
    }
  }, [errorCampaignPatch]);

  useEffect(() => {
    if (errorInsuranceType) {
      showErrorToast(errorInsuranceType, setSeeToast, t);
    }
  }, [errorInsuranceType]);

  useEffect(() => {
    if (errorProjectPost) {
      showErrorToast(errorProjectPost, setSeeToast, t);
    }
  }, [errorProjectPost]);

  useEffect(() => {
    if (errorAccountTypePost) {
      showErrorToast(errorAccountTypePost, setSeeToast, t);
    }
  }, [errorAccountTypePost]);

  useEffect(() => {
    if (errorAccountType) {
      showErrorToast(errorAccountType, setSeeToast, t);
    }
  }, [errorAccountType]);

  useEffect(() => {
    if (errorPostCampaign) {
      showErrorToast(errorPostCampaign, setSeeToast, t);
    }
  }, [errorPostCampaign]);

  useEffect(() => {
    if (responseInsuranceType?.length > 0) {
      const newInsurancesTypeList: select[] = responseInsuranceType.map(
        (item: StatusOptionsModel) => ({
          id: item.id,
          name: item.description,
          value: item.id,
        }),
      );
      setInsuranceTypeList(newInsurancesTypeList);
    }
  }, [responseInsuranceType]);

  useEffect(() => {
    if (responseCurrencies?.length > 0) {
      const catalogType: select[] = responseCurrencies.map(
        (item: StatusOptionsModel) => ({
          id: item.id,
          name: item.name,
          value: item.id,
        }),
      );
      setCurrencyList(catalogType);
    }
  }, [responseCurrencies]);

  useEffect(() => {
    if (responseSponsor?.length > 0) {
      const newSponsorList: select[] = responseSponsor.map(
        (item: StatusOptionsModel) => ({
          id: item.id,
          name: item.name,
          value: item.id,
        }),
      );
      setSponsorList(newSponsorList);
    }
  }, [responseSponsor]);

  useEffect(() => {
    if (responseGetCatalogPaymentMode?.length > 0) {
      const paymentRecurrence = responseGetCatalogPaymentMode.map(
        (item: StatusOptionsModel) => ({
          id: item.id,
          name: item.description,
          value: item.id,
        }),
      );
      setPaymentModeList(paymentRecurrence);
      dispatch(getCatalogPaymentModeClearData());
    }
  }, [responseGetCatalogPaymentMode]);

  useEffect(() => {
    if (responseGetCatalogAccountType?.length > 0) {
      const accountType = responseGetCatalogAccountType.map(
        (item: StatusOptionsModel) => ({
          id: item.id,
          name: item.description,
          value: item.id,
        }),
      );
      setAccountTypeList(accountType);
      dispatch(getCatalogAccountTypeClearData());
    }
  }, [responseGetCatalogAccountType, responseAccountType]);

  useEffect(() => {
    if (responseProjectPost && responseProjectPost?.data) {
      setShowAlert(true);
      setDescriptionAlert("Para continuar, ahora cree una campaña");
      setDisableCampaign(false);
      showToastSuccessMessage(
        "Producto creado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      if (uploadedImage) {
        dispatch(
          putAvatar("Project", responseProjectPost.data.id, uploadedImage),
        );
        setUploadedImage(null);
      }
      setProjectId(responseProjectPost.data.id);
      setTriggerPlans(true);
      dispatch(projectPostClearData());
    }
  }, [responseProjectPost]);

  useEffect(() => {
    if (projectId) {
      setTriggerActionF(true);
    }
  }, [projectId]);

  useEffect(() => {
    if (responsePutAvatar && responsePutAvatar?.url) {
      showToastSuccessMessage(
        "Producto actualizado con éxito.",
        typeAlert.success,
        setSeeToast,
      );

      dispatch(clearDataPutAvatar());
    }
  }, [responsePutAvatar]);

  useEffect(() => {
    if (responseProjectPatch) {
      showToastSuccessMessage(
        "Producto actualizado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(projectPatchClearData());
      if (uploadedImage) {
        dispatch(
          putAvatar("Project", responseProjectPatch?.data?.id, uploadedImage),
        );
        setUploadedImage(null);
      }
    }
  }, [responseProjectPatch]);

  useEffect(() => {
    if (responseCampaignPost && responseCampaignPost?.data) {
      setShowAlert(true);
      setActivateEditProduct(false);
      setActivateEditCampaign(false);
      setDescriptionAlert(
        "Para continuar, ahora Agregue una recurrencia de pago",
      );
      showToastSuccessMessage(
        "Campaña creada con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setDisableRecurrence(false);
      setPanelActive(1);

      let newCampaignProjectData = {
        status: 1,
        projectId: projectId ? projectId : idProduct,
        campaignId: responseCampaignPost?.data?.id,
      };
      setCampaignId(responseCampaignPost?.data?.id);
      dispatch(campaignProjectPost(newCampaignProjectData));
      dispatch(campaignProjectPostClearData());
      dispatch(campaignPostClearData());
    }
  }, [responseCampaignPost]);

  useEffect(() => {
    if (responseCampaignPatch) {
      showToastSuccessMessage(
        "Campaña actualizada con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      dispatch(campaignPatchClearData());
    }
  }, [responseCampaignPatch]);

  useEffect(() => {
    if (responsePostPaymentMode && responsePostPaymentMode?.data) {
      callDispatchPaymentMode();
      setShowAlert(true);
      setDescriptionAlert("Para continuar, ahora Agregue un modo de pago");
      showToastSuccessMessage(
        "Recurrencia de pago agregada con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setOpenDrawerPaymentR(false);
      setDisablePaymentMode(false);
      dispatch(postPaymentModeClearData());
    }
  }, [responsePostPaymentMode]);

  useEffect(() => {
    if (responseAccountTypePost && responseAccountTypePost?.data) {
      callDispatchAccountType();
      setShowAddButtonPlans(true);
      setShowAlert(true);
      setDescriptionAlert("Para continuar, ahora Agregue los planes");
      showToastSuccessMessage(
        "Modo de pago agregado con éxito.",
        typeAlert.success,
        setSeeToast,
      );
      setOpenDrawerPaymentM(false);
      setDisablePlans(false);
      dispatch(accountTypePostClearData());
    }
  }, [responseAccountTypePost]);

  useEffect(() => {
    if (Array.isArray(responsePaymentMode)) {
      setPaymentRecurrenceStorybook(responsePaymentMode);
      if (responsePaymentMode?.length === 0) {
        setDisableRecurrence(false);
        setShowAddButtonRecurrence(true);
        setShowAddButtonPaymentMode(true);
      }
      if (responsePaymentMode?.length > 0) {
        const idsToRemove = responsePaymentMode.map(
          (item) => item.catalogPaymentMode.id,
        );
        const updatedList = paymentModeList.filter(
          (item) => !idsToRemove.includes(item.id),
        );
        setPaymentModeList(updatedList);
      }
    }
  }, [responsePaymentMode]);

  useEffect(() => {
    if (Array.isArray(responseAccountType)) {
      setAccountTypeStorybook(responseAccountType);
      if (responseAccountType?.length === 0) {
        setShowAddButtonPaymentMode(true);
      }
      if (responseAccountType?.length > 0) {
        const idsToRemove = responseAccountType.map(
          (item) => item.catalogAccountType.id,
        );
        const updatedList = accountTypeList.filter(
          (item) => !idsToRemove.includes(item.id),
        );
        setAccountTypeList(updatedList);
      }
    }
  }, [responseAccountType]);

  useEffect(() => {
    if (Array.isArray(responsePlans)) {
      setPlansStorybook(responsePlans);
    }
    if (responsePlans?.length > 0) {
      setDisableFlags(false);
    }
    if (responsePlans?.length > 0) {
      setDisableFlags(false);
    }
  }, [responsePlans]);

  useEffect(() => {
    if (Array.isArray(responsePageActionFlags)) {
      setActionFlagsStorybook(responsePageActionFlags);
    }
  }, [responsePageActionFlags]);

  useEffect(() => {
    if (responseOneProject) {
      setDataProductCreation({
        IdSponsor: responseOneProject?.sponsorId,
        IdTypeInsurance: responseOneProject?.projectTypeId,
        Description: responseOneProject.description,
        Code: responseOneProject?.code,
        IdCurrency: responseOneProject?.currencyId,
        CertifyNumber: responseOneProject?.certId,
        QuotesNumber: responseOneProject?.countPolicy,
        PolicyNumber: responseOneProject?.countQuote,
      });
      setImgProject(responseOneProject.logoUrl);
      if (responseOneProject.campaign.length > 0) {
        setCampaignId(responseOneProject.campaign[0].id);
        setTriggerCampaign(true);
      }
      dispatch(projectOneClearData());
      if (responseOneProject?.campaign?.length === 0) {
        setIsUpdate(false);
        setActivateEditCampaign(true);
        setShowButtonAddCampaign(true);
        setShowAddButtonPaymentMode(true);
        setShowAddButtonRecurrence(true);
      }
    }
  }, [responseOneProject]);

  useEffect(() => {
    if (responseGetOneCampaign) {
      setDataCampaignCreation({
        CampaignName: responseGetOneCampaign.name,
        CodeCampaign: responseGetOneCampaign.code,
        StartDateCampaign: formattedDateYYMMDD(
          responseGetOneCampaign.startDate,
        ),
        EndDateCampaign: formattedDateYYMMDD(responseGetOneCampaign.endDate),
      });
      dispatch(campaignOneClearData());
    }
  }, [responseGetOneCampaign]);

  useEffect(() => {
    if (responseDeleteEntity) {
      callDispatchPaymentMode();
      callDispatchAccountType();
    }
    dispatch(deleteEntityClearData());
  }, [responseDeleteEntity]);

  const callDispatchPaymentMode = () => {
    if (projectId || idProduct) {
      dispatch(
        paymentModeAction({
          enable: enable,
          page,
          limit,
          projectId: projectId || idProduct,
          deleted: false,
        }),
      );
    }
  };

  const callDispatchAccountType = () => {
    if (projectId || idProduct) {
      dispatch(
        accountType({
          enable: enable,
          page,
          limit,
          clientId,
          projectId: projectId || idProduct,
          deleted: false,
        }),
      );
    }
  };

  const setPaymentRecurrenceStorybook = (responsePaymentMode: RowsModel[]) => {
    const recurrenceRows = responsePaymentMode?.map((item: RowsModel) => ({
      id: item.id,
      code: item.catalogPaymentMode.code,
      description: item.catalogPaymentMode.description,
    }));
    setRowsPaymentRecurrence(recurrenceRows);
    dispatch(paymentModeClearData());
  };

  const setAccountTypeStorybook = (responseAccountType: RowsModel[]) => {
    const accountTypeRows = responseAccountType?.map((item: RowsModel) => ({
      id: item.id,
      code: item.catalogAccountType.code,
      description: item.catalogAccountType.description,
    }));
    setRowsAccountType(accountTypeRows);
    dispatch(accountTypeClearData());
  };

  const setPlansStorybook = (responsePlans: RowsModel[]) => {
    const plansRows = responsePlans.map((item: RowsModel) => {
      const coverTypeDescriptions = item.coverTypes
        .map((type) => type.description)
        .join(", ");
      return {
        id: item.id,
        code: item.code,
        name: item.name,
        coverType: coverTypeDescriptions,
      };
    });

    setRowsPlans(plansRows);
    dispatch(plansClearData());
  };

  const setActionFlagsStorybook = (responsePageActionFlags: RowsModel[]) => {
    const actionFlagsRows = responsePageActionFlags.map((item: RowsModel) => {
      return {
        id: item.id,
        value: item.value === "true" ? "Activo" : "Inactivo",
        name: item.name,
      };
    });
    setRowsFlags(actionFlagsRows);
    dispatch(plansClearData());
  };

  const handleSubmitProduct = (dataProductCreation: any, fileImg: any) => {
    if (isUpdate) {
      let projectData = {
        code: dataProductCreation.Code,
        description: dataProductCreation.Description,
        certId: dataProductCreation.CertifyNumber,
        countQuote: dataProductCreation.QuotesNumber,
        countPolicy: dataProductCreation.PolicyNumber,
        sponsorId: dataProductCreation.IdSponsor,
        projectTypeId: dataProductCreation.IdTypeInsurance,
        currencyId: dataProductCreation.IdCurrency,
      };
      dispatch(projectPatch(projectData, projectId ? projectId : idProduct));
      setUploadedImage(fileImg);
    } else {
      let projectData = {
        code: dataProductCreation.Code,
        description: dataProductCreation.Description,
        certId: dataProductCreation.CertifyNumber,
        countQuote: parseInt(dataProductCreation.QuotesNumber),
        countPolicy: parseInt(dataProductCreation.PolicyNumber),
        sponsorId: dataProductCreation.IdSponsor,
        projectTypeId: dataProductCreation.IdTypeInsurance,
        currencyId: dataProductCreation.IdCurrency,
      };
      setDataProductCreation(dataProductCreation);
      dispatch(projectPost(projectData));
      setUploadedImage(fileImg);
    }
  };

  const handleSubmitCampaign = (dataCampaign: any) => {
    if (isUpdate) {
      let newCampaignData = {
        name: dataCampaign.CampaignName,
        code: dataCampaign.CodeCampaign,
        startDate: dataCampaign.StartDateCampaign,
        endDate: dataCampaign.EndDateCampaign,
      };
      dispatch(campaignPatch(newCampaignData, campaignId));
    } else {
      let newCampaignData = {
        name: dataCampaign.CampaignName,
        code: dataCampaign.CodeCampaign,
        startDate: dataCampaign.StartDateCampaign,
        endDate: dataCampaign.EndDateCampaign,
      };
      setDataCampaignCreation(dataCampaign);
      dispatch(campaignPost(newCampaignData));
    }
  };

  const handleSubmitPaymentRecurrence = (PaymentRecurrence: any) => {
    let paymentRecurrenceData = {
      projectId: projectId ? projectId : idProduct,
      catalogPaymentModeId: PaymentRecurrence.paymentRecurrence
        ? PaymentRecurrence.paymentRecurrence
        : "",
    };
    dispatch(postPaymentMode(paymentRecurrenceData));
    dispatch(postPaymentModeClearData());
  };

  const handleSubmitAccountType = (PaymentModeId: any) => {
    let paymentModeData = {
      projectId: projectId ? projectId : idProduct,
      catalogAccountTypeId: PaymentModeId.paymentMode
        ? PaymentModeId.paymentMode
        : "",
    };
    dispatch(accountTypePost(paymentModeData));
    dispatch(accountTypePostClearData());
  };

  const onClickBack = () => {
    navigate("/insurances-admin");
  };

  const onClickAddPlans = () => {
    const id = projectId ? projectId : idProduct;
    navigate(`/plan-creation/${id}`);
  };

  const clickContextMenuPlans = (item: RowsModel) => {
    const id = projectId ? projectId : idProduct;
    const idPlan = item.id;
    navigate(`/plan-creation/${id}/${idPlan}`);
  };

  const clickContextMenuRecurrence = (item: RowsModel) => {
    setOpenModal(true);
    setTitleDelete("recurrencia de pago");
    setSubTitleDelete(
      `la recurrencia de pago ${item.description?.toLowerCase()}`,
    );
    setRecurrenceId(item.id ? item.id : "");
  };

  const deleteRecurrence = () => {
    setOpenModal(false);
    dispatch(
      deleteEntity({
        id: recurrenceId,
        entity: "PaymentModeProjectEntity",
        idUser: lsLogin.getUserLoginStored().userId,
        body: {
          force: true,
        },
      }),
    );
  };

  const clickContextMenuPaymentMode = (item: RowsModel) => {
    setOpenModal(true);
    setTitleDelete("modo de pago");
    setSubTitleDelete(`el modo de pago ${item.description?.toLowerCase()}`);
    setPaymentModeId(item.id ? item.id : "");
  };

  const deletePaymentMode = () => {
    setOpenModal(false);
    dispatch(
      deleteEntity({
        id: paymentModeId,
        entity: "AccountTypeProjectEntity",
        idUser: lsLogin.getUserLoginStored().userId,
        body: {
          force: true,
        },
      }),
    );
  };

  const columnsRecurrence = ColumnsPaymentRecurrence(
    clickContextMenuRecurrence,
  );
  const columnsPaymentMode = ColumnsPaymentMode(clickContextMenuPaymentMode);

  const columnsPlans = ColumnsPlans(clickContextMenuPlans);

  const ColumnsFlags = ColumnsActionsFlags();

  return (
    <>
      {seeToast.show &&
        showToast(position.bottomRight, seeToast.type, seeToast.message)}
      <LoadingAFY
        loading={
          loadingInsuranceType ||
          loadingSponsor ||
          loadingCurrencies ||
          loadingProjectPost ||
          loadingPostCampaign ||
          loadingPostPaymentMode ||
          loadingAccountTypePost ||
          loadingOneProject ||
          loadingProjectPatch ||
          loadingCampaignPatch ||
          loadingDeleteEntity
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={
          loadingProjectPatch || loadingCampaignPatch
            ? "Actualizando"
            : "Cargando..."
        }
        bgColor={themeCore.colors.backgroundLoader}
      />
      <ProductCreationView
      permissions={permissions}
        disableCampaign={disableCampaign}
        disableRecurrence={disableRecurrence}
        disablePaymentMode={disablePaymentMode}
        disablePlans={disablePlans}
        disableFlags={disableFlags}
        insuranceTypeList={insuranceTypeList}
        dataProductCreation={dataProductCreation}
        sponsorList={sponsorList}
        handleSubmitProduct={handleSubmitProduct}
        currencyList={currencyList}
        showAlert={showAlert}
        descriptionAlert={descriptionAlert}
        dataCampaignCreation={dataCampaignCreation}
        setDescriptionAlert={setDescriptionAlert}
        handleSubmitCampaign={handleSubmitCampaign}
        panelActive={panelActive}
        autoClose={autoClose}
        autoCloseActive={autoCloseActive}
        setShowAlert={setShowAlert}
        paymentModeList={paymentModeList}
        accountTypeList={accountTypeList}
        handleSubmitPaymentRecurrence={handleSubmitPaymentRecurrence}
        handleSubmitAccountType={handleSubmitAccountType}
        rowsPaymentRecurrence={rowsPaymentRecurrence}
        rowsAccountType={rowsAccountType}
        rowsPlans={rowsPlans}
        activateEditProduct={activateEditProduct}
        activateEditCampaign={activateEditCampaign}
        showButtonsActions={showButtonsActions}
        onClickBack={onClickBack}
        showButtonAddRecurrence={showAddButtonRecurrence}
        showButtonAddPaymentMode={showAddButtonPaymentMode}
        showAddButtonPlans={showAddButtonPlans}
        idProduct={idProduct}
        isEdit={isEdit}
        showCancelButton={showCancelButton}
        setIsUpdate={setIsUpdate}
        setShowCancelButton={setShowCancelButton}
        showButtonAddCampaign={showButtonAddCampaign}
        onClickAddPlans={onClickAddPlans}
        columnsRecurrence={columnsRecurrence}
        columnsPaymentMode={columnsPaymentMode}
        openModal={openModal}
        setOpenModal={setOpenModal}
        titleDelete={titleDelete}
        subTitleDelete={subTitleDelete}
        deleteRecurrence={deleteRecurrence}
        deletePaymentMode={deletePaymentMode}
        columnsPlans={columnsPlans}
        ColumnsFlags={ColumnsFlags}
        rowsFlags={rowsFlags}
        showUploadButton={showUploadButton}
        imgProject={imgProject}
        openDrawerPaymentR={openDrawerPaymentR}
        setOpenDrawerPaymentR={setOpenDrawerPaymentR}
        openDrawerPaymentM={openDrawerPaymentM}
        setOpenDrawerPaymentM={setOpenDrawerPaymentM}
      />
    </>
  );
};
