import { call, put, takeEvery } from "redux-saga/effects";
import {
  CATALOG_PAYMENT_MODE,
  GET_CATALOG_ACCOUNT_TYPE,
  GET_CATALOG_BY_ID,
  GET_CATALOG_STATUS_QUOTE,
  GET_CATALOGS_TYPE,
} from "./actionTypes";
import {
  getCatalogAccountTypeError,
  getCatalogAccountTypeSuccess,
  getCatalogByIdError,
  getCatalogByIdSuccess,
  getCatalogPaymentModeError,
  getCatalogPaymentModeSuccess,
  getCatalogStatusQuoteError,
  getCatalogStatusQuoteSuccess,
  getCatalogsTypeError,
  getCatalogsTypeSuccess,
} from "./actions";
import {
  getCatalogByIdApi,
  getCatalogsApi,
  getStatusQuoteCatalog,
} from "../../resources/helper/catalog";

function* getCatalogById({ payload: { getCatalogById } }) {
  try {
    const response = yield call(getCatalogByIdApi, getCatalogById.id);
    yield put(getCatalogByIdSuccess(response));
  } catch (error) {
    yield put(getCatalogByIdError(error));
  }
}

function* catalogStatusQuote({ payload: { catalogStatusQuote } }) {
  try {
    const response = yield call(
      getStatusQuoteCatalog,
      catalogStatusQuote.enable,
      catalogStatusQuote.page,
      catalogStatusQuote.limit,
    );
    yield put(getCatalogStatusQuoteSuccess(response));
  } catch (error) {
    yield put(getCatalogStatusQuoteError(error));
  }
}

function* getCatalogsType({ payload: { catalogsType } }) {
  try {
    const response = yield call(
      getCatalogsApi,
      catalogsType.enable,
      catalogsType.page,
      catalogsType.limit,
      catalogsType.type,
      catalogsType.clientId,
    );
    yield put(getCatalogsTypeSuccess(response));
  } catch (error) {
    yield put(getCatalogsTypeError(error));
  }
}

function* getCatalogPaymentMode({ payload: { catalogPaymentMode } }) {
  try {
    const response = yield call(
      getCatalogsApi,
      catalogPaymentMode.enable,
      catalogPaymentMode.page,
      catalogPaymentMode.limit,
      catalogPaymentMode.type,
      catalogPaymentMode.clientId,
    );
    yield put(getCatalogPaymentModeSuccess(response));
  } catch (error) {
    yield put(getCatalogPaymentModeError(error));
  }
}

function* getCatalogAccountType({ payload: { catalogAccountType } }) {
  try {
    const response = yield call(
      getCatalogsApi,
      catalogAccountType.enable,
      catalogAccountType.page,
      catalogAccountType.limit,
      catalogAccountType.type,
      catalogAccountType.clientId,
    );
    yield put(getCatalogAccountTypeSuccess(response));
  } catch (error) {
    yield put(getCatalogAccountTypeError(error));
  }
}

function* CatalogSaga() {
  yield takeEvery(GET_CATALOG_BY_ID, getCatalogById);
  yield takeEvery(GET_CATALOG_STATUS_QUOTE, catalogStatusQuote);
  yield takeEvery(GET_CATALOGS_TYPE, getCatalogsType);
  yield takeEvery(CATALOG_PAYMENT_MODE, getCatalogPaymentMode);
  yield takeEvery(GET_CATALOG_ACCOUNT_TYPE, getCatalogAccountType);
}

export default CatalogSaga;
