import {icons} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {NavigateStructure} from "../interface/nav";

export const GetMenuSideBarHome = () => {
  const m: NavigateStructure[] = [
    {
      id: "venta",
      name: "Nueva Cotización",
      icon: icons.Shield,
      active: true,
      route: "/",
      divider: false,
    },
    {
      id: "poliza",
      name: "Polizas",
      icon: icons.Shield,
      active: false,
      route: "/insurance-policies-summary",
      divider: false,
    },
    {
      id: "reporte",
      name: "Reporte",
      icon: icons.Shield,
      active: false,
      route: "/report",
      divider: false,
    },
    {
      id: "clientes",
      name: "Clientes",
      icon: icons.Person,
      active: false,
      route: "/clients",
      divider: false,
    },
  ];
  return m;
};

export const GetMenuSideBarAdministration = () => {
  const m: NavigateStructure[] = [
    {
      id: "sponsor-admin",
      name: "Patrocinador",
      icon: icons.Shield,
      active: true,
      route: "/sponsor-admin",
      divider: false,
    },
    {
      id: "insurance-type-admin",
      name: "Tipo de producto",
      icon: icons.Shield,
      active: false,
      route: "/insurance-type-admin",
      divider: false,
    },
    {
      id: "insurances-admin",
      name: "Producto",
      icon: icons.Shield,
      active: false,
      route: "/insurances-admin",
      divider: false,
    },
    {
      id: "usuario",
      name: "Usuarios",
      icon: icons.Shield,
      active: false,
      route: "/user-admin",
      divider: false,
    },
    {
      id: "tipo-de-identificación",
      name: "Tipo de Identificacion",
      icon: icons.Shield,
      active: false,
      route: "/identification-type-admin",
      divider: false,
    },
    {
      id: "sucursal",
      name: "Sucursal",
      icon: icons.Shield,
      active: false,
      route: "/branch-admin",
      divider: false,
    },
    {
      id: "region",
      name: "Region",
      icon: icons.Shield,
      active: false,
      route: "/region-admin",
      divider: false,
    },
    {
      id: "general-catalog-admin",
      name: "Catalogo General",
      icon: icons.Shield,
      active: false,
      route: "/general-catalog-admin",
      divider: false,
    },
    {
      id: "rol-admin",
      name: "Roles",
      icon: icons.Shield,
      active: false,
      route: "/rol-admin",
      divider: false,
    },
    {
      id: "billing",
      name: "Cobranza",
      icon: icons.Report,
      active: false,
      route: "/billing",
      divider: false,
    }
  ];

  return m;
};

export const GetMenuSideBarReport = () => {
  const m: NavigateStructure[] = [
    {
      id: "report1",
      name: "Reporte 1",
      icon: icons.Shield,
      active: true,
      route: "/report",
      divider: false,
    },
    {
      id: "report2",
      name: "Reporte 2",
      icon: icons.Shield,
      active: false,
      route: "/report",
      divider: false,
    },
  ];

  return m;
};