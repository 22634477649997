import React from "react";
import "./style.css";

import {
  AdminRegionAFY,
  icons,
  MenuItem,
  sizesIcon,
  sizesSelect,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {
  buttonActionOptions,
  buttonActionOptionsList,
  optionsSelect,
} from "./data";
import { themeCore } from "../../assets/themes/theme";
import { ModalDelete } from "./resources/modalDelete";
import { SelectChangeEvent } from "@mui/material";
import { insurersView } from "../insurances/data";

export const View = (props: {
  // Props Admin
  title: string;
  columns: any;
  buttonTextAdd?: string;
  onChangeSelect: (event: SelectChangeEvent<string>) => void;
  onClickAdd: () => void;
  buttonActionOptions: Array<any>;
  onClickTree: (item: any) => void;
  onClickBackTree: (item:any) => void;

  treeData: any;


  // Props Form
  titleForm?: string;
  dataForm: any;
  editMode: boolean;
  buttonText?: string;
  RegionTypeOptions?: Array<any>;
  ParentRegionTypeOptions?: Array<any>;
  onSaveData: any;
  treePrevious: any;
  // Props Drawer
  openDrawer: boolean;
  textDescription: string;
  onCloseDrawer: () => void;
  onClickBreadcrumb: (item: any) => void;

  // Props Search
  titleSelect1?: string;
  titleSelect2?: string;
  titleSelect3?: string;
  regionTypeOptions1?: Array<any>;
  regionTypeOptions2?: Array<any>;
  regionTypeOptions3?: Array<any>;
  filterStatus: (item: any) => void;
  searchFilter: (term: any) => void;
  onCloseSearch: () => void;
  breadcrumb: any;

  list?: any;
  modalTitle: string;
  openModalDelete: boolean;
  setOpenModalDelete: (value: boolean | (() => boolean)) => void;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  onClickContextMenu: () => void;
  parentLevel: any;
  page: number;
  limit: number;
  onChangeRowsPerPage: (e: number) => void;
  onChangePage: (e: number) => void;
  countPagination: number;
}) => {
  
  const permissions = {
    create: true,
    read: true,
    update: true,
    delete: true,
  };
  return (
    <>
      <ModalDelete
        openModalConfirmDelete={props.openModalDelete}
        setOpenModalDelete={props.setOpenModalDelete}
        modalTitle={props.modalTitle}
        onClickDelete={props.onSubmit}
      />

      <AdminRegionAFY
        backgroundColorHome= "#ffffff"
        colorIcon= "#000000"
      dataForm={props.dataForm}
      title="Regiones"
      permissions={permissions}
      showFilters={true}
      backgroundColor="#ffffff"
      titleSelectSponsor="Patrocinador"
      titleSelectInsurance="Codigo"
      titleClearButton="Limpiar filtros"
      clientLabel="Cliente"
      textFilterSearch="Buscar"
      textFilterSearchDrop="Buscar"
      textBackSearch="Volver"
      backgroundColorSearch="#F6F7FB"
      titleColor="#3A56A1"
      colorClientLabel="#3A56A1"
      iconBgColor="#F6F7FB"
      iconColor="#3A56A1"
      iconColorSearch="#3A56A1"
      heightAddNew="120px"
      heightAddNewList="50px"
      heightCard="120px"
      heightCardList="50px"
      backgroundColorCardAdd=""
      iconBackgroundColor={""}
      textColor={""}
      iconAvatar={icons.Search}
      iconSearch={icons.Search}
      selectSize={sizesSelect.small}
      avatarVariant={variantsAvatar.rounded}
      selectVariant={variantsTextField.outlined}
      sizeIcon={sizesIcon.standard}
      sizesIconSearch={sizesIcon.standard}
      disabledSelect={true}
      insurersView={insurersView}
      buttonActionOptions={buttonActionOptions}
      menuItem={buttonActionOptionsList}
      optionsSelect={optionsSelect}
      data={props.columns}
      onClickAdd={props.onClickAdd}
      onClickBreadcrumb={props.onClickBreadcrumb}
      // onClickButtonOptionsDropdown={props.onClickButtonOptionsDropdown}
      onClickContextMenu={props.onClickContextMenu}
      onCloseSearch={props.onCloseSearch}
      onChangeData={props.filterStatus}
      onChangeSearch={props.searchFilter}
      onClickButtonView={(option: MenuItem) => {
        props.clickView && props.clickView(option);
      }}
      editMode={props.editMode}
      titleDrawer={""}
      topLogoUpload={"145px"}
      topNameLogoUpload={""}
      rightLogoUpload={"238px"}
      rightNameLogoUpload={""}
      viewLinearForm={true}
      widthLabelLinearForm={"300px"}
      textDescription={
        ""
      }
      onSaveData={props.onSaveData}
      openDrawer={props.openDrawer}
      onCloseDrawer={props.onCloseDrawer}
      onClickHome={props.onClickBreadcrumb}
      isSponsor={true}
      countPagination={props.countPagination}
      rowsPerPage={props.limit}
      rowsPerPageOption={[20, 25, 50, 100]}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
      onChangePage={props.onChangePage}
      pagePagination={props.page}

      titlePagination={"Registros por página"}


        // Filter Props
        showSelectStatus={true}
        showSelectBranch={true}
        showSelectRole={true}
        titleButtonClear="Limpiar filtros"
        roleTypeOptions={props.regionTypeOptions1}
        branchTypeOptions={props.regionTypeOptions2}
        statusOptions={props.regionTypeOptions3}
        titleSelect1= {props.titleSelect1}
        titleSelect2= {props.titleSelect2}
        titleSelect3= {props.titleSelect3}
       
        // Navigation list props
        breadcrumb= {props.breadcrumb}
        treePrevius={ props.treePrevious}
        tree={props.treeData}
        // Drawer Props
     


        // Admin Props
      
        buttonTextAdd="Agregar region"
        buttonText="Agregar region"
        columns={props.columns}
        rows={props.list}
        onChangeSelect={props.onChangeSelect}
      
        // Form Props
        titleForm="Region"
        RegionTypeOptions={props.RegionTypeOptions}
        ParentRegionTypeOptions={props.ParentRegionTypeOptions}
        
        //unkown props
        onBlurSearch={function noRefCheck() {}}
        
        //Tree Props
        onClickTree={props.onClickTree}
        onClickBackTree={props.onClickBackTree}
        

      />
    </>
  );
};
