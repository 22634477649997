import { URLs } from "../url";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { QuoteOneResponse } from "../../model/quote";
import { getHeader } from "../headers";

const postCreateBenefit = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.beneficiary;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const updateBeneficiary = async (body: any) => {
  let newURL =
    process.env.REACT_APP_HOST_API + URLs.beneficiary + `/${body.id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body.body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getBeneficiaryAPI = async (
  quoteId: string,
  enable: boolean,
  page: number,
  limit: number,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.beneficiary +
    `?quoteId=${quoteId}&enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as QuoteOneResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { postCreateBenefit, getBeneficiaryAPI, updateBeneficiary };
