import { URLs } from "../url";
import { BranchesResponse } from "../../model/branches";
import { getHeader } from "../headers";

const getBranches = async (
  clientId: string,
  enable: boolean,
  page: number,
  limit: number,
  name: string,
  code: string,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.branches +
    `?clientId=${clientId}` +
    `&enable=${enable}&page=${page}&limit=${limit}` +
    (name ? `&name=${name}` : "") +
    (code ? `&code=${code}` : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as BranchesResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const postBranches = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.branches;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as BranchesResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const patchBranches = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.branches + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      throw await response.json();
    }
    return (await response.json()) as BranchesResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getBranches, postBranches, patchBranches };
