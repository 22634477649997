import { 
  DataFormClient,
  ContextMenuAFY,
  icons,
  sizesIcon,
 } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
 import { SpanStatus } from "./style";

export const dataFormRegion = {
  id: "",
  Name: "",
  Zipcode: "",
  Code: "",
  ParentRegion: "",
  RegionType: "",
};

export const Columns = (handleClickContextMenu: any) => {
  return [
    {
      field: "actions",
      headerName: "Acciones",
      width: 80,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            
              <ContextMenuAFY
                colorButton="#fff"
                iconButton={icons.MoreVert}
                iconColor="#3A56A1"
                menuItem={[
                  {
                    disabled: false,
                    icon: {
                      color: "#006ac6",
                      icon: icons.Edit,
                      size: sizesIcon.standard,
                    },
                    id: "1",
                    name: "Modificar",
                  },
                  {
                    icon: {
                      color: "#006ac6",
                      icon: icons.Delete,
                      size: sizesIcon.standard,
                    },
                    disabled: false,
                    id: "2",
                    name: "Eliminar",
                  },
                  
                ]}
                onClickContextMenu={(item: any) =>
                   handleClickContextMenu(item, params)
                }
                sizeIcon={sizesIcon.standard}
              />
          </div>
        );
      },
    },
    {
      editable: false,
      field: "Enable",
      headerName: "Estado",
      width: 100,
      renderCell: (params: any) => {
        return (
          <div style={{ display: "flex", gap: "10px" }}>
            <SpanStatus
              style={{
                backgroundColor:
                  params.row.Enable === "true" ? "#46c51a" : "#46c51a",
              }}
            ></SpanStatus>
            {params.row.Enable ==="true" ? "Activo" : "Activo"}
          </div>
        );
      },
    },
    {
      field: "Code",
      headerName: "Codigo",
      width: 180,
    },
    {
      field: "Name",
      headerName: "Nombre",
      width: 300,
    },
    {
      field: "ZipCode",
      headerName: "ZipCode",
      width: 180,
    },
    {
      field: "RegionType",
      headerName: "RegionType",
      width: 180,
    },
  ];
};