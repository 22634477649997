import React from "react";
import { useStyles } from "./style";
import "./style.css";

import {
  AdminSponsorAFY,
  CardProps,
  DataFormClient,
  icons,
  MenuItem,
  sizesIcon,
  sizesSelect,
  variantsAvatar,
  variantsTextField,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {
  buttonActionOptions,
  buttonActionOptionsList,
  optionsSelect,
  sponsorView,
} from "./data";
import { ModalDelete } from "./resorources/modalDelete";

export const ViewSponsor = (props: {
  sponsorList: CardProps[];
  openModal: boolean;
  // openModalConfirm: boolean;
  openModalCloneConfirm: boolean;
  onSaveData: any;
  loadingPostSponsor: any;
  dataForm: DataFormClient;
  modalTitle: string;
  editMode: boolean;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  onSubmitClone: () => void;
  onClose: () => void;
  // onCloseConfirm: () => void;
  onCloseCloneModal: () => void;
  handleOpenModal: () => void;
  // handleOpenModalConfirm: () => void;
  handleOpenModalCloneConfirm: () => void;
  onClickAdd: () => void;
  // onClickButtonOptionsDropdown: () => void;
  onClickContextMenu: () => void;
  filterStatus: (item: any) => void;
  searchFilter: (term: any) => void;
  onCloseSearch: () => void;
  openDrawer: boolean;
  setOpenDrawer: (value: boolean | (() => boolean)) => void;
  openModalDelete: boolean;
  setOpenModalDelete: (value: boolean | (() => boolean)) => void;
  onCloseDrawer: () => void;
  pageSponsor: number;
  sponsorLimit: number;
  onChangeRowsPerPage: (e: number) => void;
  onChangePage: (e: number) => void;
  countPaginationSponsor: number;
  titleDrawer: string;
}) => {
  const classes = useStyles();
  return (
    <>
      <ModalDelete
        openModalConfirmDelete={props.openModalDelete}
        setOpenModalDelete={props.setOpenModalDelete}
        modalTitle={props.modalTitle}
        onClickDelete={props.onSubmit}
      />
      {/*<ModalComponentAFY*/}
      {/*  openModal={props.openModalCloneConfirm}*/}
      {/*  backgroundColor="#ffffff"*/}
      {/*  onClose={props.onCloseCloneModal}*/}
      {/*  border={0}*/}
      {/*  modalWidth={500}*/}
      {/*  padding={30}*/}
      {/*>*/}
      {/*  <div style={{ marginTop: "10px" }}>*/}
      {/*    <h2 className={classes.title}>Clonar {props.modalTitle}</h2>*/}
      {/*    <p className={classes.description}>*/}
      {/*      {`¿Está seguro que desea Clonar ${props.modalTitle}?`}*/}
      {/*    </p>*/}
      {/*    <div className={classes.buttonsContainer}>*/}
      {/*      <div className={classes.button}>*/}
      {/*        <ButtonAFY*/}
      {/*          variant={variants.contained}*/}
      {/*          size={sizes.large}*/}
      {/*          label={"No"}*/}
      {/*          onClick={props.onCloseCloneModal}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*      <div className={classes.button}>*/}
      {/*        <ButtonAFY*/}
      {/*          variant={variants.contained}*/}
      {/*          size={sizes.large}*/}
      {/*          label={"Si, Clonar"}*/}
      {/*          onClick={(e) => {*/}
      {/*            e.stopPropagation();*/}
      {/*            props.onSubmitClone();*/}
      {/*          }}*/}
      {/*        />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</ModalComponentAFY>*/}

      <AdminSponsorAFY
        dataForm={props.dataForm}
        title="Patrocinador"
        showFilters={true}
        backgroundColor="#ffffff"
        titleSelectSponsor="Patrocinador"
        titleSelectInsurance="Codigo"
        titleClearButton="Limpiar filtros"
        clientLabel="Cliente"
        textFilterSearch="Buscar"
        textFilterSearchDrop="Buscar"
        textBackSearch="Volver"
        backgroundColorSearch="#F6F7FB"
        titleColor="#3A56A1"
        colorClientLabel="#3A56A1"
        iconBgColor="#F6F7FB"
        iconColor="#3A56A1"
        iconColorSearch="#3A56A1"
        heightAddNew="120px"
        heightAddNewList="50px"
        heightCard="120px"
        heightCardList="50px"
        backgroundColorCardAdd=""
        iconBackgroundColor={""}
        textColor={""}
        iconAvatar={icons.Search}
        iconSearch={icons.Search}
        selectSize={sizesSelect.small}
        avatarVariant={variantsAvatar.rounded}
        selectVariant={variantsTextField.outlined}
        sizeIcon={sizesIcon.standard}
        sizesIconSearch={sizesIcon.standard}
        disabledSelect={true}
        insurersView={sponsorView}
        buttonActionOptions={buttonActionOptions}
        menuItem={buttonActionOptionsList}
        optionsSelect={optionsSelect}
        data={props.sponsorList}
        onClickAdd={props.onClickAdd}
        // onClickButtonOptionsDropdown={props.onClickButtonOptionsDropdown}
        onClickContextMenu={props.onClickContextMenu}
        onCloseSearch={props.onCloseSearch}
        onChangeData={props.filterStatus}
        onChangeSearch={props.searchFilter}
        onClickButtonView={(option: MenuItem) => {
          props.clickView && props.clickView(option);
        }}
        editMode={props.editMode}
        titleDrawer={props.titleDrawer}
        topLogoUpload={"145px"}
        topNameLogoUpload={""}
        rightLogoUpload={"238px"}
        rightNameLogoUpload={""}
        viewLinearForm={true}
        widthLabelLinearForm={"300px"}
        textDescription={
          "Ingrese los datos del sponsor. El sponsor es la entidad o persona que tendrá productos asignados y será responsable de su administración. Asegúrese de incluir información detallada y precisa para facilitar la identificación y gestión de los productos asociados."
        }
        onSaveData={props.onSaveData}
        openDrawer={props.openDrawer}
        onCloseDrawer={props.onCloseDrawer}
        isSponsor={true}
        countPagination={props.countPaginationSponsor}
        rowsPerPage={props.sponsorLimit}
        rowsPerPageOption={[20, 25, 50, 100]}
        onChangeRowsPerPage={props.onChangeRowsPerPage}
        onChangePage={props.onChangePage}
        pagePagination={props.pageSponsor}
        titlePagination={"Registros por página"}
      />
    </>
  );
};
