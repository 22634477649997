import styled from "styled-components";
import { themeCore } from "../../assets/themes/theme";

export const Container = styled.section`
  display: flex;
  background-color: ${themeCore.colors.backgroundFourth};
  height: calc(100vh - 56px);
  position: relative;
  margin: 0;
  padding: 0;
  overflow-y: auto;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: ${themeCore.colors.primaryText};
  margin: 5px 0 15px 0;
  position: relative;
`;

export const TitleCenter = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: ${themeCore.colors.primaryText};
  margin: 0 0 20px 0;
  position: relative;
  text-align: center;
`;

export const TitleCotizacionPoliza = styled.h1`
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: ${themeCore.colors.primaryText};
  margin: 2px 0 20px 0;
  position: absolute;
`;

export const WraperSidebar = styled.article`
  transition: width 1s ease;
  margin: 0;
  padding: 0;

  .wrapperSidebar {
    position: fixed;
  }

  z-index: 9;
`;

export const WrapperFooter = styled.section`
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
`;

export const WrapperContent = styled.article`
  margin: 0 0 0 0;
  height: 100%;
  position: relative;
`;

export const Content = styled.article`
  flex-grow: 1;
  padding: 10px 15px 0 20px;
  overflow-y: auto;
`;
