import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { user } from "../../store/auth/actions";

export const useUserGetAll = (
  trigger: boolean,
  users:string
) => {
  const dispatch = useDispatch();

  const { responseUser, errorUser, loadingUser } = useSelector(
    (state: any) => ({
      responseUser: state.Login.responseUser?.data,
      errorUser: state.Login.errorUser,
      loadingUser: state.Login.loadingUser,
    }),
  );

  useEffect(() => {
    if (trigger) {
      dispatch(
        user({
          users: users,
          orderBy: "createdAt",
          orderDir: "desc",
          enabled: true,
          deleted: false,
        }),
      );
    }
  }, [trigger]);

  return {
    responseUser,
    errorUser,
    loadingUser,
  };
};
