import { useEffect, useState } from "react";
import { useGetPermissionList } from "../fnx/getPermissions";

export const GetNavigationPages = () => {
  let permissionsConfig: { name: string }[] = [];
  const [trigger2, setTrigger2] = useState(false);
  const { permissionList } = useGetPermissionList(trigger2);
  useEffect(() => {
    setTrigger2(true);
  }, []);

  if (localStorage.getItem("afy-login")) {
    const item = localStorage.getItem("afy-login");
    if (item !== null) {
      switch ("rootRol") {
        case "rootRol":
          permissionsConfig = permissionList;
          break;
        default:
          permissionsConfig = [];
      }
    }
  }
  return {
    permissionsConfig,
  };
};
export const hasPermission = (tag: any) => {
  const permission = GetNavigationPages().permissionsConfig;
  return permission.find((i) => i.name === tag) ? true : false;
}

// export const findPage = (page: any) => {
//   const pages = GetNavigationPages().authAccess;
//   return pages.find((i) => i.name === page) ? true : false;
// };
