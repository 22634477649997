import {
  breadcrumb,
  DataFormClient,
  state,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

export const paymentSummaryStorybook = {
  paymentOption: {
    id: "1",
    name: "Cuenta a banco",
    option: 1,
  },
  paymentOptions: [
    {
      id: "1",
      name: "Cuenta a banco",
      option: 1,
    },
    {
      disabled: true,
      id: "2",
      name: "Tarjeta de credito",
      option: 2,
    },
    {
      disabled: true,
      id: "3",
      name: "Otro metodo",
      option: 3,
    },
  ],
  previousLabel: "Ver resumen",
  stepProgress: [
    {
      id: "1",
      name: "Tipo de seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Nombre del seguro",
      state: state.complete,
    },
    {
      id: "2",
      name: "Tipo de plan",
      state: state.complete,
    },
    {
      id: "3",
      name: "Datos del cliente",
      state: state.complete,
    },
    {
      id: "6",
      name: "Confirmación",
      state: state.complete,
    },
    {
      id: "7",
      name: "Datos de pago",
      state: state.inProgress,
    },
  ],
  title: "Método de pago",
  nextLabel: "Finalizar",
};

export const Breadcrum: breadcrumb[] = [
  {
    name: "Producto",
    key: "producto",
    ariaCurrent: "",
  },
  {
    name: "Plan",
    key: "plan",
    ariaCurrent: "",
  },
  {
    name: "Validar cliente",
    key: "cliente",
    ariaCurrent: "",
  },
  {
    name: "Cofirmación cliente",
    key: "confirmacion",
    ariaCurrent: "",
  },
  {
    name: "Pago",
    key: "pago",
    ariaCurrent: "",
  },
];

export const dataFilter = (
  dataForm: DataFormClient & { Insurance?: string } & {
    CoverType?: string;
  } & {
    PaymentMode?: string;
  } & { Currency?: string } & { Prima?: string } & { QuoteId?: string },
) => {
  return [
    {
      label: "Datos personales",
      type: "subtitle",
    },
    {
      name: "Nombre",
      type: "label",
      label: dataForm.Name,
    },
    {
      label: `${dataForm.LastNames ? dataForm.LastNames : ""}`,
      name: "Apellidos",
      type: "label",
    },
    {
      label: `${dataForm.DocumentNumber?.replace(
        /(\d{3})(\d{7})(\d{1})/,
        "$1-$2-$3",
      )}`,
      name: "No. de documento",
      type: "label",
    },
    {
      label: "Datos de seguro",
      type: "subtitle",
    },
    {
      label: `${dataForm.Insurance}`,
      name: "Producto",
      type: "label",
    },
    {
      label: `${dataForm.PlanTypeName ? dataForm.PlanTypeName : ""}`,
      name: "Plan",
      type: "label",
    },
    {
      label: `${dataForm.CampaignName ? dataForm.CampaignName : ""}`,
      name: "Campaña",
      type: "label",
    },
    {
      label: `${dataForm.CoverType ? dataForm.CoverType : ""}`,
      name: "Tipo de cobertura",
      type: "label",
    },
    {
      label: `${dataForm.PaymentMode ? dataForm.PaymentMode : ""}`,
      name: "Modalidad de pago",
      type: "label",
    },
    {
      label: `${dataForm.Currency ? dataForm.Currency : ""}`,
      name: "Moneda",
      type: "label",
    },
    {
      label: `${dataForm.Prima ? dataForm.Prima : ""}`,
      name: "Prima",
      type: "label",
    },
  ];
};
