import React from "react";
import {
    breadcrumb,
    BreadcrumbAFY,
    CardProps,
    icons,
    InformationAFY,
    InsuranceTypeAFY,
    LoadingAFY,
    MenuItem,
    sizesIcon,
    StepProgressAFY,
    typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import {Title, WrapperContent} from "../../components/layout/style";
import {themeCore} from "../../assets/themes/theme";
import {InsuranceTypeStoryBook} from "../../model/insuranceType";
import MetaTags from "react-meta-tags";
export const InsuranceTypeView = (props: {
    steps: MenuItem[];
    insurance: InsuranceTypeStoryBook[];
    onClick: (card: CardProps) => void;
    breadCrumbs: breadcrumb[];
    title: string;
    loading: boolean;
}) => {
    /*const [insuranceTypeData, setInsuranceTypeData] = React.useState<
      InsuranceTypeStoryBook[]
    >([]);

    useEffect(() => {
      if (props.insurance) {
        setInsuranceTypeData(props.insurance);
      }
    }, props.insurance);*/

    return (
        <WrapperContent>
            <BreadcrumbAFY options={props.breadCrumbs}/>
            <Title>
                Tipo de seguro
                <article
                    style={{
                        position: "absolute",
                        top: "-28px",
                        right: "391px",
                        zIndex: "9",
                    }}
                >
                    <StepProgressAFY
                        onClickStepProgress={() => {
                        }}
                        stepProgress={props.steps}
                        collapsed
                        sticky
                    />
                </article>
            </Title>
           
            <section style={{marginBottom: "15px"}}>
                <InformationAFY
                    iconSize={sizesIcon.standard}
                    iconColor={themeCore.colors.primary}
                    colorText={themeCore.colors.secondaryText}
                    backgroundColor={themeCore.colors.background}
                    description="Haz click en el tipo de seguro para esta venta"
                    icon={icons.Help}
                />
            </section>

            <InsuranceTypeAFY
                data={props.insurance}
                title={props.title}
                onClick={props.onClick}
            />
            <LoadingAFY
                loading={props.loading}
                bgColor={themeCore.colors.backgroundLoader}
                type={typeLoaders.BarLoader}
                color={themeCore.colors.third}
                textColor={themeCore.colors.third}
                text={"Obteniendo Informacion!"}
            />
        </WrapperContent>
    );
};
