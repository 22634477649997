import {
  CATALOG_ACCOUNT_TYPE,
  CATALOG_ACCOUNT_TYPE_CLEAR_DATA,
  CATALOG_ACCOUNT_TYPE_ERROR,
  CATALOG_ACCOUNT_TYPE_SUCCESS,
  CATALOG_GENDER,
  CATALOG_GENDER_ERROR,
  CATALOG_GENDER_SUCCESS,
  PLAN,
  PLAN_CLEAR_DATA,
  PLAN_ERROR,
  PLAN_POST,
  PLAN_POST_ERROR,
  PLAN_POST_SUCCESS,
  PLAN_PUT,
  PLAN_PUT_ERROR,
  PLAN_PUT_SUCCESS,
  PLAN_SUCCESS,
} from "./actionTypes";

const initialState = {
  errorPlan: null,
  responsePlan: null,
  loadingPlan: false,
  errorCatalogGender: null,
  catalogGender: null,
  responseCatalogGender: null,
  loadingCatalogGender: false,
  errorCatalogAccountType: null,
  catalogAccountType: null,
  responseCatalogAccountType: null,
  loadingCatalogAccountType: false,
  errorPlanPost: null,
  responsePlanPost: null,
  loadingPlanPost: false,
  errorPlanPut: null,
  responsePlanPut: null,
  loadingPlanPut: false,
};

const Plan = (state = initialState, action) => {
  switch (action.type) {
    case PLAN:
      state = {
        ...state,
        loadingPlan: true,
        errorPlan: null,
        responsePlan: true,
      };
      break;
    case CATALOG_GENDER:
      state = {
        ...state,
        loadingCatalogGender: true,
        errorCatalogGender: null,
        responseCatalogGender: true,
      };
      break;
    case CATALOG_GENDER_SUCCESS:
      state = {
        ...state,
        loadingCatalogGender: false,
        errorCatalogGender: null,
        responseCatalogGender: action.payload,
      };
      break;
    case PLAN_SUCCESS:
      state = {
        ...state,
        errorPlan: null,
        loadingPlan: false,
        responsePlan: action.payload,
      };
      break;
    case CATALOG_GENDER_ERROR:
      state = {
        ...state,
        errorCatalogGender: action.payload,
        loadingCatalogGender: false,
        responseCatalogGender: null,
      };
      break;
    case PLAN_ERROR:
      state = {
        ...state,
        errorPlan: action.payload,
        loadingPlan: false,
        responsePlan: null,
      };
      break;
    case PLAN_CLEAR_DATA:
      state = {
        ...state,
        errorPlan: null,
        loadingPlan: null,
        responsePlan: false,
      };
      break;
    case CATALOG_ACCOUNT_TYPE:
      state = {
        ...state,
        loadingCatalogAccountType: true,
        errorCatalogAccountType: null,
        responseCatalogAccountType: true,
      };
      break;
    case CATALOG_ACCOUNT_TYPE_SUCCESS:
      state = {
        ...state,
        loadingCatalogAccountType: false,
        errorCatalogAccountType: null,
        responseCatalogAccountType: action.payload,
      };
      break;
    case CATALOG_ACCOUNT_TYPE_ERROR:
      state = {
        ...state,
        errorCatalogAccountType: action.payload,
        loadingCatalogAccountType: false,
        responseCatalogAccountType: null,
      };
      break;
    case CATALOG_ACCOUNT_TYPE_CLEAR_DATA:
      state = {
        ...state,
        errorCatalogAccountType: null,
        loadingCatalogAccountType: null,
        responseCatalogAccountType: null,
      };
      break;
    case PLAN_POST:
      state = {
        ...state,
        loadingPlanPost: true,
        errorPlanPost: null,
        responsePlanPost: true,
      };
      break;
    case PLAN_POST_SUCCESS:
      state = {
        ...state,
        loadingPlanPost: false,
        errorPlanPost: null,
        responsePlanPost: action.payload,
      };
      break;
    case PLAN_POST_ERROR:
      state = {
        ...state,
        loadingPlanPost: false,
        errorPlanPost: action.payload,
        responsePlanPost: null,
      };
      break;
    case PLAN_PUT:
      state = {
        ...state,
        loadingPlanPut: true,
        errorPlanPut: null,
        responsePlanPut: true,
      };
      break;
    case PLAN_PUT_SUCCESS:
      state = {
        ...state,
        loadingPlanPut: false,
        errorPlanPut: null,
        responsePlanPut: action.payload,
      };
      break;
    case PLAN_PUT_ERROR:
      state = {
        ...state,
        loadingPlanPut: false,
        errorPlanPut: action.payload,
        responsePlanPut: null,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Plan;
