import {
  iconsSvg,
  MenuItem,
  breadcrumb,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";


export const Breadcrum: breadcrumb[] = [
  {
    name: "Producto",
    key: "seguro",
    ariaCurrent: "",
  },
  {
    name: "Plan",
    key: "plan",
    ariaCurrent: "",
  },
  {
    name: "Registro",
    key: "registro",
    ariaCurrent: "",
  },

];

